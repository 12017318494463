import { Component, Input, OnInit } from '@angular/core';
import { DebounceFactory, TableDataFactory } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
import { NonprofitService } from '../../../nonprofit/nonprofit.service';
import { RevocationDetailsBySubdivision } from '@core/models/revocation.model';

@Component({
  selector: 'yc-revocation-details-by-state-modal',
  styleUrls: ['./revocation-details-by-state-modal.component.scss'],
  templateUrl: './revocation-details-by-state-modal.component.html'
})
export class RevocationDetailsByStateModalComponent extends YCModalComponent<void> implements OnInit {
  @Input() nonprofitId: number;
  @Input() nonprofitName: string;
  @Input() stateCode: string;
  @Input() nonprofitGuid: string;
  ready = false;
  modalSubheader: string;
  tableDataFactory: TableDataFactory<RevocationDetailsBySubdivision>;
  modalHeader = this.i18n.translate(
    'common:textRevocationList',
    {},
    'Revocation List'
  );

  constructor (
    private i18n: I18nService,
    private nonprofitService: NonprofitService
  ) {
    super();
  }

  ngOnInit () {
    this.modalSubheader = this.nonprofitName;
    this.setTableDataFactory();
    this.ready = true;
  }

  setTableDataFactory () {
    let results: RevocationDetailsBySubdivision[];
    this.tableDataFactory = DebounceFactory.createSimple(
      async () => {
        // If modal is opened from Extended Search
        if (!this.nonprofitGuid) {
          results = await this.nonprofitService.getRevocationDetails(
            this.nonprofitId,
            this.stateCode
          );
        } else {
          // If modal is opened from Public Search page
          results = await this.nonprofitService.getRevocationDetails(
            this.nonprofitId
          );
        }

        return {
          success: true,
          data: {
            recordCount: results.length,
            records: results
          }
        };
      }
    );
  }

  onCancel () {
    this.closeModal.emit();
  }
}
