import { Injectable } from '@angular/core';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { MyWorkspaceFinanceResources } from './my-workspace-finance.resources';
import { MyWorkspaceFinanceState } from './my-workspace-finance.state';


@AttachYCState(MyWorkspaceFinanceState)
@Injectable({ providedIn: 'root' })
export class MyWorkspaceFinanceService extends BaseYCService<MyWorkspaceFinanceState> {

  constructor (
    private myWorkspaceFinanceResources: MyWorkspaceFinanceResources
  ) {
    super();
  }

  get mostRecentPayments () {
    return this.get('mostRecentPayments');
  }

  get verifyDonationsTotal () {
    return this.get('verifyDonationsTotal');
  }

  get volunteerHoursUnverified () {
    return this.get('volunteerHoursUnverified');
  }

  async setMostRecentPayments () {
    const response = await this.myWorkspaceFinanceResources.getMostRecentPayments();
    this.set('mostRecentPayments', response);
  }

  async setVerifyDonationsTotal () {
    const response = await this.myWorkspaceFinanceResources.getVerifyDonationsTotal();
    this.set('verifyDonationsTotal', response);
  }

  async setVolunteerHoursUnverified () {
    const response = await this.myWorkspaceFinanceResources.getVolunteerHoursUnverified();
    this.set('volunteerHoursUnverified', response);
  }

  updateVerifyHoursTotal (numberToSubtract: number) {
    const newVal = this.volunteerHoursUnverified - numberToSubtract;

    return this.set('volunteerHoursUnverified', newVal);
  }

  updateVerifyDonationsTotal (numberToSubtract: number) {
    const newVal = this.verifyDonationsTotal - numberToSubtract;

    return this.set('verifyDonationsTotal', newVal);
  }

  async setMyWorkspaceStats () {
    return Promise.all([
      this.setMostRecentPayments(),
      this.setVerifyDonationsTotal(),
      this.setVolunteerHoursUnverified()
    ]);
  }
}
