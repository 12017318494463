<ng-container [formGroup]="parentFormGroup">
  <div class="row">
    <div class="col-md-{{colSize}}">
      <yc-typeahead-select *ngIf="usNonprofitSelected"
        name="ntee"
        [items]="nteeOptions"
        [disabled]="disableNtee"
        [label]="'nonprofits:textNteeCode' | ycI18n: 'NTEE Code'">
      </yc-typeahead-select>

      <ng-container *ngIf="!usNonprofitSelected">
        <yc-typeahead-select
          name="category"
          [label]="'nonprofits:lbCategory' | ycI18n: 'Category'"
          [items]="classificationOptions"
          [disabled]="disableNtee"
          (onChange)="classificationChanged()">
        </yc-typeahead-select>
        <ng-container *ngIf="parentFormGroup.value.category?.children?.length">
          <yc-typeahead-select
            name="group"
            [label]="'nonprofits:lblGroup' | ycI18n: 'Group'"
            [items]="groupSelectOptions"
            [disabled]="disableNtee"
            (onChange)="classificationChanged()">
          </yc-typeahead-select>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
