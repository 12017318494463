import { inject } from '@angular/core';
import { TourStorageService } from '../services/tour-storage.service';
import { lastValueFrom } from 'rxjs';
import { ResolveFn } from '@angular/router';

export const tourResolver: ResolveFn<void> = async () => {
  const tourStorageService = inject(TourStorageService);
  await lastValueFrom(tourStorageService.getCompletedTours());
}

