import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { SystemHealthDashboardDetails, SystemHealthDashboardStats } from '@core/typings/system-health-dashboard.typing';

@Injectable({ providedIn: 'root' })
export class SystemHealthDashboardResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getSystemHealthDashboardStats (): Promise<SystemHealthDashboardStats[]> {
    return this.httpRestService.get('api/SystemHealthDashboard/Stats');
  }

  getSystemHealthDashboardDetails (healthIndicatorId: number): Promise<SystemHealthDashboardDetails> {
    return this.httpRestService.get('api/SystemHealthDashboard/Details/' + healthIndicatorId);
  }
}


