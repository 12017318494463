import { NonprofitAdminRequestDisplayModel } from '@core/models/claim.model';
import { BasicMyNonprofit, MyNonprofitRow } from '@core/models/npo.model';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class MyNonprofitsState extends BaseYcState {
  readonly nonprofitsForUser: BasicMyNonprofit[];
  readonly requestsForUser: NonprofitAdminRequestDisplayModel[];
  readonly tableRows: MyNonprofitRow[];
  readonly nonprofitArray: MyNonprofitRow[];
  readonly adminRequestArray: MyNonprofitRow[];
  readonly nonprofitHasChapters: boolean;
  readonly useParentDescription: boolean;
  readonly useChapterDescription: boolean;
}
