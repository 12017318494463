import { NonprofitAsset } from '@core/models/nonprofit-asset.model';
import { AssetType } from '@yourcause/common';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class AssetManagementState extends BaseYcState {
  readonly assetTypes: AssetType[];
  readonly assetTypesWithWorkflow: AssetType[];
  readonly defaultAssetWorkflow: AssetType;
  readonly assets: NonprofitAsset[];
}
