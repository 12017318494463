import { SystemEmailsAPI } from '@core/typings/api/system-email.typing';

export interface CommunicationPreferences {
  unsubscribedEmailClassIds: number[];
  email: string;
}

export interface CommunicationPreferencesPayload {
  unsubscribedEmailClassIds: number[];
  userEmailAddress?: string;
}

export interface EmailCommGroup {
  group: SystemEmailsAPI.EmailClass[];
}

export enum UnsubscribeEmailClassIds {
  system = 1,
  compliance = 2,
  disbursements = 3,
  usersAndAdministratorRequests = 4,
  bestPractices = 5,
  chapterManagement = 6,
  volunteering = 7,
  adHoc = 8,
  accountManagement = 9,
  vetting = 10
}

export enum UserCommunicationPreference {
  compliance = 'Compliance',
  disbursementManagement = 'Disbursement Management',
  bestPractice = 'Best Practice',
  chapterManagement = 'Chapter Management',
  volunteering = 'Volunteering',
  adminManagement = 'Admin Management'
}
