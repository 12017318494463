
import { Injectable } from '@angular/core';
import { StagedNonprofitForAdminRequest } from '@core/models/admin-request.model';
import { ClaimStateDataWithAssets, ClaimStatePathId, ContactForm, FiscalType, NewChapter, NonprofitAdminRequestCreateModel, NonprofitAdminRequestDetailModel, NonprofitAdminRequestDisplayModel, NonprofitAdminRequestUpdateModel, RegAuthorityTypeForUS, RegistrationAuthorityInfoModel, StagedNonprofit } from '@core/models/claim.model';
import { NonprofitAsset } from '@core/models/nonprofit-asset.model';
import { BasicNonprofit } from '@core/models/npo.model';
import { AdminRequestStatusId } from '@core/models/status.model';
import { User } from '@core/models/user.model';
import { AssetType, AssetTypesGet, AssetWorkflowType, SearchResult, TableAsset } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { AssetManagementService } from '../../asset-management/services/asset-management.service';
import { MyNonprofitsService } from '../../my-nonprofits/my-nonprofits.service';
import { RegistrationAuthoritiesService } from '../../registration-authorities/registration-authorities.service';
import { ClaimResources } from './claim.resources';
import { ClaimState } from './claim.state';
import { ArrayHelpersService } from '@yourcause/common/utils';

@AttachYCState(ClaimState)
@Injectable({ providedIn: 'root' })
export class ClaimService extends BaseYCService<ClaimState> {
  constructor (
    private claimResources: ClaimResources,
    private notifier: NotifierService,
    private i18n: I18nService,
    private registrationAuthoritiesService: RegistrationAuthoritiesService,
    private myNonprofitsService: MyNonprofitsService,
    private arrayHelper: ArrayHelpersService,
    private assetManagementService: AssetManagementService
  ) {
    super();
  }

  get currentStep (): number {
    return this.get('currentStep');
  }

  get claimDataWithAssets (): ClaimStateDataWithAssets {
    return this.get('claimStateDataWithAssets');
  }

  get storedClaimData (): ClaimStateDataWithAssets {
    return this.get('storedClaimData');
  }

  get existingDraftClaims (): ClaimStateDataWithAssets[] {
    return this.get('existingDraftClaims');
  }

  get activeExistingClaimId (): number {
    return this.get('activeExistingClaimId');
  }

  get claimIdForReview (): number {
    return this.get('claimIdForReview');
  }

  get claimDetailsForReview (): NonprofitAdminRequestDetailModel {
    return this.get('claimDetailsForReview');
  }

  get activeExistingStatusId (): number {
    return this.get('activeExistingStatusId');
  }

  get claimsAssetTypes (): AssetType[] {
    return this.get('claimsAssetTypes');
  }

  get isNpoUser (): boolean {
    return this.get('isNpoUser');
  }

  get path (): string {
    return this.get('path');
  }

  get nonprofitIdForChapters (): number {
    return this.get('nonprofitIdForChapters');
  }

  get chapterRegionId (): number {
    return this.get('chapterRegionId');
  }

  get stepperState (): ClaimStatePathId {
    if (this.path.includes('nonprofit-registration')) {
      if (this.path.includes(ClaimStatePathId.NEW_ADMIN_CLAIM)) {
        return ClaimStatePathId.NEW_ADMIN_CLAIM;
      } else {
        return ClaimStatePathId.VIEW_MY_CLAIM;
      }
    } else {
      if (this.activeExistingStatusId === AdminRequestStatusId.PENDING) {
        return ClaimStatePathId.ADMIN_EDIT_CLAIM;
      } else {
        return ClaimStatePathId.ADMIN_VIEW_CLAIM;
      }
    }
  }

  get formDisabled (): boolean {
    return this.get('formsDisabled');
  }

  get assetsDisabled (): boolean {
    return this.get('assetsDisabled');
  }

  get defaultRegAuthForAssets (): number {
    return this.get('defaultRegAuthForAssets');
  }

  get existingContactInfo (): ContactForm {
    return this.get('existingContactInfo');
  }

  setCurrentStep (step: number) {
    this.set('currentStep', step);
  }

  setClaimDataWithAssets (claim: ClaimStateDataWithAssets) {
    this.set('claimStateDataWithAssets', claim);
  }

  convertToTableAssets (
    assets: NonprofitAsset[],
    claimId: number
  ) {
    const convertedAssets: TableAsset[] = assets?.map((asset) => {
      return {
        fileName: asset.fileName,
        file: null,
        fileId: asset.assetId,
        optional: false,
        claimId,
        assetTypeId: asset.assetTypeId,
        name: asset.assetTypeName,
        fileUrl: asset.fileUrl,
        uploadDate: asset.uploadDate
      };
    });

    return convertedAssets;
  }

  setDefaultClaimData () {
    this.setClaimDataWithAssets(ClaimStateDataWithAssets.default);
  }

  setStoredClaimData (claimData: Partial<ClaimStateDataWithAssets>) {
    this.set('storedClaimData', claimData);
  }

  setExistingDraftClaims (requests: NonprofitAdminRequestDisplayModel[]) {
    const draftRequests = requests.filter((request) => {
      return request.adminStatusId === AdminRequestStatusId.DRAFT ||
        request.adminStatusId === AdminRequestStatusId.PENDING;
    });
    const draftClaimsFromRequests: ClaimStateDataWithAssets[] = [];
    draftRequests.forEach((req) => {
      draftClaimsFromRequests.push(this.adaptRequestDataToClaimData(req));
    });
    this.set('existingDraftClaims', draftClaimsFromRequests);
  }

  async setActiveExistingClaimId (
    claimId: number
  ) {
    const activeExistingClaimId = !(isNaN(claimId)) ?
      claimId : null;
    this.set('activeExistingClaimId', activeExistingClaimId);
    if (!!activeExistingClaimId) {
      await this.setAllCurrentClaimDataAndExistingFiles();
    } else {
      this.setFormsDisabled(false);
      this.setAssetsDisabled(false);
      this.setDefaultClaimData();
    }
    await this.setClaimsAssetTypes();
  }

  async setClaimIdForReview (claimId: number) {
    this.set('claimIdForReview', claimId);
    await this.setClaimDetailsForReview();
  }

  setActiveExistingStatusId (statusId: number) {
    this.set('activeExistingStatusId', statusId);
  }

  setIsNpoUser (val: boolean) {
    this.set('isNpoUser', val);
  }

  setPathForStepperState (path: string) {
    this.set('path', path);
  }

  setNonprofitIdForChapters (id: number) {
    this.set('nonprofitIdForChapters', id);
  }

  setChapterRegionId (id: number) {
    this.set('chapterRegionId', id);
  }

  setFormsDisabled (val: boolean) {
    this.set('formsDisabled', val);
  }

  setAssetsDisabled (val: boolean) {
    this.set('assetsDisabled', val);
  }

  setExistingContactInfo (info: ContactForm) {
    this.set('existingContactInfo', info);
  }

  async cancelAdminRequest (
    nonprofitAdminRequestId: number,
    skipToastr = false
  ) {
    try {
      await this.claimResources.cancelAdminRequest(nonprofitAdminRequestId);
      if (!skipToastr) {
        this.notifier.success(this.i18n.translate(
          'claim:notificationSuccessfullyCancelledRequest',
          {},
          'Successfully cancelled administrator request'
        ));
      }
    } catch (e) {
      console.error(e);
      if (!skipToastr) {
        this.notifier.error(this.i18n.translate(
          'claim:notificationErrorCancellingAdminRequest',
          {},
          'There was an error cancelling your administrator request'
        ));
      }
    }
  }

  async removeAsset (
    file: TableAsset
  ) {
    try {
      const response = await this.claimResources.removeAsset(
        this.activeExistingClaimId,
        file,
        this.isNpoUser
      );
      if (!this.isNpoUser) {
        this.notifier.success(this.i18n.translate(
          'claim:notificationSusseccfullyUpdatedDocuments',
          {},
          'Successfully updated the documents'
        ));
      }

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'claim:notificationErrorUpdatingDocuments',
        {},
        'There was an error updating the documents'
      ));

      return null;
    }
  }

  async uploadClaimAsset (assetToAdd: TableAsset) {
    let tableAsset: TableAsset[] = [];

    try {
      const response = await this.assetManagementService.uploadClaimAsset(
        assetToAdd,
        this.isNpoUser,
        this.activeExistingClaimId
      );
      if (!!response) {
        tableAsset = this.convertToTableAssets(
          [response.data as NonprofitAsset],
          this.activeExistingClaimId
        );
      }
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'common:notificationErrorUploadingFile',
        {},
        'There was an error uploading the file'
      ));
    }

    return tableAsset;
  }

  async updateAdminRequest (
    payload: ClaimStateDataWithAssets,
    isSubmission: boolean
  ) {
    const adaptedPayload = this.adaptPayloadForCreateOrUpdate(
      payload,
      isSubmission
    );
    const updatePayload = {
      ...adaptedPayload,
      nonprofitAdminRequestId: +this.activeExistingClaimId
    } as NonprofitAdminRequestUpdateModel;

    try {
      const response = await this.claimResources.updateAdminRequest(
        updatePayload
      );

      if (isSubmission) {
        await this.myNonprofitsService.setAllDataForMyNonprofitRows();
        this.notifier.success(this.i18n.translate(
          'claim:notificationSuccessfullySubmittedClaim',
          {},
          'Successfully submitted your administrator request'
        ));
      }

      return response;
    } catch (e) {
      console.error(e);
      if (isSubmission) {
        this.notifier.error(this.i18n.translate(
          'claim:notificationErrorSubmittingClaim',
          {},
          'There was an error submitting your administrator request'
        ));
      } else {
        this.notifier.error(this.i18n.translate(
          'claim:notificationErrorSavingClaim',
          {},
          'There was an error saving your administrator request'
        ));
      }
      this.resetClaimData();

      return null;
    }
  }

  async createClaim () {
    const createPayload: NonprofitAdminRequestCreateModel = this.adaptPayloadForCreateOrUpdate(
      this.storedClaimData,
      false
    );
    try {
      const response = await this.claimResources.createAdminRequest(
        createPayload
      );
      if (!!response) {
        await this.setActiveExistingClaimId(response.nonprofitAdminRequestId);
        await this.myNonprofitsService.setAllDataForMyNonprofitRows();
      }
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'claim:notificationErrorSavingClaim',
        {},
        'There was an error saving your administrator request'
      ));
      this.resetClaimData();
    }
  }

  async getNonprofitAdmins (id: string) {
    try {
      const response = await this.claimResources.getNonprofitAdmins(id);

      return response as User[];
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  adaptRequestDataToClaimData (
    data: NonprofitAdminRequestDetailModel
  ): ClaimStateDataWithAssets {
    if (!!data) {
      this.setActiveExistingStatusId(+data.adminStatusId);
      const fiscal = data.claimChapter ?
        FiscalType.Chapter :
        FiscalType.Standalone;
      const dataStagedNonprofit = data.stagedNonprofit as StagedNonprofitForAdminRequest;
      let nonprofit: SearchResult;
      let parent: SearchResult;
      let stagedNonprofit: Partial<StagedNonprofitForAdminRequest>;
      let registrationAuthorityId: number;
      let existingAssets: TableAsset[];

      if (!!data.parent) {
        parent = this.constructSearchResult(data.parent);
        registrationAuthorityId = data.parent.registrationAuthorityId;
      }
      if (!!data.nonprofit) {
        nonprofit = this.constructSearchResult(data.nonprofit);
        registrationAuthorityId = data.nonprofit.registrationAuthorityId;
      }
      if (!!dataStagedNonprofit) {
        stagedNonprofit = dataStagedNonprofit;
        registrationAuthorityId = !!data.parent ?
          data.parent.registrationAuthorityId :
          dataStagedNonprofit.stagedNonprofitRegistrationAuthorityId;
      }
      const authority = this.registrationAuthoritiesService.getAuthorityById(
        registrationAuthorityId
      );
      const countryCode = authority?.countryCode ||
        nonprofit?.addressObj?.countryCode ||
        stagedNonprofit?.address?.countryCode ||
        'US';

      if (data.assets?.length > 0) {
        existingAssets = this.convertToTableAssets(
          data.assets,
          data.nonprofitAdminRequestId
        );
      }

      return {
        ...data,
        country: countryCode,
        regAuthTypeUS: this.getOrgType(registrationAuthorityId),
        currentStep: 0,
        fiscal,
        myOrg: nonprofit,
        parentOrg: parent,
        stagedNonprofit,
        contact: {
          title: data.positionTitle,
          website: data.website,
          phone: data.workPhone,
          workEmail: data.workEmail
        },
        registrationAuthorityId,
        nonprofitAdmins: [],
        showNoAdminForm: false,
        currentRoleError: '',
        id: data.nonprofitAdminRequestId,
        isParent: data.isParent,
        isDraft: data.adminStatusId === AdminRequestStatusId.DRAFT,
        existingAssets
      } as ClaimStateDataWithAssets;
    } else {
      return ClaimStateDataWithAssets.default;
    }
  }

  adaptPayloadForCreateOrUpdate (
    claimData: ClaimStateDataWithAssets,
    isSubmission: boolean
  ) {
    const claimedOrgIsChapter: boolean = claimData.fiscal === FiscalType.Chapter;
    const claimedOrgIsExisiting = !!claimData.myOrg?.document.id;
    const stagedNonprofit: StagedNonprofit = claimData.stagedNonprofit;
    const adminForm = claimData.adminForm || {};

    const payload: NonprofitAdminRequestCreateModel = {
      isSubmission,
      nonprofitId: claimedOrgIsExisiting ?
        +claimData.myOrg.document.id :
        null,
      parentId: claimData.parentOrg ?
        +claimData.parentOrg.document.id :
        undefined,
      parentAdminFirstName: adminForm.firstName ||
        claimData.parentAdminFirstName,
      parentAdminLastName: adminForm.lastName ||
        claimData.parentAdminLastName,
      parentAdminEmailAddress: adminForm.email ||
        claimData.parentAdminEmailAddress,
      parentAdminPhoneNumber: adminForm.phone ||
        claimData.parentAdminPhoneNumber,
      workEmail: claimData.contact.workEmail,
      workPhone: claimData.contact.phone,
      positionTitle: claimData.contact.title,
      website: claimData.contact.website,
      stagedNonprofit: !!stagedNonprofit ?
        {
          name: stagedNonprofit.name,
          address: stagedNonprofit.address,
          contactFirstName: stagedNonprofit.contactFirstName,
          contactLastName: stagedNonprofit.contactLastName,
          contactPhoneNumber: stagedNonprofit.contactPhoneNumber,
          contactEmail: stagedNonprofit.contactEmail,
          registrationId: stagedNonprofit.registrationId || null
        } : null,
      claimChapter: claimedOrgIsChapter,
      isParent: false
    };

    return payload;
  }

  constructSearchResult (nonprofit: BasicNonprofit|NewChapter) {
    return SearchResult.construct<SearchResult>({
      text: nonprofit.name,
      document: {
        address1: nonprofit.address.address1,
        city: nonprofit.address.city,
        country: nonprofit.address.countryCode,
        iconURL: (nonprofit as BasicNonprofit).nonprofitIconImageUrl || '',
        id: ((nonprofit as BasicNonprofit).id + '' || ''),
        name: nonprofit.name,
        postalCode: nonprofit.address.postalCode,
        registrationId: (nonprofit as BasicNonprofit).registrationId || null,
        stateProvRegCode: nonprofit.address.stateProvRegCode,
        stateProvRegId: nonprofit.address.stateProvRegId
      }
    });
  }

  getOrgType (authorityId: number): number {
    if (authorityId) {
      switch (authorityId) {
        case 1:
        default:
          return RegAuthorityTypeForUS.NonprofitPrivateOrReligious;
        case 2:
        case 3:
          return RegAuthorityTypeForUS.PublicSchoolOrDistrict;
        case 30:
          return RegAuthorityTypeForUS.NativeAmericanTribe;
      }
    }

    return 0;
  }

  updateClaimDataWithAssets (attrs: any) {
    this.set(
      'claimStateDataWithAssets',
      {
        ...this.claimDataWithAssets,
        ...attrs
      }
    );
    this.setStoredClaimData(this.claimDataWithAssets);
  }

  resetClaimData () {
    this.setClaimDataWithAssets(ClaimStateDataWithAssets.default);
    this.setCurrentStep(0);
    this.setActiveExistingClaimId(
      null
    );
    this.setActiveExistingStatusId(null);
  }

  async setClaimsAssetTypes () {
    const defaultRegAuth = this.defaultRegAuthForAssets;
    const types = await this.getClaimsAssetTypes(
      this.claimDataWithAssets?.registrationAuthorityId ||
      defaultRegAuth
    );
    const sorted = this.sortAssets(types);
    this.set('claimsAssetTypes', sorted);
  }

  sortAssets (assets: AssetType[]) {
    const sortedAssets = this.arrayHelper.sort(assets, 'name');
    const indexOfOther = sortedAssets.findIndex(asset => asset.name === 'Other');
    if (indexOfOther !== -1) {
      return [
        ...sortedAssets.slice(0, indexOfOther),
        ...sortedAssets.slice(indexOfOther + 1),
        sortedAssets[indexOfOther]
      ];
    }

    return sortedAssets;
  }

  async setAllCurrentClaimDataAndExistingFiles () {
    const response = await this.getRequestDetail();
    if (!!response) {
      const assetsEnabled = (this.isNpoUser &&
        (response.isDraft || !response.nonprofitAdminRequestId)) ||
        (!this.isNpoUser &&
          response.adminStatusId === AdminRequestStatusId.PENDING);
      this.setClaimDataWithAssets(response);
      this.setFormsDisabled(
        !!response.nonprofitAdminRequestId &&
        !response.isDraft
      );
      this.setAssetsDisabled(!assetsEnabled);
    } else {
      this.setDefaultClaimData();
    }
  }

  async setClaimDetailsForReview () {
    const response = await this.claimResources.getRequestDetailForReview(
      this.claimIdForReview
    );
    this.set('claimDetailsForReview', response);
    this.set('claimIdForReview', null);
  }

  setAllExistingClaimsDataFromRequests () {
    const allAdminRequests = this.myNonprofitsService.requestsForUser;
    this.setExistingDraftClaims(allAdminRequests);
  }

  async getClaimsAssetTypes (registrationAuthorityId: number) {
    const payload: AssetTypesGet = {
      registrationAuthorityId,
      workflowId: AssetWorkflowType.ADMIN_REG
    };
    try {
      const response = await this.assetManagementService.fetchAssetTypesWorkflow(
        payload,
        true // skipToastr
      );

      return response as AssetType[];
    } catch (e) {
      throw e;
    }
  }

  async getRequestDetail (): Promise<ClaimStateDataWithAssets> {
    let data: NonprofitAdminRequestDetailModel;
    if (this.isNpoUser) {
      data = await this.claimResources.getRequestDetail(
        this.activeExistingClaimId
      );
    } else {
      data = await this.claimResources.getRequestDetailForReview(
        this.activeExistingClaimId
      );
    }
    const adaptedResponse = this.adaptRequestDataToClaimData(data);

    return adaptedResponse;
  }

  async fetchRegistrationAuthNamesForSearch (
    countryCode: string,
    organizationType?: number
  ): Promise<RegistrationAuthorityInfoModel[]> {
    const response = await this.claimResources.fetchRegistrationAuthNamesForSearch(
      countryCode,
      organizationType
    );

    return response;
  }
}
