
import { Component, OnInit } from '@angular/core';
import { SystemHealthDashboardStats } from '@core/typings/system-health-dashboard.typing';
import { DebounceFactory, TableDataFactory } from '@yourcause/common';
import { ModalFactory } from '@yourcause/common/modals';
import { SystemHealthDashboardService } from '../system-health-dashboard.service';
import { SystemHealthDashboardDetailsModalComponent } from '../system-health-dashboard-modal/system-health-dashboard-modal.component';

@Component({
  selector: 'npp-system-health-dashboard-page',
  templateUrl: './system-health-dashboard-page.component.html',
  styleUrls: ['./system-health-dashboard-page.component.scss']
})
export class SystemHealthDashboardPageComponent implements OnInit {
  tableDataFactory: TableDataFactory<SystemHealthDashboardStats>;

   constructor (
    private systemHealthDashboardService: SystemHealthDashboardService,
    private modalFactory: ModalFactory
  ) { }

  async ngOnInit () {
    this.tableDataFactory = DebounceFactory.createSimple(
      async () => {
        const result = await this.systemHealthDashboardService.getSystemHealthDashboardStats();

        return {
          success: true,
          data: {
            recordCount: result.endpointResponse.length,
            records: result.endpointResponse
          }
        };
      }
    );
  }

  async viewHealthIndicatorDetails (
    healthIndicatorStats: SystemHealthDashboardStats
  ) {
    await this.modalFactory.open(
      SystemHealthDashboardDetailsModalComponent,
      {
        healthIndicatorStats
      },
      { class: 'modal-full-size' }
    );
  }
}
