import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Notification } from '@core/models/notifications.model';
import { YCModalComponent } from '@yourcause/common/modals';
import { NotificationsService } from '../../../home/services/notifications.service';
import { NonprofitService } from '../../../nonprofit/nonprofit.service';


@Component({
  selector: 'yc-nonprofit-notification-modal',
  template: `
    <ng-container *ngIf="ready">
      <yc-modal
        [simple]="true"
        [modalHeader]="notification.title"
        [cancelButtonText]="'common:btnClose' | ycI18n:'Close'"
        [secondaryButtonText]="notification.isDismissable ? ('common:btnDismiss' | ycI18n: 'Dismiss') : ''"
        (onCancel)="onCancel()"
        (onSecondaryClick)="_onDismiss(notification.notificationId)">
        <div class="notification-container"
          (click)="navigateTo()">
          <div class="notification-body"
            [innerHTML]="modalContent">
          </div>
          <div *ngIf="notification.nonprofit"
            class="mb-3">
            <div class="d-flex justify-content-center align-items-center">
              <div>
                <img [src]="notification.nonprofit.nonprofitIconImageUrl"
                  [alt]="'common:textNonprofitIcon' | ycI18n">
              </div>
              <div>
                <yc-button
                  [link]="true">
                    {{ notification.nonprofit.name }}
                </yc-button>
              </div>
            </div>
          </div>
        </div>
      </yc-modal>
    </ng-container>
`,
styleUrls: ['./nonprofit-notification-modal.component.scss']
})

export class NonprofitNotificationModalComponent extends YCModalComponent<void> implements OnInit {
  @Input() notification: Notification;
  ready = false;
  modalTitle: string;
  modalContent: string;

  constructor (
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private nonprofitService: NonprofitService,
    private notificationsService: NotificationsService
  ) {
    super();
  }

  ngOnInit () {
    this.modalTitle = this.notification.title;
    this.modalContent = this.notification.subtitle;
    this.ready = true;
  }

  async navigateTo () {
    this.onCancel();
    if (this.notification.setActiveNpoOnNav) {
      await this.nonprofitService.setSelectedNpo(
        this.notification.nonprofit.nonprofitId
      );
      this.router.navigate([this.notification.actionUrl], {
        relativeTo: this.activatedRoute
      });
    } else {
      this.router.navigate([this.notification.actionUrl], {
        relativeTo: this.activatedRoute
      });
    }
    this.notificationsService.setModalNotificationClosed(true);
  }

  async _onDismiss (id: string) {
    await this.notificationsService.dismissNotification(+id);
    this.notificationsService.setModalNotificationClosed(true);
    this.onCancel();
  }

  onCancel () {
    this.closeModal.emit();
  }
}
