import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenResponse } from '@core/typings/token.typing';
import { APIResponse } from '@yourcause/common';
import { TokenAdapterService } from './token-adapter.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TokenRefreshResources {

  constructor (
    private http: HttpClient,
    private adapter: TokenAdapterService
  ) { }

  protected doRefresh (refreshToken: string, userId: number, clientIdentifier: string) {
    return lastValueFrom(this.http.post<APIResponse<TokenResponse>>('/api/admin/Sso/Refresh', {
      refreshToken,
      userId,
      clientIdentifier
    }));
  }

  async refreshToken (
    refreshToken: string,
    userId: number,
    clientIdentifier: string
  ): Promise<TokenResponse> {
    const response = await this.doRefresh(refreshToken, userId, clientIdentifier);

    try {
      return this.adapter.handleTokenRequest(response.data);
    } catch (err) {
      const e = err as HttpErrorResponse;
      if (e.status === 400) {
        return null;
      }
      throw e;
    }
  }
}
