import { Pipe, PipeTransform } from '@angular/core';
import { ExternalDonorInfo } from '@core/models/donation.model';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'nppMatchedDonorInfoPipe'
})
export class MatchedDonorInfoPipe implements PipeTransform {
constructor (
  private i18n: I18nService
) { }

  transform (
    info: ExternalDonorInfo
  ): string {
    let returnVal: string;
    const primaryIsEmpty = Object.values(info.primaryAddress).every((val) => val === '');
    const secondaryIsEmpty = Object.values(info.secondaryAddress).every((val) => val === '');
    const nonNestedIsEmpty = Object.values(info).every((val) => {
      if (typeof val === 'string') {
        return val === '';
      }

      return !!val;
    });

    if (primaryIsEmpty && secondaryIsEmpty && nonNestedIsEmpty) {
      returnVal = '';
    } else if (!nonNestedIsEmpty) {
      returnVal = this.i18n.translate(
        'common:textMatchForUser',
        {
          name: info.firstName + ' ' + info.lastName
        },
        'Match for __name__'
      );
    }

    return returnVal;
  }
}
