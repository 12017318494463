import { inject } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { TokenService } from '@core/services/token/token.service';
import { NonprofitService } from '../../nonprofit/nonprofit.service';
import { ResolveFn } from '@angular/router';

export const unsetActiveNpoResolver: ResolveFn<boolean> = async () => {
  const nonprofitService = inject(NonprofitService);
  const authService = inject(AuthService);
  const tokenService = inject(TokenService);

  if (!!nonprofitService.selectedNpo &&
    !!nonprofitService.activeNpo &&
    authService.isInternalAdmin()
  ) {
    nonprofitService.setSelectedNpo(undefined);
    tokenService.setActiveNpoId(undefined);
  } else if (!nonprofitService.activeNpo) {
    return true;
  }

  return false;
}
