import { Injectable } from '@angular/core';
import { Notification } from '@core/models/notifications.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class NotificationsResources {
  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getNotifications () {
    const response = await this.httpRestService.get<APIResponse<Notification[]>>(
      'api/Notification/GetNotifications'
    );

    return response.data;
  }

  async dismissNotification (notificationId: number) {
    const endpoint = 'api/Notification/DismissNotification';
    const payload = {
      notificationId
    };
    const response = await this.httpRestService.post(endpoint, payload);

    return response;
  }
}
