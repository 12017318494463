import { Injectable } from '@angular/core';
import { ClassificationResources } from '@core/resources/classification.resources';
import { Classification, InflectService } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { Nonprofit } from '../models/npo.model';
import { ClassificationState } from '../state/classification.state';
import { SelectOption } from '@yourcause/common/core-forms';

@AttachYCState(ClassificationState)
@Injectable({ providedIn: 'root' })
export class ClassificationService extends BaseYCService<ClassificationState>{

  constructor (
    private inflect: InflectService,
    private i18n: I18nService,
    private notifier: NotifierService,
    private classificationResources: ClassificationResources
  ) {
    super();
  }

  get classifications () {
    return this.get('classifications');
  }

  get subsections () {
    return this.get('subsections');
  }

  async getClassifications () {
    try {
      const response = await this.classificationResources.getClassifications();
      const adapted = this.buildHierarchy(response.data);
      this.set('classifications', adapted);
    } catch (e) {
      this.notifier.error(
        this.i18n.translate(
          'common:textErrorGettingClassifications',
          {},
          'There was an error fetching nonprofit classifications'
        )
      );
    }
  }

  async getSubsections () {
    try {
      const response = await this.classificationResources.getSubsections();
      this.set('subsections', response.data);
    } catch (e) {
      this.notifier.error(
        this.i18n.translate(
          'common:textErrorGettingSubsectionCodes',
          {},
          'There was an error fetching nonprofit subsection codes'
        )
      );
    }
  }

  async setClassification (payload: Partial<Nonprofit>) {
    try {
      const response = await this.classificationResources.setClassification(payload);

      return response;
    } catch (e) {
      this.notifier.error(
        this.i18n.translate(
          'common:textErrorSettingClassification',
          {},
          'There was an error setting nonprofit classification'
        )
      );

      return null;
    }
  }

  buildHierarchy (list: Classification[]) {
    const hierarchicalMap: { [x: string]: Classification } = {};
    const childMap: { [x: string]: Classification } = {};
    const flatMap = list.reduce((map, classification) => {
      classification.code = (classification.code || '').trim();
      classification.parentCode = (classification.parentCode || '').trim();

      classification.display = this.i18n.translate(
        `nonprofits:text${this.inflect.trimPuncAndPascalize(classification.name)}`,
        {},
        classification.name
      );
      classification.value = classification;

      classification.children = map[classification.code] ? map[classification.code].children : [];

      map[classification.code] = classification;

      if (classification.parentCode) {
        map[classification.parentCode] = map[classification.parentCode] || {
          children: []
        } as Classification;
        map[classification.parentCode].children.push(classification);
        childMap[classification.code] = classification;
      } else {
        classification.isRoot = true;
        hierarchicalMap[classification.code] = classification;
      }

      return map;
    }, {} as { [x: string]: Classification });

    return {
      childMap,
      hierarchicalMap,
      flatMap,
      list
    };
  }

  getOrgClassification (
    type: string,
    classificationOptions: SelectOption<Classification>[],
    allClassifications: Classification[],
    org: Nonprofit
  ) {
    let valToCompare = 0;
    let levelOne = 0;
    let levelTwo = 0;
    let levelThree = 0;
    if (org) {
      if (org.classificationInfo) {
        levelOne = org.classificationInfo.classificationOneId;
        levelTwo = org.classificationInfo.classificationTwoId;
        levelThree = org.classificationInfo.classificationThreeId;
      }
    } else {
      levelOne = classificationOptions[0].value.id;
    }
    switch (type) {
      case 'ntee':
        if (org) {
          valToCompare = levelThree || levelTwo || levelOne;
        }
        break;
      case 'cat':
        valToCompare = levelOne;
      break;
      case 'group':
        valToCompare = levelTwo;
      break;
    }
    const returnVal = allClassifications.find((ntee) => {
      return +ntee.id === +valToCompare;
    });

    return returnVal || null;
  }
}
