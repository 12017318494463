import { Injectable } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { SystemHealthDashboardResources } from './system-health-dashboard.resources';

@Injectable({ providedIn: 'root' })
export class SystemHealthDashboardService {

   constructor (
    private systemHealthDashboardResources: SystemHealthDashboardResources,
    private confirmAndTakeActionService: ConfirmAndTakeActionService,
    private i18n: I18nService
  ) {}

  async getSystemHealthDashboardStats () {
    return await this.confirmAndTakeActionService.genericTakeAction(
      () => this.systemHealthDashboardResources.getSystemHealthDashboardStats(),
      null,
      this.i18n.translate(
        'common:textErrorFetchingSystemHealthDashboardStats',
        {},
        'There was an error fetching system health dashboard stats'
      )
    );
  }

  async getSystemHealthDashboardDetails (healthIndicatorId: number) {
    return await this.confirmAndTakeActionService.genericTakeAction(
      () => this.systemHealthDashboardResources.getSystemHealthDashboardDetails(healthIndicatorId),
      null,
      this.i18n.translate(
        'common:textErrorFetchingSystemHealthDashboardDetails',
        {},
        'There was an error fetching system health dashboard details'
      )
    );
  }
}
