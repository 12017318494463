import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationRef, Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { YCCoreModule } from '@core/_core.module';
import { environment } from '@environment';
import { YCCommonModule } from '@yourcause/common';
import { LoggingModule } from '@yourcause/common/logging';
import { NotifierModule } from '@yourcause/common/notifier';
import { AppInsightsService } from '@yourcause/common/utils';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Subscription } from 'rxjs';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { HeapModule } from '@yourcause/common/heap';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    YCCoreModule,
    YCCommonModule,
    LoggingModule,
    NotifierModule,
    BrowserModule,
    HeapModule,
    RouterModule.forRoot(routes, {}),
    HttpClientModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot()
  ],
  providers: [
    AppInsightsService
  ],
  exports: [
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    TooltipModule,
    YCCoreModule,
    YCCommonModule,
    AppComponent
  ],
  bootstrap: [AppComponent]
})
export class AppSharedModule {
  static injector: Injector;
  subscription: Subscription;
  appState: any;

  constructor (
    public appRef: ApplicationRef,
    injector: Injector,
    appInsightsService: AppInsightsService
  ) {
    AppSharedModule.injector = injector;
    appInsightsService.config = {
      instrumentationKey: environment.insightsKey
    };
    appInsightsService.init();
  }
}
