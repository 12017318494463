import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nppStringCommaSpace'
})
export class StringCommaSpacePipe implements PipeTransform {
  constructor (
  ) { }

  transform (val: string): string {
    const stringWithSpaces = val.replace(/,/g, ', ');

    return stringWithSpaces;
  }
}
