import { Injectable } from '@angular/core';
import { Behaviors, TokenResponse } from '@core/typings/token.typing';
import { GuidService } from '@yourcause/common/utils';

@Injectable({ providedIn: 'root' })
export class TokenStorageService {

  constructor (
    private guidService: GuidService
  ) { }

  set jwt (jwt: TokenResponse) {
    localStorage.setItem(Behaviors.userTokenKey, JSON.stringify(jwt));
  }

  get jwt () {
    const token = localStorage.getItem(Behaviors.userTokenKey);

    try {
      return JSON.parse(token) as TokenResponse;
    } catch (e) {
      console.error(e, {
        subMessage: 'Error parsing JWT got: ' + token
      });
    }

    return null;
  }

  revoke () {
    localStorage.removeItem(Behaviors.userTokenKey);
  }

  overrideClientIdentifier (id: string) {
    localStorage.setItem(Behaviors.clientIdentifierKey, id);
  }

  get clientIdentifier () {
    let identifier = localStorage.getItem(Behaviors.clientIdentifierKey);

    if (!identifier) {
      identifier = this.guidService.nonce();
      localStorage.setItem(Behaviors.clientIdentifierKey, identifier);
    }

    return identifier;
  }
}
