import { Pipe, PipeTransform } from '@angular/core';
import { EXTERNAL_SYSTEMS } from '@core/models/donation.model';
import { VettingRequestStatusId } from '@core/models/status.model';
import { VettingRequestAPI } from '@core/typings/api/vetting-request.typing';

@Pipe({
  name: 'nppVettingRequestedBy'
})
export class VettingRequestedBy implements PipeTransform {
  VettingRequestAPI = VettingRequestAPI;
  EXTERNAL_SYSTEMS = EXTERNAL_SYSTEMS;
  transform (
    statusId: number,
    row: VettingRequestAPI.VettingRequestDisplay
  ): string {
    let requestedBy;
    switch (statusId) {
      case VettingRequestStatusId.REQUEST_SUBMITTED:
        requestedBy = row.affiliateEmployeeFirstName ?
          (row.affiliateEmployeeFirstName + ' ' + row.affiliateEmployeeLastName) :
          '';
        break;
      default:
      requestedBy = row.affiliateEmployeeFirstName ?
        (row.affiliateEmployeeFirstName + ' ' + row.affiliateEmployeeLastName) :
          row.createdByGcUser ?
            row.createdByGcUser : '';
    }

    return requestedBy;
  }
}
