import { Injectable } from '@angular/core';
import { RegistrationAuthority, TopLevelFilterOption } from '@yourcause/common';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { RegistrationAuthoritiesResources } from './registration-authorities.resources';
import { RegistrationAuthoritiesState } from './registration-authorities.state';
import { I18nService } from '@yourcause/common/i18n';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { RegistrationAuthorityValidForNonprofitCreate } from './registration-authorities-typings';

@AttachYCState(RegistrationAuthoritiesState)
@Injectable({ providedIn: 'root' })
export class RegistrationAuthoritiesService extends BaseYCService<RegistrationAuthoritiesState> {
  defaultRegAuthText = this.i18n.translate(
    'search:textAllRegAuths',
    {},
    '-- All registration authorities --'
);
  constructor (
    private registrationAuthoritiesResources: RegistrationAuthoritiesResources,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService
  ) {
    super();
  }

  get authorities () {
    return this.get('authorities');
  }

  get allRegAuthsForSelect () {
    return this.get('allRegAuthsForSelect');
  }

  get regAuthsForNonprofitAdd () {
    return this.get('regAuthsForNonprofitAdd');
  }

  setAuthorities (auths: RegistrationAuthority[]) {
    this.set('authorities', auths);
  }

  async getAllRegistrationAuthorities (force = false) {
    if (force || !this.authorities) {
      const response = await this.registrationAuthoritiesResources.getRegistrationAuthorities();
      this.setAuthorities(response);
      this.setAllRegAuthsForSelect();

      return response;
    }

    return this.authorities;
  }

  async getRegistrationAuthoritiesForNonprofitAdd () {
    const vaildRegistrationAuthorities = (await this.registrationAuthoritiesResources.getRegistrationAuthoritiesValidForNonprofitCreate()).registrationAuthorities;

    const validOptions = this.arrayHelper.sort(
        vaildRegistrationAuthorities.map((registrationAuthority: RegistrationAuthorityValidForNonprofitCreate) => {
          return {
            label: registrationAuthority.name,
            value: registrationAuthority.id
          };
        }), 'label'
      );

      this.set('regAuthsForNonprofitAdd', validOptions);
  }

  async registrationAuthorityAndRegistrationIdExist (registrationId: string, registrationAuthorityId: number) {
    return await this.registrationAuthoritiesResources.registrationAuthorityAndRegistrationIdExist(registrationAuthorityId, registrationId)
  }

  getAuthorityById (id: number) {
    return this.authorities?.find((auth) => {
      return auth.registrationAuthorityId === id;
    });
  }

  setAllRegAuthsForSelect () {
    const allRegAuths = this.authorities;
    const regAuthSelectVals: TopLevelFilterOption[] = allRegAuths.map((regAuth) => {
      return {
        value: regAuth.registrationAuthorityName,
        display: regAuth.registrationAuthorityName
      };
    });
    this.arrayHelper.sort(regAuthSelectVals, 'display');
    regAuthSelectVals.unshift({
      display: this.defaultRegAuthText,
      value: null
    });
    this.set('allRegAuthsForSelect', regAuthSelectVals);
  }
}
