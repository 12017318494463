import { Component, Input } from '@angular/core';
import { PaymentCard } from '../../models/payment-cards.model';

@Component({
  selector: 'yc-payment-cards',
  templateUrl: './payment-cards.component.html',
  styleUrls: ['./payment-cards.component.scss']
})
export class PaymentCardsComponent {
  @Input() items: PaymentCard[];
  @Input() columnClass: string;
  @Input() zeroState: boolean;
}


