
import {filter, map, mergeMap} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { lastValueFrom } from 'rxjs';
import { TourDefinition, TourService } from '@yourcause/common/tour';

@Injectable({
  providedIn: 'root'
})
export class TourStorageService {
  private completedTours: string[];
  masterTourList: TourDefinition[] = [{
    tourKey: 'NEW_USER',
    tourName: this.i18n.translate(
      'tours:lblNewUserTour',
      {},
      'Workspace and Navigation'
    ),
    tourDescription: this.i18n.translate(
      'tours:textNewUserTourDescription',
      {},
      'This introductory tour takes you through the basic navigation and features found on "My Workspace"'
    ),
    tourLocation: '/my-workspace'
  // SAVING FOR PAYMENTS TOUR
  }, {
    tourKey: 'PAYMENTS_DOWNLOAD',
    tourName: this.i18n.translate(
      'tours:lblPayments',
      {},
      'Payments'
    ),
    tourDescription: this.i18n.translate(
      'tours:textPaymentsDescription',
      {},
      'Learn about downloading donor details from payments that you receive through YourCause'
    ),
    tourLocation: '/giving/payments'
  }];

  constructor (
    private tourService: TourService,
    private http: HttpClient,
    private i18n: I18nService
  ) {
    tourService.toursCompleted$
      .pipe(filter(completedTour => !this.completedTours.includes(completedTour)))
      .pipe(mergeMap(completedTour => this.http.post('/api/MyAccount/CompleteUserTour', {
        tourKey: completedTour
      })
      .pipe(map(() => this.completedTours.push(completedTour)))))
      .subscribe();
  }

  private initCompletedTours (cb: () => any) {
    if (!this.completedTours) {
      this.getCompletedTours()
        .subscribe(() => cb());
    } else {
      cb();
    }
  }

  async setTourAsIncomplete (tourName: string) {
    await this.resetTour(tourName);
    this.completedTours = this.completedTours.filter(tour => tour !== tourName);
    this.tourService.toursCompleted = this.completedTours;
  }

  initTour (tourName: string) {
    this.initCompletedTours(() => {
      if (!this.completedTours.includes(tourName)) {
        this.tourService.initTour(tourName);
      }
    });
  }
  getCompletedTours () {
    return this.http.get<APIResponse<{tourKey: string}[]>>('/api/MyAccount/GetToursCompleted').pipe(
      map((response) => {
        this.completedTours = response.data
          .map(val => val.tourKey);
        this.tourService.toursCompleted = this.completedTours;

        return this.completedTours;
      }));
  }

  async resetTour (tourName: string) {
    return new Promise<void>((res) => this.initCompletedTours(res))
      .then(() => this.completedTours.includes(tourName) ?
        lastValueFrom(this.http.post('/api/MyAccount/RemoveUserTourCompletion', {
          tourKey: tourName
        })) :
        null
      );
  }
}
