import { Injectable } from '@angular/core';
import { Asset, BucketTypes } from '@core/models/asset.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse } from '@yourcause/common';
import { SpinnerService } from '../spinner.service';
import { NotifierService } from '@yourcause/common/notifier';
import { I18nService } from '@yourcause/common/i18n';
import { FileService, YcFile } from '@yourcause/common/files';

/**
 * Generic endpoints and resources for assets.
 */
@Injectable({ providedIn: 'root' })
export class AssetResources {
  constructor (
    private httpRestService: HttpRestService,
    private fileService: FileService,
    private spinnerService: SpinnerService,
    private notifier: NotifierService,
    private i18n: I18nService
  ) { }

  /**
   * Upload a YcFile to the server.
   * @param file file to upload.
   * @param bucketId bucket to store the file in.
   * @param assetTypeId optional asset type id.
   * @param comments optional asset comments.
   * @returns
   */
  uploadYcFile (file: YcFile, bucketId: BucketTypes, assetTypeId?: number, comments?: string) {
    if (!file || !file.file) {
      return null;
    }

    const formData = new FormData();
    formData.append('bucketId', bucketId.toString());
    formData.append('files', file.file, file.fileName);

    if (assetTypeId) {
      formData.append('assetTypeId', assetTypeId.toString());
    }

    if (comments) {
      formData.append('comments', comments);
    }

    var request = this.httpRestService.post<APIResponse<Asset[]>>('/api/Assets', formData);

    return request.then(e => e.data[0]);
  }

  /**
   * Get asset metadata by id.
   * @param assetId asset identity.
   * @returns
   */
  getAssetById (assetId: string) {
    return this.httpRestService.get<APIResponse<Asset>>(
      `/api/Assets/${assetId}`
    );
  }

  /**
   * Download an asset.
   * @param asset asset to download
   */
  async downloadAsset (asset: Asset) {
    await this.download(asset.assetId, asset.fileName);
  }

  /**
   * Download an asset by id.
   * @param assetId asset id to download
   * @param fileName file name to send to the browser to saveAs (test.txt, etc.)
   */
  async download (assetId: string, fileName: string) {
    this.spinnerService.startSpinner();
    try {
      await this.fileService.downloadUrlAs(
        `/api/${assetId}`,
        fileName
      );
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        'common:notificationErrorDownloadingFile',
        {},
        'There was an error downloading the file'
      ));
    }
    this.spinnerService.stopSpinner();
  }
}
