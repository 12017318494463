import { Injectable } from '@angular/core';
import { CheckInfoPayload } from '@core/models/claim.model';
import { CodeType, NonprofitIRSCodes } from '@core/models/nonprofit-irs-code.model';
import { BasicNonprofit, CreateNonprofitApi, DisplayPayload, GetNonprofitAdditionalInformationResponseModel, Nonprofit, NonprofitAPI, NonprofitByCheckInfo, NonprofitRegAuthAttributes, NonprofitStatsSummaryModel, SearchMoreDetails, UpdateNPOClassificationPayload } from '@core/models/npo.model';
import { RevocationDetailsBySubdivision } from '@core/models/revocation.model';
import { User } from '@core/models/user.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse, APIResult, NonprofitSummaryInfoScenario, PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class NonprofitResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  updateNonprofitDisplayInfo (displayPayload: DisplayPayload) {
    return this.httpRestService.post<APIResponse<Nonprofit>>(
      '/api/Nonprofit/Profile/UpdateNonprofitDisplayInfo',
      displayPayload
    );
  }

  getNonprofitsForAdmin () {
    return this.httpRestService.get<APIResponse<BasicNonprofit[]>>(
      '/api/Nonprofit/Profile/GetNonprofitsForAdmin'
    );
  }

  getNonprofit (nonprofitId: number) {
    return this.httpRestService.get<APIResponse<NonprofitAPI>>(
      `/api/Nonprofit/GetNonprofit?nonprofitId=${nonprofitId}`
    );
  }

  getNonprofitAdmins (nonprofitId: number) {
    const endpoint = `api/NonprofitAdminRequest/GetAdmins?nonprofitId=${nonprofitId}`;

    return this.httpRestService.get<APIResponse<User[]>>(endpoint);
  }

  createEditOrganization (payload: CreateNonprofitApi, newOrganization: boolean) {
    const endpoint = '/api/nonprofits';

    return newOrganization ?
      this.httpRestService.post(
        endpoint,
        payload
      ) :
      this.httpRestService.put(
        endpoint,
        payload
      );
  }

  updateNonprofitClassification (
    payload: UpdateNPOClassificationPayload,
    nonprofitId: number
  ) {
    const endpoint = `/api/NonprofitClassification/UpdateNonprofitClassificationByNonprofitId?nonprofitId=${nonprofitId}`;

    return this.httpRestService.post<APIResponse<UpdateNPOClassificationPayload>>(
      endpoint, payload
    );
  }

  getStatsSummaryInfo (
    nonprofitId: number,
    scenario: NonprofitSummaryInfoScenario
  ) {
    return this.httpRestService.get<APIResponse<NonprofitStatsSummaryModel>>(
      `/api/Nonprofit/GetNonprofitSummaryInfo?NonprofitId=${nonprofitId}&scenario=${scenario}`
    );
  }

  getShowMoreDetail (nonprofitId: number) {
    return this.httpRestService.get<APIResponse<SearchMoreDetails>>(
      `/api/Nonprofit/GetSearchMoreDetails?NonprofitId=${nonprofitId}`
    );
  }

  /**
   * Get Revocation Details By State
   *
   * @param nonprofitId nonprofit identity
   * @param subdivisionCode state code (e.g. 'CA')
   * @param nonprofitGuid nonprofit identity
   * @returns
   */
  getRevocationDetails (nonprofitId: number, subdivisionCode: string) {
    let url = `api/nonprofits/${nonprofitId}/RevocationDetails`;
    if (subdivisionCode) {
      url += `?subdivisionCode=${subdivisionCode}`;
    }

    return this.httpRestService.get<APIResponse<Array<RevocationDetailsBySubdivision>>>(url);
  }

  getNonprofitIrsCodes (codeType: CodeType) {
    return this.httpRestService.get<APIResponse<NonprofitIRSCodes>>(
      `api/Nonprofit/GetNonprofitCodes?codeType=${codeType}`
    );
  }

  getStrictNonprofitsForAdmin () {
    return this.httpRestService.get<APIResponse<BasicNonprofit[]>>(
      '/api/Nonprofit/Profile/GetStrictNonprofitsForAdmin'
    );
  }

  getIrsAttribution (
    paginationOptions: PaginationOptions<NonprofitRegAuthAttributes>,
    nonprofitId: number
  ) {
    return this.httpRestService.post<APIResult<NonprofitRegAuthAttributes>>(
      'api/Nonprofit/GetIRSAttribution',
      {
        paginationOptions,
        nonprofitId
      }
    );
  }

  fetchNonprofitsWithCheckInfo (checkInfo: CheckInfoPayload) {
    const endpoint = 'api/Nonprofit/Finance/GetNonprofitByCheckInfo';

    return this.httpRestService.post<APIResponse<NonprofitByCheckInfo[]>>(
      endpoint,
      checkInfo
    );
  }

  getNonprofitAdditionalInformation (nonprofitId: number) {
    const endpoint = 'api/Nonprofit/GetNonprofitAdditionalInformation';
    const payload = { nonprofitId };

    return this.httpRestService.post<APIResponse<GetNonprofitAdditionalInformationResponseModel>>(
      endpoint,
      payload
    );
  }
}
