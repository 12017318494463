import { Injectable } from '@angular/core';
import { NonprofitAsset } from '@core/models/nonprofit-asset.model';
import { LogoPayload } from '@core/models/npo.model';
import { SpinnerService } from '@core/services/spinner.service';
import { CharityCompareAPI } from '@core/typings/api/charity-compare.typing';
import { APIResponse, AssetType, AssetTypes, AssetTypesGet, PaginationOptions, TableAsset } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { AssetManagementResources } from '../../asset-management/services/asset-management.resources';
import { AssetManagementState } from '../../asset-management/services/asset-management.state';
import { AssetResources } from '@core/services/assets/asset.resources';
import { FileService, FileTypeService, TableDataDownloadFormat, YcFile } from '@yourcause/common/files';

/**
 * Service for managing nonprofit assets.
 */
@AttachYCState(AssetManagementState)
@Injectable({ providedIn: 'root' })
export class AssetManagementService extends BaseYCService<AssetManagementState>{
  uploadVirusFoundError = this.i18n.translate(
    'nonprofits:notificationErrorUploadingAssetVirusFound',
    {},
    'There was an error uploading the asset file'
  );
  uploadVirusFoundErrorCode = 'Virus_Found';

  uploadVirusScanIncompleteError = this.i18n.translate(
    'nonprofits:notificationErrorUploadingAssetScanIncomplete',
    {},
    'There was an error uploading the asset file'
  );
  uploadVirusScanIncompleteErrorCode = 'Virus_Scan_Incomplete';

  uploadInvalidType = 'Invalid_File_Type';

  uploadInvalidSize = 'Invalid_File_Size';

  uploadRequestBodyTooLarge = 'Request_Body_Too_Large';

  uploadGenericError = this.i18n.translate(
    'nonprofits:notificationErrorUploadingAsset',
    {},
    'There was an error uploading the asset file'
  );

  constructor (
    private assetManagementResources: AssetManagementResources,
    private assetResources: AssetResources,
    private notifier: NotifierService,
    private i18n: I18nService,
    private fileService: FileService,
    private spinnerService: SpinnerService,
    private fileTypeService: FileTypeService
  ) {
    super();
  }

  get defaultAssetWorkflow () {
    return this.get('defaultAssetWorkflow');
  }

  get assets () {
    return this.get('assets');
  }

  get assetTypes () {
    return this.get('assetTypes');
  }

  get assetTypesWithWorkflow () {
    return this.get('assetTypesWithWorkflow');
  }

  setAssetTypes (types: AssetType[]) {
    this.set('assetTypes', types);
  }

  setassetTypesWithWorkflow (types: AssetType[]) {
    this.set('assetTypesWithWorkflow', types);
  }

  adaptAssetTypes (types: AssetType[]) {
    // moving 'Other' type to end of array
    let adaptedTypes: AssetType[];
    if (types?.length > 0) {
      const otherIndex = types.findIndex((type) => type.name === 'Other');
      if (otherIndex !== -1) {
        adaptedTypes = [
            ...types.slice(0, otherIndex),
            ...types.slice(otherIndex + 1),
            types[otherIndex]
          ];
      } else {
        adaptedTypes = types;
      }
    }

    return adaptedTypes;
  }

  downloadCsvOrExcel (
    options: PaginationOptions<any>,
    format: TableDataDownloadFormat,
    fileName: string,
    endpoint: string,
    customOptions: any
  ) {
    if (options) {
      return this.fileService.downloadPostUrlAs(
        endpoint,
        fileName,
        {
          paginationOptions: options,
          exportReportType: format
        }
      );
    } else {
      return this.fileService.downloadPostUrlAs(
        endpoint,
        fileName,
        customOptions
      );
    }
  }

  convertToTableAssets (
    assets: NonprofitAsset[],
    claimId: number
  ) {
    const convertedAssets: TableAsset[] = assets?.map((asset) => {
      return {
        fileName: asset.fileName,
        file: null,
        fileId: asset.assetId,
        optional: false,
        claimId,
        assetTypeId: asset.assetTypeId,
        name: asset.assetTypeName,
        fileUrl: asset.fileUrl,
        uploadDate: asset.uploadDate
      };
    });

    return convertedAssets;
  }

  constructAssetTypeObj (response: APIResponse<AssetType[]>) {
    return response?.data?.map(ent => AssetType.construct(ent));
  }

  determineAssetTypesOrDefault (response: APIResponse<AssetType[]>) {
    let types: AssetType[];
    if (response?.data.length > 0) {
      const assetTypesFromAPI = response.data.map(ent => AssetType.construct(ent));
      types = this.adaptAssetTypes(assetTypesFromAPI);
    } else {
      const defaultType = this.defaultAssetWorkflow;
      types = [
        defaultType
      ];
    }

    return types;
  }

  // APIs
  async fetchAssetTypesByRegAuthId (registrationAuthorityId: number) {
    try {
      const response = await this.assetManagementResources.fetchAssetTypesByRegAuthId(registrationAuthorityId);
      const constructed = this.constructAssetTypeObj(response);
      const adapted = this.adaptAssetTypes(constructed);
      this.setAssetTypes(adapted);

      return adapted;
    } catch (e) {
      this.notifier.error(
        this.i18n.translate(
          'common:textErrorFetchingAssetTypes',
          {},
          'There was an error fetching asset types'
        )
      );

      throw e;
    }
  }

  async fetchAssetTypesWorkflow (
    payload: AssetTypesGet,
    skipToastr: boolean
  ) {
    let endpoint = `/api/NonprofitAssets/GetAssetTypes?registrationAuthorityId=${payload.registrationAuthorityId}&workflowId=${payload.workflowId}`;

    if (!payload.registrationAuthorityId && !!payload.workflowId) {
      endpoint = `/api/NonprofitAssets/GetAssetTypes?workflowId=${payload.workflowId}`;
    } else if (!!payload.registrationAuthorityId && !payload.workflowId) {
      endpoint = `/api/NonprofitAssets/GetAssetTypes?registrationAuthorityId=${payload.registrationAuthorityId}`;
    }
    try {
      const response = await this.assetManagementResources.fetchAssetTypesWorkflow(endpoint);
      const types = this.determineAssetTypesOrDefault(response);
      this.setassetTypesWithWorkflow(types);

      return types;
    } catch (e) {
      if (!skipToastr) {
        this.notifier.error(
          this.i18n.translate(
            'common:textErrorFetchingAssetTypeWorkflows',
            {},
            'There was an error fetching asset type workflows'
          )
        );
      }

      return null;
    }
  }

  async fetchDefaultAssetType (payload: AssetTypesGet) {
    try {
      const response = await this.assetManagementResources.fetchDefaultAssetType(payload);

      return response;
    } catch (e) {
      this.notifier.error(
        this.i18n.translate(
          'common:textErrorFetchingDefaultAssetType',
          {},
          'There was an error fetching the default asset type'
        )
      );

      throw e;
    }
  }

  async fetchNonprofitAssets () {
    try {
      const assets = await this.assetManagementResources.getNonprofitAssets();
      const adapted = assets.data.records.map(record => NonprofitAsset.construct(record));
      this.set('assets', adapted);
    } catch (e) {
      this.notifier.error(
        this.i18n.translate(
          'common:textErrorFetchingAssets',
          {},
          'There was an error fetching assets'
        )
      );
    }
  }

  async setDefaultAssetWorkflow () {
    const response = await this.fetchDefaultAssetType({
      registrationAuthorityId: 6
    });
    const adapted = AssetType.construct(response.data[0]);
    this.set('defaultAssetWorkflow', adapted);
  }

  async getAssetById (assetId: string) {
    return await this.assetResources.getAssetById(assetId);
  }

  // UPLOAD

  /*
  For use with:
  api/NonprofitAssets/UploadAsset OR
  api/NonprofitAssets/UploadAssetByNonprofitId
  - URL is determined by nonprofitId
  */
  async uploadNonprofitAsset (
    file: Blob,
    typeId: number,
    fileName: string,
    comment: string,
    nonprofitId?: number
  ) {
    const formData = new FormData();
    formData.append('asset', file, fileName);
    formData.append('type', '' + typeId);
    let url = '';
    if (!!nonprofitId) {
      url = `/api/NonprofitAssets/UploadAssetByNonprofitId?nonprofitId=${nonprofitId}&assetTypeId=${typeId}`;
    } else {
      url = `/api/NonprofitAssets/UploadAsset?assetTypeId=${typeId}`;
    }
    if (comment) {
      url += `&comment=${encodeURIComponent(comment)}`;
    }
    try {
      const response = await this.assetManagementResources.uploadAssetToUrl(
        url,
        formData
      );
      this.notifier.success(this.i18n.translate(
        'nonprofits:notificationSuccessUploadingAsset',
        {},
        'Successfully uploaded asset file'
        ));

      return response;
    } catch (e: any) {
      this.handleUploadAssetErrorCodes(e);

      throw e;
    }
  }

  async uploadRemittanceRequestAsset (
    file: YcFile,
    comment: string,
    nonprofitAddressRequestId: number
  ) {
    let url = `/api/NonprofitAssets/UploadRemittanceRequestAsset?assetTypeId=6`;
    if (comment) {
      url += `&comment=${encodeURIComponent(comment)}`;
    }
    if (nonprofitAddressRequestId) {
      const nonprofitAdminRequestId = nonprofitAddressRequestId;
      url += `&nonprofitAdminRequestId=${nonprofitAdminRequestId}`;
    }
    try {
      const response = await this.assetManagementResources.postFile(
        url,
        file.file,
        'file',
        file.fileName
      );

      return response;
    } catch (e: any) {
      this.handleUploadAssetErrorCodes(e);

      return null;
    }
  }

  async uploadNoteAsset (
    nonprofitId: number,
    file: Blob,
    type: string,
    fileName: string,
    typeId = AssetTypes.NONPROFIT_NOTE
  ) {
    const formData = new FormData();
    formData.append('asset', file, fileName);
    formData.append('type', type);
    const url = `api/NonprofitAssets/UploadAssetByNonprofitId?nonprofitId=${nonprofitId}&assetTypeId=${typeId}`;

    try {
      const response = await this.assetManagementResources.uploadAssetToUrl(
        url,
        formData
      );

      return response;
    } catch (e: any) {
      this.handleUploadAssetErrorCodes(e);

      throw e;
    }
  }

  async uploadClaimAsset (
    assetToAdd: TableAsset,
    isNpoUser: boolean,
    activeExistingClaimId: number
  ) {
    const file = new YcFile(
      assetToAdd.fileName,
      assetToAdd.file
    );

    const url = !isNpoUser ?
      `api/NonprofitAdminRequest/UploadAssetForReview?nonprofitAdminRequestId=${activeExistingClaimId}&assetTypeId=${assetToAdd.assetTypeId}` :
      `api/NonprofitAdminRequest/UploadAsset?nonprofitAdminRequestId=${activeExistingClaimId}&assetTypeId=${assetToAdd.assetTypeId}`;

    const formData = new FormData();
    formData.append('asset', file.file, file.fileName);
    formData.append('type', '' + assetToAdd.assetTypeId);

    try {
      const response = await this.assetManagementResources.uploadAssetToUrl(
        url,
        formData
      );

      if (!isNpoUser) {
        this.notifier.success(this.i18n.translate(
          'claim:notificationSusseccfullyUpdatedDocuments',
          {},
          'Successfully updated the documents'
        ));
      }

      return response;
    } catch (e) {
      if (!isNpoUser) {
        this.handleUploadAssetErrorCodes(e, this.i18n.translate(
          'claim:notificationErrorUpdatingDocuments',
          {},
          'There was an error updating the documents'
        ));
      } else {
        this.handleUploadAssetErrorCodes(e);
      }

      throw e;
    }
  }

  async uploadCharityCompareBatch (
    batchPayload: CharityCompareAPI.CharityCompareBatchPayload
  ) {
    const url = 'api/PlatformTool/CharityCompare/create';
    const formData = new FormData();
    formData.append('file', batchPayload.sourceFile.file);
    formData.append('Description', batchPayload.description);
    formData.append('Name', batchPayload.name);
    if (batchPayload.clientId) {
      formData.append('ClientId', batchPayload.clientId);
    }
    if (batchPayload.excludeRegAuthId) {
      formData.append('ExcludeRegistrationAuthorityId', '' + batchPayload.excludeRegAuthId);
    }

    try {
      const response = await this.assetManagementResources.uploadAssetToUrl(
        url,
        formData
      );
      this.notifier.success(this.i18n.translate(
        'admin:textSuccessCreatingComparison',
        {},
        'Your comparison has been queued and will be processed shortly'
      ));

      return response;
    } catch (e) {
      this.handleUploadAssetErrorCodes(e, this.i18n.translate(
        'admin:textErrorProcessingComparisonReport',
        {},
        'There was an error processing the comparison report, please try again'
      ));

      throw e;
    }
  }

  async uploadStoryAsset (file: YcFile) {
    const formData = new FormData();
    formData.append('asset', file.file, file.fileName);
    const url = 'api/NonprofitStory/UploadImageFile';

    try {
      const response = await this.assetManagementResources.uploadAssetToUrl(
        url,
        formData
      );

      return response;
    } catch (e) {
      this.handleUploadAssetErrorCodes(e);

      return null;
    }
  }

  async uploadProgramAsset (file: YcFile) {
    const formData = new FormData();
    formData.append('asset', file.file, file.fileName);
    const url = 'api/Program/UploadProgramImage';
    try {
      const response = await this.assetManagementResources.uploadAssetToUrl(
        url,
        formData
      );

      return response;
    } catch (e) {
      this.handleUploadAssetErrorCodes(e);

      return null;
    }
  }

  async uploadVettingApplicationAsset (
    file: YcFile,
    nonprofitVettingApplicationId: number,
    assetTypeId: number
  ) {
    const formData = new FormData();
    formData.append('asset', file.file, file.fileName);
    const url = `api/NonprofitVettingApplication/UploadAsset?nonprofitVettingApplicationId=${nonprofitVettingApplicationId}&assetTypeId=${assetTypeId}`;

    try {
      const response = await this.assetManagementResources.uploadAssetToUrl(
        url,
        formData
      );

      return response;
    } catch (e) {
      this.handleUploadAssetErrorCodes(e);

      throw e;
    }
  }

  async uploadVolunteerEventFile (file: YcFile) {
    const formData = new FormData();
    formData.append('asset', file.file, file.fileName);
    const url = 'api/Volunteer/UploadEventFile';

    try {
      const response = await this.assetManagementResources.uploadAssetToUrl(
        url,
        formData
      );

      return response;
    } catch (e) {
      console.error(e);
      this.handleUploadAssetErrorCodes(e);

      return null;
    }
  }

  async updateNpoImage (payload: LogoPayload) {
    const formData = new FormData();
    formData.append('image', payload.image);
    formData.append('type', payload.type);
    const url = `/api/Nonprofit/Profile/SetImage?imageType=${payload.type}`;
    try {
      const response = await this.assetManagementResources.uploadAssetToUrl(
        url,
        formData
      );

      return response;
    } catch (e) {
      this.handleUploadAssetErrorCodes(e);

      return null;
    }
  }

  async uploadParentChildAsset (
    childNonProfitId: number,
    file: Blob,
    type: string,
    typeId: number,
    fileName: string
  ) {
    const formData = new FormData();
    formData.append('asset', file, fileName);
    formData.append('type', type);
    const url = `/api/NonprofitAssets/UploadAssetByNonprofitId?nonprofitId=${childNonProfitId}&assetTypeId=${typeId}`;

    try {
      const response = await this.assetManagementResources.uploadAssetToUrl(
        url,
        formData
      );

      return response;
    } catch (e) {
      this.handleUploadAssetErrorCodes(e);

      throw e;
    }
  }

  /**
   * Remove an asset from a nonprofit.
   * @param assetId asset identifier to delete.
   */
  async removeNonprofitAsset (assetId: string) {
    try {
      await this.assetManagementResources.removeNonprofitAsset(assetId);
      this.notifier.success(this.i18n.translate(
        'nonprofits:notificationSuccessRemoveAsset',
        {},
        'Successfully deleted resource'
      ));
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        'nonprofits:notificationErrorRemovingAsset',
        {},
        'There was an error removing the resource'
      ));
      throw e;
    }
  }

  /**
   * Download File from the given url and save it as the specified file name.
   * @param url url to file download (ie: https://localhost/api/fileaccess/GetFile?assetId=1)
   * @param fileName name of the file to be downloaded
   */
  async downloadUrlAs (
    url: string,
    fileName: string
  ) {
    this.spinnerService.startSpinner();
    try {
      await this.fileService.downloadUrlAs(
        url,
        fileName
      );
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        'common:notificationErrorDownloadingFile',
        {},
        'There was an error downloading the file'
      ));
    }
    this.spinnerService.stopSpinner();
  }

  private handleUploadAssetErrorCodes (exception: any, messageOverride?: string) {
    let usingFileTypeService = false;

    let notifierMessage = this.uploadGenericError;

    if (!messageOverride) {
      if (exception?.error?.errorCode) {
        const errorCode = exception.error.errorCode;

        if (errorCode === this.uploadVirusFoundErrorCode) {
          notifierMessage = this.uploadVirusFoundError;
        } else if (errorCode === this.uploadVirusScanIncompleteErrorCode) {
          notifierMessage = this.uploadVirusScanIncompleteError;
        } else if (errorCode === this.uploadInvalidSize) {
          usingFileTypeService = true;

          this.fileTypeService.displayInvalidFileUploadErrorMessage('File size is invalid.', null);
        } else if (errorCode === this.uploadInvalidType) {
          usingFileTypeService = true;

          this.fileTypeService.displayInvalidFileUploadErrorMessage('File type is invalid.', null);
        } else if (errorCode === this.uploadRequestBodyTooLarge) {
          usingFileTypeService = true;

          this.fileTypeService.displayInvalidFileUploadErrorMessage('Request body too large', null);
        }
      }
    } else {
      notifierMessage = messageOverride;
    }

    if (!usingFileTypeService) {
      this.notifier.error(notifierMessage);
    }
  }
}
