import { Injectable } from '@angular/core';
import { NonprofitAsset } from '@core/models/nonprofit-asset.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse, AssetType, AssetTypesGet, PaginatedAPIResponse } from '@yourcause/common';

/**
 * Nonprofit asset management endpoints and resources.
 */
@Injectable({ providedIn: 'root' })
export class AssetManagementResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  /**
   * Get asset types by registration authority id.
   * @param registrationAuthorityId registration authority id
   * @returns
   */
  fetchAssetTypesByRegAuthId (registrationAuthorityId: number) {
    const endpoint = `/api/NonprofitAssets/GetAssetTypes?registrationAuthorityId=${registrationAuthorityId}`;

    return this.httpRestService.get<APIResponse<AssetType[]>>(endpoint);
  }

  /**
   * Get asset types for the given endpoint.
   * @param endpoint url of the endpoint
   * @returns
   */
  fetchAssetTypesWorkflow (endpoint: string) {
    return this.httpRestService.get<APIResponse<AssetType[]>>(endpoint);
  }

  /**
   * Get default asset types.
   * @param payload
   * @returns
   */
  async fetchDefaultAssetType (payload: AssetTypesGet) {
    const endpoint = `/api/NonprofitAssets/GetAssetTypes?registrationAuthorityId=${payload.registrationAuthorityId}&workflowId=1`;

    return this.httpRestService.get<APIResponse<AssetType[]>>(endpoint);
  }

  /**
   * Get nonprofit assets for the current administering nonprofit.
   * @returns
   */
  getNonprofitAssets () {
    return this.httpRestService.post<Promise<PaginatedAPIResponse<NonprofitAsset>>>(
      '/api/NonprofitAssets/GetAssets', {
      paginationOptions: {
        returnAll: true
      }
    });
  }

  /**
   * Remove an asset from a nonprofit.
   * @param assetId asset identifier to delete.
   * @returns
   */
  removeNonprofitAsset (assetId: string) {
    const endpoint = '/api/NonprofitAssets/RemoveAsset';
    const payload = {
      assetId
    };

    return this.httpRestService.post<APIResponse<any>>(endpoint, payload);
  }

  /*
   UPLOAD
  For use with:
  api/NonprofitAssets/UploadAsset,
  api/NonprofitAssets/UploadAssetByNonprofitId,
  api/NonprofitAssets/UploadRemittanceRequestAsset,
  api/NonprofitVetting/UploadAsset,
  api/NonprofitStory/UploadImageFile
  api/PlatformTool/CharityCompare/create
  api/NonprofitAdminRequest/UploadAssetForReview
  api/NonprofitAdminRequest/UploadAsset
  URL and formData are determined in service
  */
  uploadAssetToUrl (
    url: string,
    formData: FormData
  ) {
    return this.httpRestService.post<APIResponse<any>>(
      url,
      formData
    );
  }

  postFile (
    endpoint: string,
    file: File|Blob|string,
    paramName = 'file',
    fileName?: string
  ) {
    const name = !!fileName ? fileName : '';

    return this.httpRestService.postFile(
      endpoint,
      file,
      paramName,
      name
    );
  }
}
