import { Address, SearchResult, TableAsset } from '@yourcause/common';
import { StagedNonprofitForAdminRequest } from './admin-request.model';
import { NonprofitAsset } from './nonprofit-asset.model';
import { BasicMyNonprofit, BasicNonprofit } from './npo.model';
import { User } from './user.model';

export const NEW_CLAIM = 'new-claim';
export const TEMP_REG_AUTHORITIES = [
  'US Nonprofit',
  'Catholic Directory',
  'Private Schools'
];

export interface AdminForm {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

export interface ContactForm {
  title?: string;
  workEmail?: string;
  phone?: string;
  website?: string;
}

export interface NonprofitAdminRequestCreateModel {
  isSubmission: boolean;
  nonprofitId?: number|string;
  parentId: number|string;
  workEmail: string;
  workPhone: string;
  positionTitle: string;
  website: string;
  remittanceAddress?: Address;
  claimChapter: boolean;
  stagedNonprofit?: StagedNonprofit;
  isParent?: boolean;
  parentAdminFirstName?: string;
  parentAdminLastName?: string;
  parentAdminEmailAddress?: string;
  parentAdminPhoneNumber?: string;
}

export interface NonprofitAdminRequestUpdateModel extends NonprofitAdminRequestCreateModel {
  nonprofitAdminRequestId: number;
}

export class NonprofitAdminRequestDisplayModel {
  nonprofitAdminRequestId: number;
  adminStatusId: number;
  approvedByNonprofit: boolean;
  nonprofit: BasicMyNonprofit;
  parent: BasicNonprofit;
  dateSubmitted: string;
  assignedUser: User;
  submittedByUser: User;
  reviewedByNonprofitUser: User;
  approvedByUser: User;
  nonprofitId: number;
  parentId: number;
  workEmail: string;
  workPhone: string;
  positionTitle: string;
  website: string;
  remittanceName: string;
  remittanceAddress: Address;
  claimChapter: boolean;
  stagedNonprofit: StagedNonprofitForAdminRequest;
  isParent?: boolean;
  parentAdminFirstName: string;
  parentAdminLastName: string;
  parentAdminEmailAddress: string;
  parentAdminPhoneNumber: string;
  hasAdmins: boolean;
  parentHasAdmins: boolean;
  reviewedByNonprofitDate: string;
  approvedDate: string;
  compositeNonprofitName: string;
  publicComments?: string;
  internalComments?: string;
  reasonId?: number;
  isGCRequestedOrg?: boolean;
}

export class NonprofitAdminRequestDetailModel extends NonprofitAdminRequestDisplayModel {
  assets?: NonprofitAsset[];

  static get default () {
    return new this();
  }
}

export class ClaimStateDataWithAssets {
  nonprofitAdminRequestId?: number;
  adminStatusId?: number;
  approvedByNonprofit?: boolean;
  nonprofit?: BasicMyNonprofit;
  parent?: BasicNonprofit;
  dateSubmitted?: string;
  assignedUser?: User;
  submittedByUser?: User;
  reviewedByNonprofitUser?: User;
  approvedByUser?: User;
  nonprofitId?: number;
  parentId?: number;
  workEmail?: string;
  workPhone?: string;
  positionTitle?: string;
  website?: string;
  remittanceName?: string;
  remittanceAddress?: Address;
  claimChapter?: boolean;
  stagedNonprofit?: StagedNonprofitForAdminRequest|StagedNonprofit;
  isParent?: boolean;
  parentAdminFirstName?: string;
  parentAdminLastName?: string;
  parentAdminEmailAddress?: string;
  parentAdminPhoneNumber?: string;
  hasAdmins?: boolean;
  parentHasAdmins?: boolean;
  reviewedByNonprofitDate?: string;
  approvedDate?: string;
  compositeNonprofitName?: string;
  reviewComment?: string;
  rejectReasonId?: number;
  country?: string;
  regAuthTypeUS?: number;
  fiscal?: number;
  myOrg?: SearchResult;
  parentOrg?: SearchResult;
  contact?: ContactForm;
  registrationAuthorityId?: number;
  tempRegAuthorities?: string[];
  nonprofitAdmins?: User[];
  showNoAdminForm?: boolean;
  currentRoleError?: string;
  id?: number;
  adminForm?: AdminForm;
  pending?: boolean;
  isDraft?: boolean;
  assets?: NonprofitAsset[];
  existingAssets?: TableAsset[];
  static get default (): ClaimStateDataWithAssets {
    return {
      country: 'US',
      regAuthTypeUS: 0,
      fiscal: 0,
      parentOrg: null,
      myOrg: null,
      contact: {
        title: '',
        workEmail: '',
        phone: '',
        website: ''
      },
      registrationAuthorityId: 1,
      tempRegAuthorities: [
        'US Nonprofit',
        'Catholic Directory',
        'Private Schools'
      ],
      showNoAdminForm: false,
      currentRoleError: null,
      id: null,
      adminForm: null,
      pending: false,
      isDraft: true
    };
  }
}

export interface NewChapter {
  name: string;
  address: Address;
}

export interface StagedNonprofit {
  stagedNonprofitId?: number;
  name: string;
  address: Address;
  registrationId?: string;
  contactPhoneNumber?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  registrationAuthorityName?: string;
  mission?: string;
  tags?: string;
  displayNumber?: string;
  displayEmail?: string;
  displayUrl?: string;
  parentNonprofitId?: number;
}

export interface AddCharityModalResponse {
  existingNonprofit?: SearchResult;
  stagedNonprofit?: StagedNonprofit;
}

export interface CheckInfoPayload {
  paymentNumber: string;
  amount: number;
  dateCreatedStart: string;
  dateCreatedEnd: string;
}

export interface RegistrationAuthorityInfoModel {
  registrationAuthorityName: string;
  registrationAuthorityId?: number;
}

export enum FiscalType {
  Standalone = 0,
  Chapter = 1
}

export enum RegAuthorityTypeForUS {
  NonprofitPrivateOrReligious = 0,
  PublicSchoolOrDistrict = 1,
  NativeAmericanTribe = 2
}

export const REG_AUTH_TYPE_MAP_BY_ID = {
  0: 'Nonprofit, Private School, or Religious Organization',
  1: 'Public School or Public School District',
  2: 'Native American Tribe'
};

export enum ClaimStatePathId {
  ADMIN_EDIT_CLAIM = 'admin-edit-claim',
  VIEW_MY_CLAIM = 'view-my-claim',
  ADMIN_VIEW_CLAIM = 'admin-view-claim',
  NEW_ADMIN_CLAIM = 'new-claim',
  VIEW_REQUEST = 'view-request'
}

export const DISABLED_STATES = [
  ClaimStatePathId.ADMIN_EDIT_CLAIM,
  ClaimStatePathId.VIEW_MY_CLAIM,
  ClaimStatePathId.ADMIN_VIEW_CLAIM
];

