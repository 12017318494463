import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Address } from '@yourcause/common';
import { ModalFactory } from '@yourcause/common/modals';
import { NonprofitService } from '../../../nonprofit/nonprofit.service';
import { NppNonprofitBlockTableInfoModalComponent } from '../nonprofit-block-table-info-modal/nonprofit-block-table-info-modal.component';

@Component({
  selector: 'npp-nonprofit-block-table',
  templateUrl: './npp-nonprofit-block-table.component.html',
  styleUrls: ['./npp-nonprofit-block-table.component.scss']
})
export class NppNonprofitBlockTableComponent implements OnChanges {
  @Input() icon: string;
  // id is required if showAdditionalInfo = true OR
  // id/parentId is required when npoSelect/npoParentSelect = true
  @Input() id: number;
  @Input() name: string;
  @Input() parentId: number|string;
  @Input() parentName: string;
  @Input() parentRouterLink = '';
  @Input() npoSelect = false;
  @Input() address: Address;
  @Input() addressString = '';
  @Input() registrationId: string;
  @Input() chapterRegistrationId: string;
  @Input() npoParentSelect = false;
  @Input() isPrivateOrg: boolean;
  @Input() clientName: string;
  @Input() ycCharityId: string;
  @Input() npoRouterLink: string | string[];
  @Input() skipTitleize = false;
  @Input() showAdditionalInfo = false; // show Additional Information link
  @Input() eligibleForGivingStatusId: string;

  constructor (
    private modalFactory: ModalFactory,
    private nonprofitService: NonprofitService,
    private router: Router
  ) { }
  showAddress = true;

  ngOnChanges () {
    this.showAddress = this.address && (
      !!this.address.address1 ||
      !!this.address.city ||
      !!this.address.postalCode ||
      !!this.address.stateProvRegCode
    );
  }

  async openAdditionalInfoModal () {
    const info = await this.nonprofitService.getNonprofitAdditionalInformation(this.id);
    await this.modalFactory.open(
      NppNonprofitBlockTableInfoModalComponent,
      {
        nonprofitId: this.id,
        additionalInfo: info
      }
    );
  }

  async selectNonprofitAndNavigate (
    nonprofitId: string|number
  ) {
    await this.nonprofitService.setSelectedNpo(+nonprofitId);
    this.router.navigateByUrl('account-setup');
  }
}
