import { Pipe, PipeTransform } from '@angular/core';
import { set } from 'date-fns';
import { VolunteerEvent } from '../models/volunteer-event.model';
import { DATE_TIME_FORMAT, DateService } from '@yourcause/common/date';

@Pipe({
  name: 'nppVolunteerEventDate'
})
export class VolunteerEventDate implements PipeTransform {
  constructor (
    private dateService: DateService
  ) { }

  transform (event: VolunteerEvent, context: 'eventStart'|'eventEnd'): any {
    let returnDate;
    switch (context) {
      case 'eventStart':
        const startDate = set(new Date(event.originalStartDate), {
          hours: event.startHours,
          minutes: event.startMinutes
        });
        returnDate = this.dateService.formatDate(startDate, DATE_TIME_FORMAT);
        break;
      case 'eventEnd':
        const endDate = set(new Date(event.originalEndDate), {
          hours: event.endHours,
          minutes: event.endMinutes
        });
        returnDate = this.dateService.formatDate(endDate, DATE_TIME_FORMAT);
      break;
    }

    return returnDate;
  }
}
