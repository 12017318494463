import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { from, mergeMap, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {

  constructor (
    private tokenService: TokenService
  ) { }

  intercept (
    r: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from((async (request) => {
      // we are trying to make an internal request if we omit http/https
      // i.e. /some/endpoint vs http://api.something.com/some/endpoint
      if (this.checkShouldProcessRequest(request.url)) {
        request = this.buildUpFullAPIURL(request);
        request = await this.appendToken(request);
      }

      return request;
    })(r))
    // if we got a request out of the previous observable, then we are good to go
    // otherwise stop the request
    .pipe(mergeMap((req) => {
      return next.handle(req).pipe(map(res => {
        if ('body' in res && res.body && (res.body.success === false)) {
          throw new HttpErrorResponse({
            error: res.body,
            url: req.url
          });
        }

        return res;
      }));
    }));
  }

  private async appendToken (request: HttpRequest<any>) {
    if (this.checkShouldAppendToken(request.url)) {
      // capture whether or not we had a token before the attempted retrieval/refresh
      const latestToken = await this.tokenService.getLatestToken() as string;
      // if the refresh went through or we have a valid token
      if (latestToken) {
        let headers = request.headers.set('Authorization', `Bearer ${latestToken}`);
        const activeNpoId = this.tokenService.activeNpoId;

        if (activeNpoId) {
          headers = headers.set('nonprofitId', `${activeNpoId}`);
        }

        request = request.clone({
          headers
        });
      }
    }

    return request;
  }

  private checkShouldAppendToken (url: string) {
    return !url.startsWith(`${environment.apiBase}/api/admin/Sso`) && !url.startsWith(`${environment.apiBase}/api/token`);
  }

  private buildUpFullAPIURL (request: HttpRequest<any>) {
    if (!request.url.startsWith('/')) {
      request = request.clone({
        url: '/' + request.url
      });
    }

    request = request.clone({
      url: `${environment.apiBase}${request.url}`
    });

    return request;
  }

  private checkShouldProcessRequest (url: string) {
    return !(/^(http|\/(InsertMissing|assets|Version|Log))/).test(url) &&
      (url.startsWith('/api') ||
      url.startsWith('api'));
  }
}
