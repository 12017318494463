import { Pipe, PipeTransform } from '@angular/core';
import { ChangeTrackingEntityType } from '@core/models/change-tracking.model';
import { InflectService } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'nppEntityPropertyDescPipe'
})
export class EntityPropertyDescPipe implements PipeTransform {

  constructor (
    private i18n: I18nService,
    private inflect: InflectService
  ) { }

  transform (entityPropertyDesc: string): string {
    let desc;
    if (entityPropertyDesc === ChangeTrackingEntityType.DisbursementSuspended) {
      desc = this.i18n.translate(
        'changeTracking:textEligibleToDisburse',
        {},
        'Eligible to Disburse'
      );
    } else {
      desc = this.i18n.translate(
        `changeTracking:text${this.inflect.pascalize(entityPropertyDesc)}`,
        {},
        entityPropertyDesc
      );
    }

    return desc;
  }
}
