import { NonprofitBlockDisplayModel } from './npo.model';

export interface ChangeTracking {
  id: number;
  nonprofitName: string;
  changeType: string;
  entityId: number;
  entityPropertyDesc: string;
  changeDate: string;
  changedBy: string;
  changedByRole: string;
  oldValue: string;
  newValue: string;
  comment: string;
  assetId?: string;
  reasonId?: number;
  nonprofit?: NonprofitBlockDisplayModel;
  user?: ChangeTrackingUser;
}

export interface UserChangeTrackingViewModel {
  id: number;
  changeType: string;
  entityId: number;
  entityPropertyDesc: string;
  changeDate: string;
  changedBy: string;
  changedByRole: string;
  oldValue: string;
  newValue: string;
  comment: string;
  assetId?: string;
  reasonId?: number;
  user: ChangeTrackingUser;
}

export interface ChangeTrackingUser {
  firstName: string;
  lastName: string;
  email: string;
}


export interface ChangeTrackingEntityTypeObject {
  entityProperty: string;
  id: number;
}

export enum ChangeTrackingEntityType {
  AllowDonations = 'Allow donations', // deprecated
  AllowVolunteering = 'Allow volunteering', // deprecated
  Classification = 'Classification',
  DisplayName = 'Display name',
  IsActive = 'Is active',
  IsDeductible = 'Is deductible',
  MailingAddress = 'Mailing address',
  MailingName = 'Mailing name',
  ParentChildRel = 'Parent/Child Relationship',
  BankInfo = 'Banking Information',
  DisbursementSuspended = 'Disbursement Suspended',
  EligibleForGiving = 'Eligible For Giving',
  VettingApplicationStatus = 'Vetting Application Status',
  VettingOrganizationType = 'Vetting Organization Type',
  LegalEntityId = 'LegalEntityId',
  EmailCommunicationPreferences = 'Email Communication Preferences'
}

export enum ChangeTrackingEntityTypeId {
  CLASSIFICATION = 1,
  DISPLAY_NAME = 2,
  IS_DEDUCTIBLE = 4,
  MAILING_NAME = 5,
  MAILING_ADDRESS = 6,
  IS_ACTIVE = 8,
  PARENT_CHILD_RELATIONSHIP = 9,
  BANK_INFO = 10,
  DISBURSEMENT_SUSPENDED = 11,
  ELIGIBLE_FOR_GIVING = 13,
  VETTING_APPLICATION_STATUS = 14,
  VETTING_ORGANIZATION_TYPE = 15,
  LEGAL_ENTITY_ID = 16,
  EMAIL_COMM_PREFERENCES = 17
}

export enum ChangeTrackingType {
  NonprofitChangeTracking = 'Nonprofit Change Tracking',
  UserChangeTracking = 'User Change Tracking'
}

export enum ChangeTrackingTypeId {
  NONPROFIT_CHANGE_TRACKING = 1,
  USER_CHANGE_TRACKING = 2
}


export enum ChangeTrackingChangeByRole {
  YCUser = 'YourCause User',
  NPOUser = 'Nonprofit User',
  System = 'System'
}

export enum ChangeTrackingChangedBySystemName {
  NPPAutomation = 'NPP Automation',
  SiteAdmin = 'Site Admin'
}
