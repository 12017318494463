<yc-modal
  [modalHeader]="'login:hdrUpdateToTerms' | ycI18n: 'Update to Terms'"
  [primaryButtonText]="'common:btnAcknowledgeTerms' | ycI18n: 'Acknowledge Terms'"
  [cancelButtonText]="'common:hdrSignOut' | ycI18n: 'Sign Out'"
  (onCancel)="signOut()"
  (onPrimaryClick)="acknowledgeTermsOfService()">
    <p>
      <yc-i18n
        key="login:textTermsOfServiceChanges"
        defaultValue="We recently updated Terms of Service and/or our Privacy Policy.  Your continued use of NPOconnect is subject to these newly revised terms.  Please review and acknowledge these new terms.">
      </yc-i18n>
    </p>
    <div class="d-flex mt-4">
      <div class="col">
        <yc-button
          [primary]="true"
          (onClick)="openTermsOfService()">
          <yc-fa icon="external-link" class="me-2"></yc-fa>
          <yc-i18n
            key="login:textTermsOfService"
            defaultValue="YourCause Terms of Service">
          </yc-i18n>
        </yc-button>
      </div>
      <div class="col">
        <yc-button
          [primary]="true"
          (onClick)="openPrivacyPolicy()">
          <yc-fa icon="external-link" class="me-2"></yc-fa>
          <yc-i18n
            key="login:textPrivacyPolicy"
            defaultValue="YourCause Privacy Policy">
          </yc-i18n>
        </yc-button>
      </div>
    </div>
  </yc-modal>