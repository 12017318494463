import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfirmNpoAdminModalComponent } from '@core/components/confirm-npo-admin-modal/confirm-npo-admin-modal.component';
import { RemoveUserRolesPayload } from '@core/models/account.model';
import { DateService } from '@yourcause/common/date';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { NonprofitService } from 'ClientApp/app/nonprofit/nonprofit.service';
import { BasicNonprofit, InviteAdminEmailPayload } from '../models/npo.model';
import { AccountService } from './account.service';
import { SpinnerService } from './spinner.service';
import { TokenService } from './token/token.service';


@Injectable({ providedIn: 'root' })
export class NonprofitAdminService {

  constructor (
    public http: HttpClient,
    public notifier: NotifierService,
    public i18n: I18nService,
    private accountService: AccountService,
    private nonprofitService: NonprofitService,
    private dateService: DateService,
    private modalFactory: ModalFactory,
    private spinnerService: SpinnerService,
    private tokenService: TokenService
  ) { }

  sendNonprofitAdminInvite (payload: InviteAdminEmailPayload) {
    return this.http
      .post('api/Admin/InviteEmailToBecomeNonprofitAdmin', payload);
  }

  async confirmNpoAdmin () {
    let myNpos: BasicNonprofit[];
    let modalDeps: Partial<ConfirmNpoAdminModalComponent> = {};
    let payload: RemoveUserRolesPayload = {
      nonprofitIds: []
    };

    const userId = this.tokenService.tokenInfo.userId;

    if (!this.accountService.accessRemovedModalClosed
    ) {
      const response = await this.accountService.getUserDeactivationFlags(+userId);
      const deactivationWfFlagDates = response.data;
      if (
        deactivationWfFlagDates?.flaggedForDeactivationOn ||
        deactivationWfFlagDates?.deactivatedViaWorkflowOn
      ) {
        if (deactivationWfFlagDates?.flaggedForDeactivationOn) {
          myNpos = await this.nonprofitService.getStrictNonprofitsForAdmin();

          if (myNpos.length > 1) {
            modalDeps = {
              myNpos,
              userInfo: this.tokenService.tokenInfo,
              userAccessRemovedDate: ''
            };
          }
        } else if (deactivationWfFlagDates?.deactivatedViaWorkflowOn) {
          modalDeps = {
            myNpos: [],
            userInfo: this.tokenService.tokenInfo,
            userAccessRemovedDate: this.dateService.formatDate(deactivationWfFlagDates.deactivatedViaWorkflowOn)
          };
        }

        if (Object.keys(modalDeps).length) {
          const modalResponse = await this.modalFactory.open(
            ConfirmNpoAdminModalComponent,
            modalDeps,
            {
              class: 'modal-lg'
            }
          );

          if (modalResponse) {
            this.spinnerService.startSpinner();
            payload = {
              nonprofitIds: modalResponse.nonprofitIds
            };
            await this.accountService.removeUserRoles(payload);
            await this.tokenService.doRefresh(true);
            this.spinnerService.stopSpinner();
            location.pathname = '/my-nonprofits';
          } else {
            await this.accountService.removeUserRoles(payload);
            location.pathname = '/my-nonprofits';
          }

          return !!modalResponse;
        } else {
          if (deactivationWfFlagDates?.flaggedForDeactivationOn) {
            await this.accountService.removeUserRoles(payload);
          }

          return true;
        }
      } else {
        return true;
      }
    }

    return true;
  }

}
