export enum ManageEventSteps {
  EventDetails,
  Schedule,
  Contacts,
  ShiftsAndCapacity,
  Documents,
  Photos,
  RequiredQuestions
}

export interface EventContact {
  reasonToContact: string;
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  modifiedBy?: string;
}

export interface ContactResponse {
  contacts: EventContact[];
  totalCount: number;
  pageCount: number;
  page: number;
  pageSize: number;
}

export interface ContactModalResponse {
  contactPayload: ContactPayload;
}

export interface ContactPayload {
  eventId: number;
  reasonToContact: string;
  modifiedBy?: string;
  id?: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export interface CreateEventPhotoPayload {
  eventId: number;
  name: string;
  description?: string;
  url: string;
}

export interface DeleteEventPhotoPayload {
  eventId: number;
  photoName: string;
}

export interface CreateEventDocumentPayload {
  eventId: number;
  name: string;
  url: string;
}

export interface DeleteEventDocumentPayload {
  eventId: number;
  documentName: string;
}

export interface EventLocation {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  latitude: string;
  longitude: string;
}

export interface VolunteerShift {
  shiftId: number;
  eventId: number;
  ycCharityId: number;
  name: string;
  description: string;
  startHours: number;
  startMinutes: number;
  endHours: number;
  endMinutes: number;
  capacity: number;
  numberOfDays: number;
  startDay: number;
  hoursLocked: boolean;
  defaultHours: number;
  hasDefaultHours: boolean;
  numberOfParticipants?: number;
  modifiedBy: string;
  active?: boolean;
}

export enum VolunteerEventStatus {
  Published,
  Draft
}

export interface VolunteerTimeZone {
  displayName: string;
  id: string;
}

export interface VolunteerEventPhoto {
  url: string;
  name: string;
  description: string;
}

export interface VolunteerEventDocument {
  url: string;
  name: string;
}

export interface DocumentModalResponse {
  name: string;
  file: Blob;
}

export interface EventPhotoModalResponse {
  description: string;
  name: string;
  file: Blob;
}

export interface ParticipantParticipations {
  participationDate: string;
  participationId: number;
  hours: number;
  shift: VolunteerShift;
}

export interface Participant {
  affiliateId?: string;
  affiliateName?: string;
  email: string;
  firstName: any;
  lastName: any;
  affiliateEmployeeId?: number;
  participations: ParticipantParticipations[];
}

export interface ParticipantResponse {
  participants: Participant[];
  totalCount: number;
  pageCount: number;
  page: number;
  pageSize: number;
}

export interface VolunteerEventsResponse {
  events: VolunteerEvent[];
  totalCount: number;
  pageCount: number;
  page: number;
  pageSize: number;
}

export interface VolunteerEvent {
  participants?: number;
  status?: string;
  eventId: number;
  name: string;
  location: EventLocation;
  description: string;
  meetupInstructions: string;
  imageUrl: string;
  image: Blob;
  startDate: string;
  endDate: string;
  originalStartDate: string;
  originalEndDate: string;
  originalTimeZone: string;
  originalTimezoneOffset: number;
  ycCharityId: number;
  totalShifts: number;
  contacts: EventContact[];
  registrationStartDate: string;
  registrationEndDate: string;
  isDraft: boolean;
  isVirtual: boolean;
  totalParticipants: number;
  private: boolean;
  allowLogHoursAfterEndEvent: boolean;
  allowFamilyAndFriendsToAttend: boolean;
  totalWaitlistCount: number;
  totalUniqueParticipants: number;
  shifts: VolunteerShift[];
  affiliateId: number;
  affiliateName: string;
  startHours: number;
  endHours: number;
  startMinutes: number;
  endMinutes: number;
  waitlistEnabled: boolean;
  requiresApproval: boolean;
  approvalStatus: number;
  statusMessage: string;
  customQuestionSetResponseId: number;
  customQuestionUrl: string;
  skills: number[];
}

export interface VolunteerShiftsResponse {
  shifts: VolunteerShift[];
  totalCount: number;
  pageCount: number;
  page: number;
  pageSize: number;
}

export interface ShiftModalResponse {
  formValue: any;
  context: string;
  shiftId?: number;
  eventId: number;
}

export interface CreateOrUpdateEventShiftPayload {
  shiftId?: number;
  eventId: number;
  name: string;
  description: string;
  capacity: number;
  hoursLocked: boolean;
  defaultHours: number;
  startDay: number;
  startHours: number;
  startMinutes: number;
  endHours: number;
  endMinutes: number;
  numberOfDays: number;
}

export interface ParticipantExportModel {
  participants: Participant[];
}

export interface VolunteerCommunityPartnerResponse {
  data: VolunteerCommunityPartner[];
  page: number;
  pageCount: number;
  pageSize: number;
  totalCount: number;
}

export interface VolunteerCommunityPartner {
  affiliateId: number;
  name: string;
  approvalRequired: boolean;
  customQuestionSetId: number;
}

export interface EmailParticipantModalResponse {
  eventId: number;
  shiftId?: number;
  subject: string;
  body: string;
  emailParticipants: boolean;
  emailWaitlist: boolean;
  replyToName?: string;
  replyToAddress?: string;
  affiliateEmployeeIdListToEmail?: number[];
}

export interface EventCategorySkillType {
  skillId: number;
  skillName: string;
  active: boolean;
  categoryId: number;
}

export interface EventSkillType {
  categoryId: number;
  categoryName: string;
  active: boolean;
  categorySkills: EventCategorySkillType[];
}

export interface EventSkillsResponse {
  skillsEnabled: boolean;
  skills: EventSkillType[];
}

export enum CsrApprovalStatus {
  Live = 1,
  Declined = 2,
  Pending = 3,
  RequestChange = 4
}

export enum EventActionScenario {
  MANAGE,
  VIEW,
  COPY_REMOVE,
  PUBLISH,
  SUBMIT,
  SHOW_MENU
}

export enum EventWrapperScenario {
  MANAGE = 'manage',
  VIEW = 'view',
  CREATE = 'create',
  COPY = 'copy'
}

export enum VolunteerType {
  ALL_VOLUNTEERS,
  ALL_PARTICIPANTS,
  ALL_WAITLIST
}

export enum EventSaveScenario {
  SAVE,
  PUBLISH
}


