import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nppMergeComparision'
})
export class MergeComparison implements PipeTransform {
  constructor () {}


  transform (value1: any, value2: any, fieldName: string): any {
    if (value1 && value2) {
      if (value1[fieldName] && value2[fieldName]) {
        return 'npo-stat-info text-warning';
      } else if (!value1[fieldName]) {
        return 'npo-stat-info text-secondary';
      } else if (!value2[fieldName]) {
        return 'npo-stat-info';
      }
    } else if (value1 && !value2) {
      if (!value1[fieldName]) {
        return 'npo-stat-info text-secondary';
      } else {
        return 'npo-stat-info';
      }
    } else {
      return 'npo-stat-info';
    }
  }
}
