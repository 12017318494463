import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BBAuth, BBOmnibar, BBOmnibarConfig } from '@blackbaud/auth-client';
import { DetermineSignInFlow } from '@core/typings/bbid.typing';
import { LogService } from '@yourcause/common/logging';
const BbidProfileUrl = 'https://app.blackbaud.com/user';

@Injectable({ providedIn: 'root' })
export class BBIDService {

  constructor (
    private logger: LogService,
    private router: Router
  ) { }

  /**
   * Get the most up to date token from BBAuth
   *
   * @returns the BBAuth Token
   */
  async getToken () {
    try {
      const token = await BBAuth.getToken();

      return token;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  /**
   * Loads the Omnibar with the given service name
   */
  async loadOmnibar () {
    const payload: BBOmnibarConfig = {
      serviceName: 'NPOconnect'
    };

    await BBOmnibar.load(payload);
  }

  /**
   * Handle redirecting to BBID for authentication
   *
   * @param signInFlow: Sign in Flow Details for the User
   * @param email: Email
   * @param npoAccountToCreateIsAdmin: NPO account to create (if there is one) is an Admin user
   */
  redirectToBbid (
    signInFlow: DetermineSignInFlow,
    email: string,
    npoAccountToCreateIsAdmin: boolean
  ) {
    if (signInFlow) {
      const returnLoginUrl = this.getReturnLoginUrl(
        signInFlow.isBlackbaudIdLinked,
        email,
        !signInFlow.existing,
        location.href,
        npoAccountToCreateIsAdmin
      );

      const redirectRoute = this.getRedirectRoute(signInFlow.hasExistingBlackbaudIdAccount, email, returnLoginUrl);

      this.doRedirect(redirectRoute);
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  /**
   * Get Redirect Route to Authenticate with BBID
   *
   * @param hasExistingBbidAccount: Does the user have an existing account in BBID?
   * @param email: Email
   * @param returnLoginUrl: Return Login URL
   * @returns the redirect route to authenticate a user in BBID
   */
  getRedirectRoute (
    hasExistingBbidAccount: boolean,
    email: string,
    returnLoginUrl: string
  ) {
    const encodedReturnUrl = encodeURIComponent(returnLoginUrl);
    let encodedEmailQueryParam = '';
    if (!!email) {
      encodedEmailQueryParam = `&login_hint=${encodeURIComponent(email)}`;
    }
    if (!hasExistingBbidAccount) {
      return `https://app.blackbaud.com/signin/sign-up?&redirectUrl=${encodedReturnUrl}${encodedEmailQueryParam}`;
    } else {
      return `https://app.blackbaud.com/signin?&redirectUrl=${encodedReturnUrl}${encodedEmailQueryParam}`;
    }
  }

  /**
   * Get the return login URL
   *
   * @param isBbidLinked: Is BBID already linked?
   * @param email: Email
   * @param npoAccountNotCreatedYet: Is NPO account not created yet?
   * @returns the return login URL
   */
  getReturnLoginUrl (
    isBbidLinked: boolean,
    email: string,
    npoAccountNotCreatedYet: boolean,
    currentRoute: string,
    npoAccountToCreateIsAdmin: boolean
  ) {
    const route = '/login/' + (npoAccountNotCreatedYet ? 'bbid-new-user' : 'bbid-signin');

    let queryParams = '';

    if (!isBbidLinked) {
      const baseParams = `needToLinkBbid=true&isBbid=true&inSystem=${!npoAccountNotCreatedYet}`;

      queryParams = `${baseParams}`;

      if (!!email) {
        queryParams = `${queryParams}&email=${encodeURIComponent(email)}`;
      }

      if (npoAccountNotCreatedYet) {
        queryParams = `${queryParams}&isAdmin=${npoAccountToCreateIsAdmin}`;
      }

      queryParams = `${queryParams}&initialRoute=${encodeURIComponent(currentRoute)}`;
    }

    const returnLoginUrl = this.getRelativeUrlForReturnLogin(route, queryParams);

    return returnLoginUrl;
  }

  /**
   * Logout of BBID
   */
  logoutOfBbid (returnLoginUrl?: string) {
    if (!returnLoginUrl) {
      const route = '/login';
      returnLoginUrl = this.getRelativeUrlForReturnLogin(route);
    }
    const encodedReturnUrl = encodeURIComponent(returnLoginUrl);
    const redirectRoute = `https://app.blackbaud.com/signin/sign-out?redirectUrl=${encodedReturnUrl}`;
    this.doRedirect(redirectRoute);
  }

  /**
   * Do redirect with location.href
   *
   * @param redirectRoute: Redirect to route
   */
  doRedirect (redirectRoute: string) {
    location.href = redirectRoute;
  }

  /**
   * Open the user's BBID profile in a new tab
   */
  openBbidProfile () {
    window.open(BbidProfileUrl, '_blank');
  }

  getRelativeUrlForReturnLogin (
    path: string,
    search?: string,
    hash?: string
  ) {
    const port = !location.port ||
      (location.port === '80' ||
      location.port === '443') ?
      '' :
      ':' + location.port;
    const searchString = search ? '?' + search : '';
    const hashString = hash ? '#' + hash : '';

    return `${location.protocol}//${location.hostname}${port}${path}${searchString}${hashString}`;
  }
}

