import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'charityCompareStatusPipe'
})
export class CharityCompareStatusPipe implements PipeTransform {
  constructor (
    private i18n: I18nService
  ) { }

  transform (statusId: number): string {
    switch (statusId) {
      case 1:
        return this.i18n.translate('statusPipe:Staging', {}, 'Staging');
      case 2:
        return this.i18n.translate('statusPipe:Queued', {}, 'Queued');
      case 3:
      case 4:
      default:
        return this.i18n.translate('statusPipe:Pending', {}, 'Pending');
      case 5:
        return this.i18n.translate('statusPipe:Completed', {}, 'Completed');
      case 6:
        return this.i18n.translate('statusPipe:Errored', {}, 'Errored');
    }
  }
}
