import { Injectable } from '@angular/core';
import { NonprofitSearchState } from '@core/components/nonprofit-search/nonprofit-search.state';
import { NonprofitSearchFacet, NonprofitSearchResponseModelUI, StandardGetSearchPayload } from '@core/models/npo.model';
import { ALL_SKIP_FILTER, PaginationOptions, SearchByType, SearchFilter, SearchResult } from '@yourcause/common';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { isEqual } from 'lodash';
import { NonprofitSearchResources } from './nonprofit-search.resources';

@AttachYCState(NonprofitSearchState)
@Injectable({ providedIn: 'root' })
export class NonprofitSearchService extends BaseYCService<NonprofitSearchState> {

  readonly showAll = true;
  readonly pageNumber = 1;
  readonly rowsPerPage = 250;
  readonly defaultPaginationOptions: PaginationOptions<any> = {
    retrieveTotalRecordCount: false,
    filterColumns: [],
    sortColumns: [],
    returnAll: true,
    pageNumber: 1,
    rowsPerPage: 10
  };

  constructor (
    private nonprofitSearchResources: NonprofitSearchResources
  ) {
    super();
  }

  get allFacets () {
    return this.get('allFacets');
  }

  get activeFacets () {
    return this.get('activeFacets');
  }

  get paymentNumber () {
    return this.get('paymentNumber');
  }

  get searchType () {
    return this.get('searchType');
  }

  get searchTerm () {
    return this.get('searchTerm');
  }

  get searchFilters () {
    return this.get('searchFilters');
  }

  get searchResults () {
    return this.get('searchResults');
  }

  setAllFacets (facets: NonprofitSearchFacet) {
    this.set('allFacets', facets);
    if (!!facets) {
      this.setActiveFacets(facets);
    }
  }

  setActiveFacets (facets: NonprofitSearchFacet) {
    this.set('activeFacets', facets);
  }

  setPaymentNumber (paymentNumber: string) {
    this.set('paymentNumber', paymentNumber);
  }

  setSearchType (type: SearchByType) {
    this.set('searchType', type);
  }

  setSearchTerm (term: string, type: SearchByType) {
    this.set('searchTerm', term?.trim());
  }

  adaptPayloadForAPI (
    searchText: string,
    searchFilters: SearchFilter[],
    showAllResults: boolean
  ): StandardGetSearchPayload {
    const payload: StandardGetSearchPayload = {
      searchText,
      pageNumber: 1,
      rowsPerPage: 10,
      searchFilters,
      showAll: showAllResults
    };

    return payload;
  }

  adaptFiltersForRegIdOnly (
    searchText: string,
    searchFilters: SearchFilter[]
  ) {
    const trimSearchText = searchText.replace(/[^0-9a-zA-Z]/g, '');
    const filters = [
      ...searchFilters,
      {
        filterName: 'RegistrationId',
        filterValue: trimSearchText
      },
      { // SHOW INACTIVE
        filterName: 'ShowAll',
        filterValue: true
      }
    ];

    return filters;
  }

  async setSearchFilters (
    searchFiltersShowAll: boolean,
    searchFiltersShowChapters: boolean,
    searchFiltersRegAuth?: string,
    searchFiltersCountry?: string,
    searchFiltersState?: string
  ) {
    const allSearchFilters: SearchFilter[] = [
      {
        filterName: 'ShowAll',
        filterValue: searchFiltersShowAll
      },
      {
        filterName: 'IsChapterAffiliate',
        filterValue: searchFiltersShowChapters
      }
    ];
    if (searchFiltersRegAuth && searchFiltersRegAuth !== ALL_SKIP_FILTER) {
      allSearchFilters.push({
        filterName: 'RegistrationAuthority',
        filterValue: searchFiltersRegAuth
      });
    }
    if (searchFiltersCountry && searchFiltersCountry !== ALL_SKIP_FILTER) {
      allSearchFilters.push({
        filterName: 'Country',
        filterValue: searchFiltersCountry
      });
    }
    if (searchFiltersState && searchFiltersState !== ALL_SKIP_FILTER) {
      allSearchFilters.push({
        filterName: 'StateProvRegName',
        filterValue: searchFiltersState
      });
    }
    this.set('searchFilters', allSearchFilters);
  }

  setSearchResults (results: SearchResult[]) {
    this.set('searchResults', results);
  }

  async searchByTerm () {
    this.setPaymentNumber(null);
    let results: NonprofitSearchResponseModelUI;

    const response = await this.nonprofitSearchResources.searchByTerm(
      this.searchTerm,
      this.pageNumber,
      this.rowsPerPage,
      this.showAll,
      this.searchFilters
    );

    if (response) {
      const activeFacets = this.activeFacets;
      const newFacets = response.facets;
      if (!isEqual(activeFacets, newFacets)) {
        this.setAllFacets(response.facets);
      }
      results = {
        data: {
          results: response.results.map((result) => {
            return {
              document: result.document
            };
          }),
          facets: response.facets
        }
      };
    }
    const searchResults = results?.data?.results.map((result) => {
      return SearchResult.construct<SearchResult>(result);
    });
    this.setSearchResults(searchResults);
  }

  async searchByAdminEmail () {
    this.setAllFacets(null);
    this.setPaymentNumber(null);
    let results: NonprofitSearchResponseModelUI;

    const response = await this.nonprofitSearchResources.searchByAdminEmail(
      this.searchTerm,
      this.showAll,
      this.defaultPaginationOptions
    );

    if (response) {
      results = {
        data: {
          results: response.records.map((result) => {
            return {
              document: result
            };
          }),
          facets: null
        }
      };
    }
    const searchResults = results?.data?.results.map((result) => {
      return SearchResult.construct<SearchResult>(result);
    });
    this.setSearchResults(searchResults);
  }

  async searchByPaymentNumber () {
    this.setAllFacets(null);
    this.setPaymentNumber(this.searchTerm);
    let results: NonprofitSearchResponseModelUI;

    const response = await this.nonprofitSearchResources.searchByPaymentNumber(
      this.paymentNumber,
      this.showAll,
      this.defaultPaginationOptions
    );

    if (response) {
      results = {
        data: {
          results: response.records.map((result) => {
            return {
              document: result
            };
          }),
          facets: null
        }
      };
    }
    const searchResults = results?.data?.results.map((result) => {
      return SearchResult.construct<SearchResult>(result);
    });
    this.setSearchResults(searchResults);
  }

  getSearchForAutoComplete (
    searchText: string,
    searchFilters: SearchFilter[] = []
  ) {
    const payload = this.adaptPayloadForAPI(
      searchText,
      searchFilters,
      false
    );

    return this.nonprofitSearchResources.getSearchForAutoComplete(
      payload
    );
  }

  searchForMergeOrgs (
    searchText: string,
    searchFilters: SearchFilter[] = []
  ) {
    const payload = this.adaptPayloadForAPI(
      searchText,
      searchFilters,
      true
    );

    const response = this.nonprofitSearchResources.searchForMergeOrgs(
      payload
    );

    return response;
  }

  searchByRegIdOnly (
    searchText: string,
    searchFilters: SearchFilter[] = []
  ) {
    const filters = this.adaptFiltersForRegIdOnly(
      searchText,
      searchFilters
    );
    const payload = this.adaptPayloadForAPI(
      searchText,
      filters,
      true
    );

    const response = this.nonprofitSearchResources.searchByRegIdOnly(
      payload
    );

    return response;
  }
}

