<ul class="{{listClass}}">
  <li *ngFor="let item of listItemArray; let i = index; let last = last;">
    <div class="item {{item.indicatorClass}}"
      [class.last]="last"
      (click)="navTo(item)"
      [class.item-margin]="!item.dismissable">
      <div class="symbol">
        <img *ngIf="type === ListTypes.Links"
          src="{{ item.symbol || item.defaultSymbol }}"
          [alt]="'common:textDefaultIcon' | ycI18n : 'Default Icon'" />
        <yc-fa *ngIf="type === ListTypes.Alerts"
          icon="circle">
        </yc-fa>
        <yc-fa *ngIf="type === ListTypes.Notifications"
          [icon]="item.symbol">
        </yc-fa>
      </div>
      <div class="content">
        <div class="title" [innerHtml]="item.title"></div>
        <div class="sub-title" [innerHtml]="item.subtitle"></div>
      </div>
      <div *ngIf="type === ListTypes.Links" class="actions">
        <yc-fa icon="angle-right"></yc-fa>
      </div>
      <div *ngIf="type === ListTypes.Notifications"
        class="bullet">
        <yc-fa
          class="indicator text-{{item.indicatorClass}}"
          [icon]="bulletIcon(item.indicatorClass)">
        </yc-fa>
        <yc-fa class="navTo text-primary"
          icon="arrow-circle-right">
        </yc-fa>
      </div>
    </div>
    <div class="footer" *ngIf="item.dismissable">
      <yc-button
        [link]="true"
        size="sm"
        (onClick)="_onDismiss(item.id)">
        <yc-i18n
          key="common:btnDismiss"
          defaultValue="Dismiss">
        </yc-i18n>
      </yc-button>
    </div>
    <hr *ngIf="last">
  </li>
</ul>
