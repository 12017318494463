<yc-modal *ngIf="modalLoaded"
  [ycHidden]="hideModal"
  [cancelButtonText]="'common:btnClose' | ycI18n: 'Close'"
  [modalHeader]="title"
  (onCancel)="_onCancel()">
  <div class="yc-layout-table">
    <yc-table
      [rows]="activeUsers"
      [simple]="true"
      [isModalTable]="true">
      <ng-container *ycTableColumn="'common:textName';
          let admin;
          prop: 'userInfo.fullName',
          filterType: 'text',
          defaultSortDirection: 'asc'">
        <div class="yc-table-key-field">
          {{ admin.userInfo.fullName }}
        </div>
        <span *ngIf="admin.userInfo.email">
          <a href="javascript:void(0)"
            class="text-info col-text"
            (click)="openMailToModal(admin)">
            {{ admin.userInfo.email }}
          </a>
        </span>
        <span *ngIf="admin.userInfo.email">
          <a class="text-info"
            href="javascript:void(0)"
            (click)="openNonprofitEmailLogReport(admin.userInfo.email)">
            {{ emailHistoryText }}
          </a>
        </span>
      </ng-container>
      <ng-container *ycTableColumn="'common:hdrJobTitle';
          let admin;
          prop: 'jobTitle',
          filterType: 'text'">
          <span class="col-text">
            {{ admin.jobTitle }}
          </span>
      </ng-container>
      <ng-container *ycTableColumn="'contact:hdrRole';
        let admin;
        prop: 'roleInfo.roleName',
        filterType: 'text'">
        <span class="col-text">
          {{ admin.roleInfo.roleName }}
        </span>
      </ng-container>
    </yc-table>
  </div>
</yc-modal>