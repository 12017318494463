import { Component, Input, OnInit } from '@angular/core';
import { NonprofitRegAuthAttributes } from '@core/models/npo.model';
import { SpinnerService } from '@core/services/spinner.service';
import { DebounceFactory, PaginationOptions, TableDataFactory } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
import { NonprofitService } from '../../../nonprofit/nonprofit.service';

@Component({
  selector: 'yc-registration-authority-attr-modal',
  styleUrls: ['./registration-authority-attr-modal.component.scss'],
  templateUrl: './registration-authority-attr-modal.component.html'
})

export class RegistrationAuthorityAttrModalComponent extends YCModalComponent<void> implements OnInit {
  @Input() nonprofitId: number;
  @Input() nonprofitName: string;
  ready = false;
  modalSubheader: string;
  tableDataFactory: TableDataFactory<NonprofitRegAuthAttributes>;
  modalHeader = this.i18n.translate(
    'nonprofits:textRegistrationAuthorityAttributes',
    {},
    'Registration Authority Attributes'
  );

  constructor (
    private i18n: I18nService,
    private nonprofitService: NonprofitService,
    private spinnerService: SpinnerService
  ) {
    super();
  }

  ngOnInit () {
    this.modalSubheader = this.nonprofitName;
    this.setTableDataFactory();
    this.ready = true;
  }

  setTableDataFactory () {
    this.tableDataFactory = DebounceFactory.createSimple(
      async (paginationOptions: PaginationOptions<NonprofitRegAuthAttributes>) => {
        this.spinnerService.startSpinner();
        const response = await this.nonprofitService.getIrsAttribution(
          paginationOptions,
          this.nonprofitId
        );
        this.spinnerService.stopSpinner();

        return response;
    });
  }

  onCancel () {
    this.closeModal.emit();
  }
}
