import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NonprofitService } from '../../nonprofit/nonprofit.service';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class RouteToSearchGuard  {

  constructor (
    private router: Router,
    private authService: AuthService,
    private nonprofitService: NonprofitService
  ) {}

  checkForActiveNonprofit () {
    return this.nonprofitService.activeNpo;
  }

  canActivate (nextRoute: ActivatedRouteSnapshot, router: RouterStateSnapshot) {
    if (this.checkForActiveNonprofit()) {
      return true;
    } else if (this.authService.isYcAdminOrSupport()) {
      this.router.navigateByUrl('/search');

      return true;
    } else {
      this.router.navigateByUrl('/my-workspace');

      return true;
    }
  }
}
