import { inject } from '@angular/core';
import { TokenService } from '@core/services/token/token.service';
import { NonprofitService } from '../../nonprofit/nonprofit.service';
import { AuthService } from '../services/auth.service';
import { ResolveFn } from '@angular/router';
import { NonprofitAPI } from '@core/models/npo.model';

export const activeNpoResolver: ResolveFn<NonprofitAPI> = async () => {
  const tokenService = inject(TokenService);
  const authService = inject(AuthService);
  const nonprofitService = inject(NonprofitService);

  const loggedIn = tokenService.getIsLoggedIn();
  const tokenInfo = tokenService.tokenInfo;

  if (loggedIn &&
    !!tokenInfo?.NonprofitIdArr &&
    !authService.isYcAdminOrSupport()
  ) {
    const jwt = tokenService.tokenInfo;
    if (
      !nonprofitService.activeNpo
    ) {
      let selectedNpo = nonprofitService.selectedNpo;

      if (!jwt.NonprofitIdArr.includes(selectedNpo)) {
        selectedNpo = +jwt.NonprofitIdArr[0];
      }
      nonprofitService.setSelectedNpo(
        selectedNpo,
        true
      );
      tokenService.setActiveNpoId(selectedNpo);
      if (!selectedNpo) {
        return nonprofitService.placeholder;
      }

      return nonprofitService.placeholder;
    }

    return nonprofitService.activeNpo;
  }

  return nonprofitService.placeholder;
}

