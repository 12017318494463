import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Country, StateProvReg } from '@core/models/location.model';
import { Addresses } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { Subscription } from 'rxjs';
import { LocationService } from '../../../location/location.service';

@Component({
  selector: 'yc-address-block',
  templateUrl: './address-block.component.html',
  styleUrls: ['./address-block.component.scss']
})
export class AddressBlockComponent implements OnInit, OnDestroy {
    @Input() addressFormGroup: UntypedFormGroup;
    @Input() showName = true;
    @Input() nameInfoText: string;
    @Input() nameLabel = this.i18n.translate(
      'common:textOrganizationName',
      {},
      'Organization name'
    );
    loading = true;
    countries: Country[] = this.locationService.countries;
    regions: StateProvReg[] = this.locationService.regions;
    countrySelects: TypeaheadSelectOption[] = this.locationService.countriesForSelect;
    regionSelects: TypeaheadSelectOption[];
    addresses: Addresses;

    subscription = new Subscription();

  constructor (
    private locationService: LocationService,
    private i18n: I18nService
  ) {
    this.subscription.add(
      this.locationService.changesTo$('regionsForSelect')
        .subscribe((regions: TypeaheadSelectOption[]) => {
          this.regionSelects = regions;
        })
    );
  }

  async ngOnInit () {
    this.showName = this.showName && !!this.addressFormGroup.controls.name;

    if (this.countries.length === 0) {
      await this.locationService.setCountries();
    }
    if (!!this.addressFormGroup?.value?.countryCode) {
      this.addressFormGroup.controls.countryCode.setValue(
        this.addressFormGroup.value.countryCode
      );
      this.locationService.setRegions(this.addressFormGroup.value.countryCode);
    }
    if (this.addressFormGroup?.value?.stateProvRegId) {
      this.setRegionCode(this.addressFormGroup.value.stateProvRegId);
    }
    this.loading = false;
  }


  setRegionCode (id: number) {
    if (this.regions) {
      const region = this.regions.find((reg) => +reg.id === +id);
      if (region) {
        this.addressFormGroup.controls.stateProvRegCode?.setValue(region.stateProvRegCode);
      }
    }
  }

  async setRegionsOnCountryChange (countryCode: string) {
    this.locationService.setRegions(countryCode);
  }

  ngOnDestroy () {
    this.subscription.unsubscribe();
  }
}
