import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { NonprofitService } from '../../nonprofit/nonprofit.service';
import { NotificationsService } from '../services/notifications.service';

export const notificationsResolver: ResolveFn<void> = async () => {
  const notificationsService = inject(NotificationsService);
  const nonprofitService = inject(NonprofitService);
  const activeNonprofitId = nonprofitService.activeNpo?.id;

  await notificationsService.setActiveNonprofitId(
    activeNonprofitId
  );
  await notificationsService.getNotifications();
}
