import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { FeatureFlagModel, UpdateFeatureFLagPayload } from '@core/typings/feature-flag.typing';
import { APIResponse } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class FeatureFlagResources {
  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getFeatureFlags () {
    const endpoint = 'api/FeatureFlag/GetFeatureFlags';

    return this.httpRestService.get<APIResponse<FeatureFlagModel[]>>(endpoint);
  }

  updateFeatureFlagStatus (payload: UpdateFeatureFLagPayload) {
    const endpoint = 'api/FeatureFlag/UpdateFeatureFlagStatus';

    return this.httpRestService.post(
      endpoint,
      payload
    );
  }
}

