<ng-container *ngIf="ready">
  <yc-modal
    [simple]="true"
    [modalHeader]="modalHeader"
    [modalSubHeader]="modalSubheader"
    [cancelButtonText]="'common:btnClose' | ycI18n:'Close'"
    (onCancel)="onCancel()">
    <yc-table
      key="REVOCATION_DETAILS_BY_STATE"
      [hideTableFilters]="true"
      [hideTableOptions]="true"
      [skipAddToState]="true"
      [tableDataFactory]="tableDataFactory">
      <!-- Revocation List -->
      <ng-container *ycTableColumn="'common:textRevocationList';
        let row;
        prop: 'listType',
        defaultSortDirection: 'asc'">
        {{ row.listType }}
      </ng-container>
      <!-- STATUS -->
      <ng-container *ycTableColumn="'common:textStatus';
        let row;
        prop: 'status'">
        {{ row.status }}
      </ng-container>
      <!-- Status Set Date -->
      <ng-container *ycTableColumn="'common:textStatusDate';
        let row;
        prop: 'statusOn'">
        {{ row.statusOn | ycFormatDate }}
      </ng-container>
      <!-- Match Method -->
      <ng-container *ycTableColumn="'nonprofit:textMatchMethod';
        let row;
        prop: 'matchMethod'">
        {{ row.matchMethod }}
      </ng-container>
      <!-- Revocation List Id -->
      <ng-container *ycTableColumn="'nonprofit:textRevocationListId';
        let row;
        prop: 'revocationListId'">
        {{ row.revocationListId }}
      </ng-container>
      <!-- Revocation List Registration Id -->
      <ng-container *ycTableColumn="'nonprofit:textRevocationListRegId';
        let row;
        prop: 'revocationListRegistrationId'">
        {{ row.revocationListRegistrationId }}
      </ng-container>
      <!-- Revocation List Name -->
      <ng-container *ycTableColumn="'nonprofit:textRevocationListName';
        let row;
        prop: 'revocationListName'">
        {{ row.revocationListName }}
      </ng-container>
      <!-- Revocation List Address -->
      <ng-container *ycTableColumn="'nonprofit:textRevocationListAddress';
        let row;
        prop: 'revocationListAddress'">
        {{ row.revocationListAddress }}
      </ng-container>
    </yc-table>
  </yc-modal>
</ng-container>