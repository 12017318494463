import { Injectable } from '@angular/core';
import { TermsOfServiceModalComponent } from '../../login/terms-of-service-modal/terms-of-service-modal.component';
import { TokenService } from './token/token.service';
import { ModalFactory } from '@yourcause/common/modals';

@Injectable({ providedIn: 'root' })
export class TermsOfServiceService {

  constructor (
    private tokenService: TokenService,
    private modalFactory: ModalFactory
  ) { }

  async getUserTermsOfServiceModal () {
    const tokenInfo = this.tokenService.tokenInfo;

    if (tokenInfo && !tokenInfo.acknowledgedTermsOfService) {
      const accepted = await this.modalFactory.open(
        TermsOfServiceModalComponent,
        {},
        { keyboard: false, class: 'modal-md'}
      );
      if (!accepted) {
        this.tokenService.logout();
      }

      return accepted;
    } else {
      return true;
    }
  }
}
