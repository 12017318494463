          <div class="font-size-75 right-align">
            <span *ngIf="(transaction.fees?.totalFees?.amount - transaction.fees?.totalFees?.coveredAmount > 0)"
              class="font-size-12">
              <yc-i18n
                key="give:textServiceFees"
                defaultValue="Service fees">
              </yc-i18n>: {{ transaction.fees?.totalFees?.amount - transaction.fees?.totalFees?.coveredAmount | money: transaction.currency : true }}
            </span>
            <div *ngIf="transaction.fees?.totalFees?.amount > 0 &&
              !transaction.fees?.totalFees?.fullyCovered">
              <a class="text-info"
                href="javascript:void(0)"
                (click)="openFeeModal(transaction)">
                <yc-i18n class="font-size-12"
                  key="give:textLearnMoreAboutServiceFees"
                  defaultValue="Learn more about service fees">
                </yc-i18n>
              </a>
            </div>
            <div *ngIf="transaction.fees?.totalFees?.amount > 0 &&
              transaction.fees?.totalFees?.fullyCovered">
              <a class="text-info"
                href="javascript:void(0)"
                (click)="openFeeModal(transaction)">
                <yc-i18n class="font-size-12"
                  key="give:textFeesCoveredBy"
                  defaultValue="Fees covered by __affiliate__"
                  [context]="{
                    'affiliate': transaction.company
                  }">
                </yc-i18n>
              </a>
            </div>
          </div>