import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BannerInformationAPI } from '@core/typings/api/custom-alerts.typing';
import { DebounceFactory, PaginationOptions } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { CustomAlertsResources } from './custom-alerts.resources';
import { CustomAlertsState } from './custom-alerts.state';
import { DateService, TIMESTAMP_FORMAT } from '@yourcause/common/date';

@AttachYCState(CustomAlertsState)
@Injectable({ providedIn: 'root' })
export class CustomAlertsService extends BaseYCService<CustomAlertsState> {
  readonly charityAdminReportLink: string;
  readonly drilldownRowId: number;

  customBannersTable = DebounceFactory.createSimple((
    paginationOptions: PaginationOptions<BannerInformationAPI.BannerInformationDisplay>
  ) => {
    return this.customAlertsResources.fetchPaginatedBannerRecords(paginationOptions);
  });

  constructor (
    private customAlertsResources: CustomAlertsResources,
    private i18n: I18nService,
    private notifier: NotifierService,
    private dateService: DateService
  ) {
    super();
  }

  async editBanner (payload: BannerInformationAPI.EditBannerPayload) {
    try {
      await this.customAlertsResources.updateBanner(payload);
      this.notifier.success(this.i18n.translate(
        'admin:textSuccessUpdatingAlert',
        {},
        'Successfully updated custom alert'
      ));
      this.customBannersTable.reset.emit();
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'admin:textErrorUpdatingAlert',
        {},
        'There was an error updating the custom alert'
      ));
    }
  }

  async addBanner (payload: BannerInformationAPI.AddBannerPayload) {
    try {
      await this.customAlertsResources.addBanner(payload);
      this.notifier.success(this.i18n.translate(
        'admin:textSuccessCreatingAlert',
        {},
        'Successfully created custom alert'
      ));
      this.customBannersTable.reset.emit();
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'admin:textErrorCreatingAlert',
        {},
        'There was an error creating the custom alert'
      ));
    }
  }

  async getAllCustomBanners () {
    try {
      const response = await this.customAlertsResources.fetchAllBannerRecords();
      this.set('allBannerRecords', response.data.records);
    } catch (e) {
      console.error(e);
    }
  }

  getAllBanners () {
    return this.get('allBannerRecords');
  }

  getAlertsForPage (
    displayPageId: BannerInformationAPI.BannerDisplayPages,
    id?: number
  ) {
    const banners = this.getAllBanners().filter((alert) => {
      return (alert.displayPageId === displayPageId) && (alert.id !== id);
    });

    return banners;
  }

  getCurrentAlertForPage (displayPageId: BannerInformationAPI.BannerDisplayPages) {
    const banner = this.getAlertsForPage(displayPageId).find((alert) => {
      return this.dateService.isBetween(new Date(), alert.displayStartDate, alert.displayEndDate);
    });

    return banner || null;
  }

  isInvalidDate (
    id: number,
    type: 'start'|'end'
  ) {
    const startDateControlName = 'alertStartDate';
    const endDateControlName = 'alertEndDate';

    // problem: we want to set expiration to same day as existing start
    return (formControl: AbstractControl) => {
      if (!formControl || !formControl.parent) {
        return null;
      }
      const formValue = formControl.parent.value;
      const displayPage = formValue.displayPageId;
      const date = new Date(this.getFormattedDate(formControl.value));
      const startDate = type === 'start' ?
        new Date(date) :
        new Date(this.getFormattedDate(formValue[startDateControlName]));
      const endDate = type === 'end' ?
        new Date(date) :
        new Date(this.getFormattedDate(formValue[endDateControlName]));
      const list = this.getAlertsForPage(displayPage, id);
      const hasConflict = list.some((alert: BannerInformationAPI.BannerInformationDisplay) => {
        const alertStart = new Date(this.dateService.formatDate(alert.displayStartDate, 'yyyy-MM-dd'));
        const alertEnd = new Date(this.dateService.formatDate(alert.displayEndDate, 'yyyy-MM-dd'));
        const alertStartAfterStartDate = isSameDay(alertStart, startDate) || isAfter(alertStart, startDate);
        const alertStartIsBeforeEndDate = isBefore(alertStart, endDate);
        const exstingAlertStartsAfter = alertStartAfterStartDate && alertStartIsBeforeEndDate;
        const alertEndIsBeforeEndDate = isSameDay(alertEnd, endDate) || isBefore(alertStart, endDate);
        const alertEndIsAfterStartDate = isAfter(alertEnd, startDate);
        const exstingAlertEndsBefore = alertEndIsBeforeEndDate && alertEndIsAfterStartDate;
        const dateIsAfterAlertStart = isSameDay(date, alertStart) || isAfter(date, alertStart);
        const dateIsBeforeAlertEnd = isBefore(date, alertEnd);
        if (type === 'start') {
          return (dateIsAfterAlertStart && dateIsBeforeAlertEnd) || (exstingAlertStartsAfter || exstingAlertEndsBefore);
        } else {

          return (dateIsAfterAlertStart && dateIsBeforeAlertEnd) || (exstingAlertStartsAfter || exstingAlertEndsBefore);
        }
      });

      const sibling = formControl.parent.get(type === 'end' ? startDateControlName : endDateControlName);
      const needToValidateSibling = (!hasConflict && sibling.invalid && sibling.errors && sibling.errors.dateConflict);
      const needToInvalidateSibling = (hasConflict && sibling.valid);
      if (needToValidateSibling || needToInvalidateSibling) {
        setTimeout(() => {
          sibling.updateValueAndValidity();
        });
      }

      return hasConflict ? {
        dateConflict: {
          i18nKey: 'admin:textRangeConflictsWithOtherAlerts',
          defaultValue: 'This date range conflicts with other alerts'
        }
      } : null;
    };
  }

  private getFormattedDate (value: string|Date) {
    return this.dateService.formatDate(value, TIMESTAMP_FORMAT);
  }

  async handleSubmission (submission: BannerInformationAPI.BannerInformationDisplay) {
    const formValue = submission;
    const tempEl = document.createElement('html');
    tempEl.innerHTML = formValue.bannerText;
    const parsedBody = tempEl.getElementsByTagName('body')[0].innerHTML.replace(/\n/g, '\r\n');
    if (submission.id) {
      const payload = {
        id: submission.id,
        displayPageId: submission.displayPageId,
        bannerText: parsedBody,
        bannerComment: submission.bannerComment,
        displayStartDate: this.dateService.formatDate(submission.displayStartDate, 'y-MM-dd'),
        displayEndDate: this.dateService.formatDate(submission.displayEndDate, 'y-MM-dd')
      };
      await this.editBanner(payload);
    } else {
      const payload = {
        displayPageId: submission.displayPageId,
        bannerText: parsedBody,
        bannerComment: submission.bannerComment,
        displayStartDate: this.dateService.formatDate(submission.displayStartDate, 'y-MM-dd'),
        displayEndDate: this.dateService.formatDate(submission.displayEndDate, 'y-MM-dd')
      };
      await this.addBanner(payload);
    }
  }
}
