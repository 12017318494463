import { Injectable } from '@angular/core';
import { CreateProgramRequestModel, GetAllProgramsReturnModelPagination, ProgramModalResponse, ProgramTypeModel, UpdateProgramRequestModel } from '@core/typings/programs.typing';
import { APIResponse, PaginationOptions } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { AssetManagementService } from '../asset-management/services/asset-management.service';
import { ProgramsResources } from './programs.resources';
import { ProgramsState } from './programs.state';
import { YcFile } from '@yourcause/common/files';

@AttachYCState(ProgramsState)
@Injectable({ providedIn: 'root' })
export class ProgramsService extends BaseYCService<ProgramsState> {
  errorRetrievingDataText = this.i18n.translate(
    'common:notificationErrorRetreivingData',
    {},
    'There was an error retrieving the data'
  );

  constructor (
    private programsResources: ProgramsResources,
    private assetManagementService: AssetManagementService,
    private i18n: I18nService,
    private notifier: NotifierService
  ) {
    super();
  }

  get programTypes (): ProgramTypeModel[] {
    return this.get('programTypes');
  }

  setProgramTypes (apiResponse: APIResponse<ProgramTypeModel[]>) {
    const types = apiResponse.data;
    this.set('programTypes', types);
  }

  async getAllPrograms (
    paginationOptions: PaginationOptions<GetAllProgramsReturnModelPagination>
  ) {
    try {
      const response = await this.programsResources.getAllPrograms(
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getProgramTypes () {
    try {
      const response = await this.programsResources.getProgramTypes();
      this.setProgramTypes(response);
    } catch (e) {
      console.error(e);
    }
  }


  async createProgram (program: ProgramModalResponse) {
    let programPayload: CreateProgramRequestModel;
    if (program.externalLink && !(program.externalLink).includes('http')) {
      program.externalLink = `http://${program.externalLink}`;
    }
    if (!!program.image) {
      const file = new YcFile(
        '' + (Math.random() * 1000000000),
        program.image as Blob
      );
      delete program.image;

      const uploadImageResponse = await this.uploadProgramImage(file);
      programPayload = {
        ...program,
        imageURL: uploadImageResponse
      };
    } else {
      programPayload = {
        ...program
      };
    }
    try {
      const response = await this.programsResources.createProgram(
        programPayload
      );
      this.notifier.success(this.i18n.translate(
        'nonprofits:textSuccessCreatingProgram',
        {},
        'Successfully created program'
      ));

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:textErrorCreatingProgram',
        {},
        'There was an error creating the program'
      ));

      return null;
    }
  }

  async updateProgram (program: ProgramModalResponse) {
    let programPayload: UpdateProgramRequestModel;
    if (program.externalLink && !(program.externalLink).includes('http')) {
      program.externalLink = `http://${program.externalLink}`;
    }
    if (!!program.image && !(typeof program.image === 'string')) {
      const file = new YcFile(
        '' + (Math.random() * 1000000000),
        program.image as Blob
      );
      delete program.image;

      const uploadImageResponse = await this.uploadProgramImage(file);
      programPayload = {
        ...program,
        id: program.id,
        imageURL: uploadImageResponse
      };
    } else if (!program.image && !!program.imageURL) {
      delete program.imageURL;

      programPayload = {
        ...program,
        id: program.id
      };
    } else {
      programPayload = {
        ...program,
        id: program.id
      };
    }
    try {
      const response = await this.programsResources.updateProgram(
        programPayload
      );
      this.notifier.success(this.i18n.translate(
        'nonprofits:textSuccessUpdatingProgram',
        {},
        'Successfully updated the program'
      ));

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:textErrorUpdatingProgram',
        {},
        'There was an error updating the program'
      ));

      return null;
    }
  }

  async deleteProgram (id: number) {
    try {
      const response = await this.programsResources.deleteProgram(id);
      this.notifier.success(this.i18n.translate(
        'nonprofits:textSuccessDeletingProgram',
        {},
        'Successfully deleted the program'
      ));

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:textErrorDeletingProgram',
        {},
        'There was an error deleting the program'
      ));

      return null;
    }
  }

  async publishProgram (id: number) {
    try {
      const response = await this.programsResources.publishProgram(id);
      this.notifier.success(this.i18n.translate(
        'nonprofits:textSuccessPublishingProgram',
        {},
        'Successfully published the program'
      ));

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:textErrorPublishingProgram',
        {},
        'There was an error publishing the program'
      ));

      return null;
    }
  }

  async unPublishProgram (id: number) {
    try {
      const response = await this.programsResources.unPublishProgram(id);
      this.notifier.success(this.i18n.translate(
        'nonprofits:textSuccessUnPublishingProgram',
        {},
        'Successfully unpublished the program'
      ));

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:textErrorUnPublishingProgram',
        {},
        'There was an error unpublishing the program'
      ));

      return null;
    }
  }

  async uploadProgramImage (file: YcFile) {
    const response = await this.assetManagementService
      .uploadProgramAsset(file);

    return response.data;
  }
}
