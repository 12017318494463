import { AccountDetails } from '@core/models/account.model';
import { CachedAttr, CACHE_TYPES } from '@yourcause/common/cache';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class AccountState extends BaseYcState {
  @CachedAttr(CACHE_TYPES.LOCALSTORAGE)
  readonly accessRemovedModalClosed: boolean;

  readonly accountDetails: AccountDetails;
}
