<div class="npo-search-info">

  <!-- POPOVER TEMPLATE  -->
  <ng-template #popoverDesc
    let-desc="desc">
    {{ desc }}
  </ng-template>

  <!-- REGISTRATION AUTHORITY  -->
  <dl *ngIf="!!registrationAuthority"
    class="dl-horizontal">
    <dt>
      <yc-i18n
        key="common:lblRegistrationAuthority">
      </yc-i18n>
      <button *ngIf="showPopovers"
        class="popover-link"
        [attr.aria-label]="'common:btnInfo' | ycI18n"
        [popover]="popoverDesc"
        [popoverContext]="{
          desc: regAuthPopover
        }"
        [outsideClick]="true"
        [placement]="popoverPlacement">
        <yc-fa class="text-link ms-2 icon-popover"
          icon="info-circle"
          [light]="true">
        </yc-fa>
      </button>
    </dt>
    <dd>
      {{ registrationAuthority }}
    </dd>
  </dl>

  <!-- YC CHARITY ID  -->
  <dl *ngIf="!!ycCharityId"
    class="dl-horizontal">
    <dt>
      <yc-i18n
        key="nonprofit:lblYCCharityID"
        defaultValue="YC Charity ID">
      </yc-i18n>
    </dt>
    <dd>
      {{ ycCharityId }}
    </dd>
  </dl>
  <dl *ngIf="foundationCodeDesc"
    class="dl-horizontal">
    <dt>
      <yc-i18n
        key="nonprofits:textFoundationType"
        defaultValue="Foundation type">
      </yc-i18n>
    </dt>
    <dd>
      {{ foundationCodeDesc }}
    </dd>
  </dl>
  <dl *ngIf="subsectionCodeDesc"
    class="dl-horizontal">
    <dt>
      <yc-i18n
        key="nonprofits:textSubsectionCode"
        defaultValue="Subsection code">
      </yc-i18n>
    </dt>
    <dd>
      {{ subsectionCodeDesc }}
    </dd>
  </dl>

  <!-- IS ACTIVE  -->
  <dl *ngIf="isActive !== null"
    class="dl-horizontal">
    <dt>
      <yc-i18n
        key="changeTracking:textIsActive"
        defaultValue="Is active">
      </yc-i18n>
      <button *ngIf="isActive && showPopovers"
        class="popover-link"
        [attr.aria-label]="'common:btnInfo' | ycI18n"
        [popover]="popoverDesc"
        [popoverContext]="{
          desc: isActivePopover
        }"
        [outsideClick]="true"
        [placement]="popoverPlacement">
        <yc-fa class="text-link ms-2 icon-popover"
          icon="info-circle"
          [light]="true">
        </yc-fa>
      </button>
      <a *ngIf="!isActive && showPopovers"
        class="text-info"
        [attr.aria-label]="'common:btnInfo' | ycI18n"
        href="javascript:void(0)"
        (click)="openModal.emit(NonprofitSearchStatsModalType.NotActive)">
        <yc-fa class="text-link ms-2 icon-popover"
          icon="info-circle"
          [light]="true">
        </yc-fa>
      </a>
    </dt>
    <dd>
      <yc-fa
        [icon]="isActive ? 'check-circle' : 'times-circle'"
        [class.text-success]="isActive"
        [class.text-danger]="!isActive">
      </yc-fa>
      <yc-i18n *ngIf="isActive"
        key="common:textYes">
      </yc-i18n>
      <yc-i18n *ngIf="!isActive"
        key="common:textNo">
      </yc-i18n>
    </dd>
  </dl>

  <!-- ELIGIBLE FOR GIVING  -->
  <dl *ngIf="!!eligibleForGivingStatusId"
    class="dl-horizontal">
    <dt>
      <yc-i18n
        key="nonprofits:textIsEligibleForGiving"
        defaultValue="Is eligible for giving">
      </yc-i18n>
      <a *ngIf="showPopovers"
        class="text-info"
        [attr.aria-label]="'common:btnInfo' | ycI18n"
        href="javascript:void(0)"
        (click)="
          eligibleForGivingStatusId === BBGFEligibleStatusId.IsEligible ?
            openModal.emit(NonprofitSearchStatsModalType.BBGFEligible) :
          eligibleForGivingStatusId === BBGFEligibleStatusId.NotEligibleButCanBe ?
            openModal.emit(NonprofitSearchStatsModalType.NeedsVetting) :
          eligibleForGivingStatusId === BBGFEligibleStatusId.AwaitingReview ?
            openModal.emit(NonprofitSearchStatsModalType.BBGFAwaitingReview) :
            openModal.emit(NonprofitSearchStatsModalType.NoGiving)
        ">
        <yc-fa class="text-link ms-2 icon-popover"
          icon="info-circle"
          [light]="true">
        </yc-fa>
      </a>
    </dt>
    <dd>
      <yc-fa
        [icon]="(eligibleForGivingStatusId === BBGFEligibleStatusId.IsEligible) ?
            'check-circle' :
          (eligibleForGivingStatusId === BBGFEligibleStatusId.NotEligibleButCanBe ||
            eligibleForGivingStatusId === BBGFEligibleStatusId.AwaitingReview) ?
            'question-circle' :
          (eligibleForGivingStatusId === BBGFEligibleStatusId.NeverEligible) ?
            'times-circle' :
            ''"
        [class.text-success]="eligibleForGivingStatusId === BBGFEligibleStatusId.IsEligible"
        [class.text-warning]="eligibleForGivingStatusId === BBGFEligibleStatusId.NotEligibleButCanBe ||
          eligibleForGivingStatusId === BBGFEligibleStatusId.AwaitingReview"
        [class.text-danger]="eligibleForGivingStatusId === BBGFEligibleStatusId.NeverEligible">
      </yc-fa>
      <yc-i18n
        [key]="
          eligibleForGivingStatusId === BBGFEligibleStatusId.IsEligible ?
            'common:textYes' :
          eligibleForGivingStatusId === BBGFEligibleStatusId.AwaitingReview ?
            'common:lblVettingInProgress' :
          eligibleForGivingStatusId === BBGFEligibleStatusId.NotEligibleButCanBe ?
            'common:textNeedsVetting' :
            'common:textNo'
        ">
      </yc-i18n>
    </dd>
  </dl>

  <!-- ELIGIBLE FOR DISBURSEMENT  -->
  <dl *ngIf="eligibleForDisbursement !== null"
    class="dl-horizontal">
    <dt>
      <yc-i18n
        key="nonprofits:textIsEligibleForDisbursement"
        defaultValue="Is eligible for disbursement">
      </yc-i18n>
      <button *ngIf="eligibleForDisbursement && showPopovers"
        class="popover-link"
        [attr.aria-label]="'common:btnInfo' | ycI18n"
        [popover]="popoverDesc"
        [popoverContext]="{
          desc: disbursementPopover
        }"
        [outsideClick]="true"
        [placement]="popoverPlacement">
        <div class="text-link">
          <yc-fa class="text-link ms-2 icon-popover"
            icon="info-circle"
            [light]="true">
          </yc-fa>
        </div>
      </button>
      <a *ngIf="!eligibleForDisbursement && showPopovers"
        class="text-info"
        href="javascript:void(0)"
        [attr.aria-label]="'common:btnInfo' | ycI18n"
        (click)="openModal.emit(NonprofitSearchStatsModalType.NoDisbursement)">
        <yc-fa class="text-link ms-2 icon-popover"
          icon="info-circle"
          [light]="true">
        </yc-fa>
      </a>
    </dt>
    <dd>
      <yc-fa
        [icon]="eligibleForDisbursement ?
          'check-circle' :
          'times-circle'"
        [class.text-success]="eligibleForDisbursement"
        [class.text-danger]="!eligibleForDisbursement">
      </yc-fa>
      <yc-i18n *ngIf="eligibleForDisbursement"
        key="common:textYes">
      </yc-i18n>
      <yc-i18n *ngIf="!eligibleForDisbursement"
        key="common:textNo">
      </yc-i18n>
    </dd>
  </dl>
</div>