import { Component, Input } from '@angular/core';
import { Transaction } from '@core/models/donation.model';
import { ModalFactory } from '@yourcause/common/modals';
import { CheckFeeDetailsModalComponent } from '../../../give/check-fee-details-modal/check-fee-details-modal.component';


@Component({
  selector: 'npp-transaction-fees',
  templateUrl: './transaction-fees.component.html',
  styleUrls: ['./transaction-fees.component.scss']
})
export class TransactionFeesComponent {
  @Input() transaction: Transaction;

  constructor (
    private modalFactory: ModalFactory
  ) { }

  async openFeeModal (transaction: Transaction) {
    await this.modalFactory.open(
      CheckFeeDetailsModalComponent,
      {
        transactionFeeDetails: transaction.fees,
        companyName: transaction.company,
        disbursementFees: transaction.disbursementFees,
        currency: transaction.currency,
        disbursementCurrencyCode: transaction.disbursementCurrencyCode
      }
    );
  }
}
