<ng-container *ngIf="chapterText">
  {{ chapterText }}
  <br>
</ng-container>
<ng-container *ngIf="designation">
  <small>
    {{ designation }}
  </small>
  <br>
</ng-container>
<span *ngIf="dedicationType">
  <small>
    {{ getDedicationText() }}
  </small>
</span>