import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'nppEventStatus'
})
export class EventStatus implements PipeTransform {
  constructor (
    private readonly i18n: I18nService
  ) {}

  transform (value: any, isDraft: boolean): any {
    // isDraft check for Events that were created before Approval Statuses
    // Live replaces Published
    let eventStatus;
    switch (value) {
      case null:
      case undefined:
      case 0:
        if (isDraft) {
          eventStatus = this.i18n.translate('common:textDraft', {}, 'Draft');

          return eventStatus;
        }
        eventStatus = this.i18n.translate('common:textLive', {}, 'Live');

        return eventStatus;
      case 1:
        eventStatus = this.i18n.translate('common:textLive', {}, 'Live');

        return eventStatus;
      case 2:
        eventStatus = this.i18n.translate('common:textDeclined', {}, 'Declined');

        return eventStatus;
      case 3:
        eventStatus = this.i18n.translate('common:textPending', {}, 'Pending');

        return eventStatus;
      case 4:
        eventStatus = this.i18n.translate('common:textChangesRequested', {}, 'Changes Requested');

        return eventStatus;
      default:
        eventStatus = this.i18n.translate('common:textDraft', {}, 'Draft');

        return eventStatus;
    }
  }
}
