import { Directive, TemplateRef, ViewContainerRef, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[ycUnlessSystemRole]'
})
export class UnlessSystemRoleDirective implements OnInit {
  @Input() ycUnlessSystemRole: string;
  constructor (
    public templateRef: TemplateRef<any>,
    public viewRef: ViewContainerRef,
    public roleService: AuthService
  ) {
  }

  ngOnInit () {
    if (!this.roleService.hasSystemRole(this.ycUnlessSystemRole)) {
      this.viewRef.createEmbeddedView(this.templateRef, {}, 0);
    }
  }
}
