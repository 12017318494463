import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'nppLocalizeBankHelp'
})
export class LocalizeBankHelp implements PipeTransform {
  constructor (
    private readonly i18n: I18nService
  ) {}

  transform (bankField: string, country: string): string {
    let localizedHelpText;
    const fieldName = bankField ? bankField.toLowerCase() : '';
    const countryCode = country ? country.toLowerCase() : '';
    switch (fieldName) {
      case 'bankbranch':
        switch (countryCode) {
          case 'br':
            localizedHelpText = this.i18n.translate('bankInfo:BankBranchHelpBrazil', {}, '3-7 digit numeric');

            return localizedHelpText;
          }

        return '';
      case 'benefitaxpayerid':
        switch (countryCode) {
          case 'br':
            localizedHelpText = this.i18n.translate('bankInfo:CNPJBrazilHelp', {}, '14-digit numeric');

            return localizedHelpText;
          case 'ar':
            localizedHelpText = this.i18n.translate('bankInfo:CUILArgentinaHelp', {}, 'This must be the word \"CUIL\" followed by a space and then 11-digit number. For example, CUIL 23875421458');

            return localizedHelpText;
          }

        return '';
      case 'bsb':
        switch (countryCode) {
          case 'au':
            localizedHelpText = this.i18n.translate('bankInfo:BSBAustraliaHelp', {}, '6-digit numeric');

            return localizedHelpText;
          }

        return '';
      case 'clabe':
        switch (countryCode) {
          case 'mx':
            localizedHelpText = this.i18n.translate('bankInfo:CLabeMexicoHelp', {}, '18 digits');

            return localizedHelpText;
          }

        return '';
      case 'bankaccountnumber':
        switch (countryCode) {
          case 'sg':
            localizedHelpText = this.i18n.translate('bankInfo:AccountNumberSingaporeHelp', {}, 'Account number can be no more than 11 characters');

            return localizedHelpText;
          case 'cr':
            localizedHelpText = this.i18n.translate('bankInfo:AccountNumberCostaRicaHelp', {}, '17 digits');

            return localizedHelpText;
          case 'hk':
            localizedHelpText = this.i18n.translate('bankInfo:AccountNumberHongKongHelp',
            {}, 'Account number can be no more than 9 characters');

            return localizedHelpText;
          case 'eg':
            localizedHelpText = this.i18n.translate('bankInfo:AccountNumberEgyptHelp',
            {}, 'National Bank of Egypt: 19 digits; <br/> Bank of Alexandria: 12 digits; <br/> Banque Misr: 14 digits;');

            return localizedHelpText;
          }

        return '';
      case 'otherroutingcode':
        switch (countryCode) {
          case 'ca':
            localizedHelpText = this.i18n.translate('bankInfo:otherRoutingCodeCanadaHelp', {}, '9-digit numeric, no spaces');

            return localizedHelpText;
          case 'cn':
            localizedHelpText = this.i18n.translate('bankInfo:otherRoutingCodeChinaHelp', {}, '14 characters (CN + 12 digits)');

            return localizedHelpText;
          }

        return '';
      case 'cedulajuridica':
        switch (countryCode) {
          case 'cr':
            localizedHelpText = this.i18n.translate('bankInfo:otherCedulaJuridicaCostaRicaHelp', {}, '9-12 digits');

            return localizedHelpText;
          }

        return '';
      case 'cuentacliente':
        switch (countryCode) {
          case 'cr':
            localizedHelpText = this.i18n.translate('bankInfo:cuentaClienteCostaRicaHelp', {}, '17 digits');

            return localizedHelpText;
          }

        return '';
      }

    return '';
    }
}
