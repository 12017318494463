export interface ReleaseNoteAcknowledgement {
  releaseRequiresAcknowledgement?: boolean;
  releaseId?: string;
  version: string;
  releaseDate: string;
  startDate: string;
  endDate: string;
  description: string;
  releaseNoteUrl?: string;
}

export interface ManageReleaseNotePayload extends ReleaseNoteAcknowledgement {
  userReleaseNoteUrl: string | null;
  adminReleaseNoteUrl: string | null;
  id?: number;
}

export interface ManageReleaseNotesModalResponse {
  manageReleaseNotesPayload: ManageReleaseNotePayload;
}

export interface ReleaseNotePayload {
  startDate: string;
  endDate: string;
  releaseDate: string;
  version: string;
  description: string;
  userReleaseNoteUrl: string;
  adminReleaseNoteUrl: string;
  id?: number;
}

export interface ReleaseNoteForUser extends ReleaseNotePayload {
  releaseNoteUrl: string;
}

export enum ReleaseNotesModalScenario {
  ADD,
  EDIT
}

export interface UpdateReleaseNotePayload extends ReleaseNote {
  id: number;
}

export interface ReleaseNote {
  startDate: string;
  endDate: string;
  releaseDate: string;
  version: string;
  description: string;
  userReleaseNoteUrl: string;
  adminReleaseNoteUrl: string;
  id: number;
}
