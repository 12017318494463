<yc-modal
  [modalHeader]="healthIndicatorStats.healthIndicatorName"
  [modalSubHeader]="'MANAGE:hdrHealthIndicatorErrorCountDynamic' | ycI18n: 'Error Count: __healthIndicatorErrorCount__' : { healthIndicatorErrorCount: healthIndicatorStats.healthIndicatorErrorCount }"
  [primaryButtonText]="'common:textClose' | ycI18n: 'Close'"
  (onPrimaryClick)="closeModal.emit()">

  <div class="table-wrapper">
    <table class="table yc-table">
      <thead class="table-col-sticky">
        <tr>
          <th *ngFor="let header of headers">
            {{header}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let detail of details">
          <td *ngFor="let header of headers">
            {{detail[header]}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</yc-modal>