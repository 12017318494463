import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { MyNonprofitsService } from "./my-nonprofits.service";
import { RegistrationAuthoritiesService } from "../registration-authorities/registration-authorities.service";

export const myNonprofitsResolver: ResolveFn<void> = async () => {
  const myNonprofitsService = inject(MyNonprofitsService);
  const registrationAuthoritiesService = inject(RegistrationAuthoritiesService);
  await Promise.all([
    registrationAuthoritiesService.getAllRegistrationAuthorities(true),
    myNonprofitsService.setAllDataForMyNonprofitRows()
  ]);
}
