import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RemoveUserRolesPayload, UserLoginClaims } from '@core/models/account.model';
import { BasicNonprofit, NpoForAdminSelectModel } from '@core/models/npo.model';
import { AccountService } from '@core/services/account.service';
import { SelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
import { ArrayHelpersService } from '@yourcause/common/utils';

@Component({
  selector: 'yc-confirm-npo-admin-modal',
  styleUrls: ['./confirm-npo-admin-modal.component.scss'],
  templateUrl: './confirm-npo-admin-modal.component.html'
})

export class ConfirmNpoAdminModalComponent extends YCModalComponent<RemoveUserRolesPayload> implements OnInit {
  @Input() myNpos: BasicNonprofit[];
  @Input() userInfo: UserLoginClaims;
  @Input() userAccessRemovedDate: string;
  ready = false;
  npoOptions: NpoForAdminSelectModel[] = [];
  npoDisplayOptions: SelectOption[] = [];
  npoIdsSelected: number[] = [];
  nposToRemove: number[] = [];
  confirmForm: UntypedFormGroup;
  userNameEmail: string;
  modalHeader: string;
  modalBody: string;
  modalBody_p_2: string;
  modalBody_p_3: string;
  checkboxText = this.i18n.translate(
    'nonprofits:textIUnderstandNotSelectingANonprofit',
    {},
    'I understand that by NOT selecting a specific nonprofit, I will lose access to administer or manage that nonprofit in the NPOconnect Nonprofit Platform.'
  );

  constructor (
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private accountService: AccountService,
    public formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit () {
    this.accountService.setAccessRemovedModalClosed(false);
    this.userNameEmail = this.userInfo?.firstName + ' ' +
      this.userInfo?.lastName + ' - ' +
      this.userInfo?.userName;

    if (!this.userAccessRemovedDate) {
      this.myNpos?.forEach((npo: BasicNonprofit) => {
        this.npoOptions.push({
          nonprofitName: npo.name,
          nonprofitId: npo.id,
          address: npo.address,
          registrationId: npo.registrationId,
          nonprofitIconImageUrl: npo.nonprofitIconImageUrl
        });
      });
      this.npoOptions = this.arrayHelper.sort(
        this.npoOptions,
        'nonprofitName',
        false
      );
      this.npoOptions.forEach((npo) => {
        this.npoDisplayOptions.push({
          display: this.formatNpoDisplay(npo),
          value: +npo.nonprofitId
        });
      });

      this.confirmForm = this.formBuilder.group({
        selectNpos: [],
        confirmNotSelecting: [false, Validators.requiredTrue]
      });

      this.modalHeader = this.i18n.translate(
        'nonprofits:hdrConfirmNonprofitAccess',
        {},
        'Confirm Nonprofit Access'
      );
      this.modalBody = this.i18n.translate(
        'nonprofits:textSelectTheNonprofitsToContinueAdministering',
        {},
        `Select at least one nonprofit that you continue to administer or manage in the NPOconnect Nonprofit Platform. Not selecting a nonprofit will result in your administrative/manager rights being removed for that nonprofit.`
      );
    } else {
      this.modalHeader = this.i18n.translate(
        'nonprofits:hdrNonprofitAccessRemoved',
        {},
        'Nonprofit Access Removed'
      );
      this.modalBody = this.i18n.translate(
        'nonprofits:textYouWereRemovedAsAdministrator_p_1',
        {
          date: this.userAccessRemovedDate
        },
        `On __date__ you were removed as an administrator of any of the nonprofits with which you were associated.`
      );
      this.modalBody_p_2 = this.i18n.translate(
        'nonprofits:textYouWereRemovedAsAdministrator_p_2',
        {},
        `This was done because you hadn't signed into NPOconnect for over 18 months and did not respond to our email asking you to sign in in order to maintain your account.`
      );
      this.modalBody_p_3 = this.i18n.translate(
        'nonprofits:textYouWereRemovedAsAdministrator_p_3',
        {},
        `You can still use this account but will need to register to administer your nonprofit(s) again.`
      );
    }

    this.ready = true;
  }

  getNposToRemove (ids: number[]) {
    this.npoIdsSelected = ids;
    const allNpoIds = this.myNpos.map(npo => npo.id);
    this.nposToRemove = allNpoIds.filter(val => !this.npoIdsSelected.includes(val));
  }

  formatNpoDisplay (npo: NpoForAdminSelectModel) {
    return `<div class="d-flex flex-row p-1">
    <div class="ms-4">
      <img src="${npo.nonprofitIconImageUrl}" height="60px" alt="'common:textNonprofitIcon' | ycI18n">
    </div>
    <div class="ms-3"><span><strong>${npo.nonprofitName}</strong></span><br>
      ${npo.address.address1}, ${npo.address.city}, ${npo.address.stateProvRegCode}<br>
      ${npo.registrationId}
    </div>
    </div>`;
  }

  get disablePrimaryButton () {
    return this.confirmForm &&
      !(this.npoIdsSelected.length > 0 &&
        this.confirmForm?.valid);
  }

  get primaryButtonText () {
    return this.confirmForm ?
      this.i18n.translate(
        'common:btnConfirm',
        {},
        'Confirm'
      ) : '';
  }

  get cancelButtonText () {
    return !this.confirmForm ?
      this.i18n.translate(
        'common:btnClose',
        {},
        'Close'
      ) : '';
  }

  onCancel () {
    this.accountService.setAccessRemovedModalClosed(true);
    this.closeModal.emit();
  }

  onPrimaryClick () {
    const payload: RemoveUserRolesPayload = {
      nonprofitIds: this.confirmForm ?
        this.nposToRemove :
        []
    };
    this.closeModal.emit(payload);
  }
}
