<div class="yc-card-group"
  [class.d-flex]="columnClass">

  <ng-container *ngFor="let item of items">
    <div class="yc-card-wrapper {{ columnClass }}">
      <div class="yc-card yc-card-payment card"
        [class.with-footer]="item.footerText">

        <div *ngIf="item.state !== 'zero'">
          <div *ngIf="item.state === 'error'">
            <div class="row">
              <div class="col">
                <dl>
                  <!-- TITLE  -->
                  <dt class="title">
                    <span *ngIf="!!item.i18nTitleKey">
                      <yc-i18n *ngIf="!!item.titleTranslationContext"
                        [key]="item.i18nTitleKey"
                        [context]="{
                          'titleTranslationContext': item.titleTranslationContext
                        }"
                        [defaultValue]="item.title">
                      </yc-i18n>
                      <yc-i18n *ngIf="!item.titleTranslationContext"
                        [key]="item.i18nTitleKey"
                        [defaultValue]="item.title">
                      </yc-i18n>
                    </span>
                    <span *ngIf="!item.i18nTitleKey">
                      {{ item.title }}
                    </span>
                  </dt>
                  <dd *ngIf="item.titleDate"
                    class="sub-title">
                    {{ item.titleDate | ycFormatDate }}
                  </dd>
                  <dd>
                    <span *ngIf="!!item.i18nMessageKey">
                      <yc-i18n *ngIf="!!item.messageTranslationContext"
                        [key]="item.i18nMessageKey"
                        [context]="{
                          'messageTranslationContext': item.messageTranslationContext
                        }"
                        [defaultValue]="item.message">
                      </yc-i18n>
                      <yc-i18n *ngIf="!item.messageTranslationContext"
                        [key]="item.i18nMessageKey"
                        [defaultValue]="item.message">
                      </yc-i18n>
                    </span>
                    <span *ngIf="!item.i18nMessageKey">
                      {{ item.message }}
                    </span>
                  </dd>
                  <dd>
                    <!-- LINK  -->
                    <div *ngIf="item.refreshLinkText"
                      class="col">
                      <yc-button
                        [link]="true"
                        (onClick)="item.refreshAction(item)"
                        size="box">
                        <yc-fa class="me-2"
                          [icon]="item.state === 'error' ?
                            'spinner fa-spin' :
                            ''">
                        </yc-fa>
                        <span *ngIf="item.i18nRefreshTextKey">
                          <yc-i18n
                            [key]="item.i18nRefreshTextKey"
                            [defaultValue]="item.refreshLinkText">
                          </yc-i18n>
                        </span>
                      </yc-button>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          <div *ngIf="item.state !== 'error'">
            <div class="row">
              <div class="col">
                <dl>
                  <!-- TITLE  -->
                  <dt class="title">
                    <span *ngIf="!!item.i18nTitleKey">
                      <yc-i18n *ngIf="!!item.titleTranslationContext"
                        [key]="item.i18nTitleKey"
                        [context]="{
                          'titleTranslationContext': item.titleTranslationContext
                        }"
                        [defaultValue]="item.title">
                      </yc-i18n>
                      <yc-i18n *ngIf="!item.titleTranslationContext"
                        [key]="item.i18nTitleKey"
                        [defaultValue]="item.title">
                      </yc-i18n>
                    </span>
                    <span *ngIf="!item.i18nTitleKey">
                      {{ item.title }}
                    </span>
                  </dt>
                  <dd class="sub-title" *ngIf="item.titleDate">
                    {{ item.titleDate | ycFormatDate }}
                  </dd>
                  <dd>
                    <div *ngIf="!item.amountType" class="amount">
                      {{ item.amount | money }}
                    </div>
                    <div *ngIf="item.amountType" class="amount">
                      {{ item.amountType === 'currency' ? (item.amount | money) : (item.amount) }}
                    </div>
                    <!-- LINK  -->
                    <yc-button
                      [link]="true"
                      [routerLink]="item.link"
                      (onClick)="item.linkAction?.(item)"
                      size="sm">
                      <span *ngIf="!!item.i18nLinkTextKey">
                        <yc-i18n *ngIf="!!item.linkTranslationContext"
                          [key]="item.i18nLinkTextKey"
                          [context]="{
                            'linkTranslationContext': item.linkTranslationContext
                          }"
                          [defaultValue]="item.linkText">
                        </yc-i18n>
                        <yc-i18n *ngIf="!item.linkTranslationContext"
                          [key]="item.i18nLinkTextKey"
                          [defaultValue]="item.linkText">
                        </yc-i18n>
                      </span>
                      <span *ngIf="!item.i18nLinkTextKey">
                        {{ item.linkText }}
                      </span>
                      &nbsp;
                      <yc-fa icon="angle-right"></yc-fa>
                    </yc-button>
                  </dd>
                </dl>
              </div>
              <div class="col" *ngIf="item.downloadText">
                <yc-button
                  [primary]="true"
                  (onClick)="item.downloadAction(item)"
                  size="box">
                  <yc-fa
                    [icon]="item.state === 'processing' ?
                      'spinner fa-spin' :
                      'download'">
                  </yc-fa>
                  <span *ngIf="item.i18nDownloadTextKey">
                    <yc-i18n
                      [key]="item.i18nDownloadTextKey"
                      [defaultValue]="item.downloadText">
                    </yc-i18n>
                  </span>
                  <span *ngIf="!item.i18nDownloadTextKey">
                    {{ item.downloadText }}
                  </span>
                  <div *ngIf="item.downloadSubText">
                    <small>
                      <span *ngIf="item.i18nDownloadSubTextKey">
                        <yc-i18n
                          [key]="item.i18nDownloadSubTextKey"
                          [defaultValue]="item.downloadSubText">
                        </yc-i18n>
                      </span>
                      <span *ngIf="!item.i18nDownloadSubTextKey">
                        {{ item.downloadSubText }}
                      </span>
                  </small>
                  </div>
                </yc-button>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="item.state === 'zero'" >
          <div class="row">
            <div class="col text-center">
              <img class="img-fluid"
                src="/assets/img/placeholders/no-events-or-opportunities-to-display.svg"
                [alt]="'common:lblNoResultsFound' | ycI18n: 'No results found'"/>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <dl>
                <!-- TITLE  -->
                <dt class="title">
                  <span *ngIf="!!item.i18nTitleKey">
                    <yc-i18n *ngIf="!!item.titleTranslationContext"
                      [key]="item.i18nTitleKey"
                      [context]="{
                        'titleTranslationContext': item.titleTranslationContext
                      }"
                      [defaultValue]="item.title">
                    </yc-i18n>
                    <yc-i18n *ngIf="!item.titleTranslationContext"
                      [key]="item.i18nTitleKey"
                      [defaultValue]="item.title">
                    </yc-i18n>
                  </span>
                  <span *ngIf="!item.i18nTitleKey">
                    {{ item.title }}
                  </span>
                </dt>
                <dd class="sub-title">
                  <span *ngIf="!!item.i18nMessageKey">
                    <yc-i18n *ngIf="!!item.messageTranslationContext"
                      [key]="item.i18nMessageKey"
                      [context]="{
                        'messageTranslationContext': item.messageTranslationContext
                      }"
                      [defaultValue]="item.message">
                    </yc-i18n>
                    <yc-i18n *ngIf="!item.messageTranslationContext"
                      [key]="item.i18nMessageKey"
                      [defaultValue]="item.message">
                    </yc-i18n>
                  </span>
                  <span *ngIf="!item.i18nMessageKey">
                    {{ item.message }}
                  </span>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="footer success" *ngIf="item.footerText">
          <span class="yc-line-limit-1 left-text" *ngIf="!item.footerLink">
            <span *ngIf="item.i18nFooterTextKey">
              <yc-i18n
                [key]="item.i18nFooterTextKey"
                [defaultValue]="item.footerText">
              </yc-i18n>
            </span>
            <span *ngIf="!item.i18nFooterTextKey">
              {{ item.footerText }}
            </span>
          </span>
          <a [routerLink]="item.footerLink" class="yc-line-limit-1 left-text" *ngIf="item.footerLink">
            <span *ngIf="item.i18nFooterTextKey">
              <yc-i18n
                [key]="item.i18nFooterTextKey"
                [defaultValue]="item.footerText">
              </yc-i18n>
            </span>
            <span *ngIf="!item.i18nFooterTextKey">
              {{ item.footerText }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
