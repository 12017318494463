<yc-app-shell
  [sidebarLinks]="sidebarLinks$ | async"
  [sidebarClosed]="sidebarClosed$ | async"
  [logoLocation]="logoUrl"
  [logoName]="primaryText"
  [logoDescription]="secondaryText"
  [productName]="'YourCause - NPOconnect Nonprofit Platform'"
  [simpleProductName]="'NPOconnect Nonprofit Platform'"
  [accountName]="accountName"
  [initials]="initials"
  [termsAndConditionsLink]="termsAndConditionsLink"
  [privacyPolicyLink]="privacyPolicyLink"
  [accountLogoUrl]="logoUrl"
  [banners]="banners$ | async"
  [navTour]="tourSteps[0]"
  [myAccountTour]="tourSteps[1]"
  [accountRole]="accountRole"
  [footerState]="footerState$ | async"
  [footerAction]="footerAction$ | async"
  [footerCancelAction]="footerCancelAction$ | async"
  [footerSecondaryAction]="footerSecondaryAction$ | async"
  [footerTertiaryAction]="footerTertiaryAction$ | async"
  [footerActionDisabled]="footerActionDisabled$ | async"
  [footerActionLabel]="footerActionLabel$ | async"
  [footerCancelLabel]="footerCancelLabel$ | async"
  [footerSecondaryLabel]="footerSecondaryLabel$ | async"
  [footerSecondaryDisabled]="footerSecondaryDisabled$ | async"
  [footerTertiaryLabel]="footerTertiaryLabel$ | async"
  [footerInfoText]="footerInfoText$ | async"
  [footerInfoIcon]="footerInfoIcon$ | async"
  [footerInfoTooltip]="footerInfoTooltip$ | async"
  [showHeaderButtons]="headerVisible"
  [copyrightText]="copyrightText"
  [editAccountLink]="'/my-account'"
  [commPreferencesLink]="'/my-account/communication-preferences'"
  [countDownTime]="timeRemaining$ | async"
  [autoSignoutVisible]="showTimeout$ | async"
  (onKeepMeSignedIn)="keepMeSignedIn()"
  (onSignOutClick)="onSignOutClick()"
  (onSidebarToggle)="handleSidebarToggle()">
  <yc-admin-navigation *ngIf="isYcAdminOrSupport">
  </yc-admin-navigation>
  <router-outlet></router-outlet>
</yc-app-shell>