import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { CachedAttr, CACHE_TYPES } from '@yourcause/common/cache';
import { } from '@yourcause/common/state';


@Injectable({ providedIn: 'root' })
export class DeepLinkingService {
  @CachedAttr(CACHE_TYPES.LOCALSTORAGE)
  private _attemptedRoute: string;

  constructor (
    private router: Router
  ) { }

  calculatePathname () {
    return location.pathname +
      (location.search || '') +
      (location.hash || '');
  }

  get attemptedRoute () {
    return this._attemptedRoute;
  }

  attemptDeepLinking (route: string) {
    this.router.navigateByUrl(this._attemptedRoute || route, /* Removed unsupported properties by Angular migration: queryParamsHandling. */ {});
    this._attemptedRoute = '';
  }

  setAttemptedRoute (route = this.calculatePathname()) {
    if (route && route !== '/') {
      this._attemptedRoute = route;
    }
  }

  getExtraRouteParams (queryParams: Params) {
    if (!!queryParams) {
      return {
        queryParams
      };
    }

    return null;
  }

  attemptDeepLink (route: string, queryParams?: Params) {
    const existingRoute = this._attemptedRoute;
    const extras = this.getExtraRouteParams(queryParams);
    const _route = existingRoute || route;
    if (!!extras) {
      this.router.navigate([_route], extras);
    } else {
      this.router.navigateByUrl(_route);
    }
    this.setAttemptedRoute('');
  }
}
