import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReleaseNoteAcknowledgement, ReleaseNoteForUser, ReleaseNotePayload } from '@core/models/release-notes.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse, APIResult, PaginationOptions } from '@yourcause/common';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReleaseNotesResources {

  constructor (
    private http: HttpClient,
    private httpRestService: HttpRestService
  ) { }

  getCurrentReleaseAcknowledgement () {
    return this.http.get<APIResponse<ReleaseNoteAcknowledgement>>(
      'api/ReleaseAcknowledgement/GetCurrentReleaseAcknowledgement'
    ).pipe(map((response) => {
      return response.data;
    }));
  }

  setReleaseAcknowledged (releaseID: number) {
    const endpoint = `api/ReleaseAcknowledgement/SetReleaseAcknowledged?releaseId=${releaseID}`;

    return this.http.post(
      endpoint,
      {}
    ).pipe(map(() => true));
  }

  async getReleaseList (
    paginationOptions: PaginationOptions<ReleaseNotePayload>
  ) {
    return this.httpRestService.post<APIResult<ReleaseNotePayload>>(
      'api/release/all',
      {
        paginationOptions
      }
    );
  }

  createRelease (
    payload: ReleaseNotePayload
  ): Promise<ReleaseNotePayload> {
    const endpoint = 'api/release';

    return this.httpRestService.post(
      endpoint,
      payload
    );
  }

  updateRelease (
    payload: ReleaseNotePayload
  ): Promise<ReleaseNotePayload> {
    const endpoint = 'api/release';

    return this.httpRestService.put(
      endpoint,
      payload
    );
  }

  deleteRelease (
    releaseId: number
  ): Promise<ReleaseNotePayload> {
    const endpoint = `api/release/${releaseId}`;

    return this.httpRestService.delete(endpoint);
  }

  async getListOfReleasesForUser (
    paginationOptions: PaginationOptions<ReleaseNoteForUser>
  ) {
    return this.httpRestService.post<APIResult<ReleaseNoteForUser>>(
      'api/release/user',
      {
        paginationOptions
      }
    );
  }
}
