<div class="yc-accordion-stepper">
  <accordion class="accordion"
    [closeOthers]="false">
    <accordion-group
      *ngFor="let step of steps;
        let index = index;
        first as first;
        last as last;"
      class="accordion-step"
      [isOpen]="isOpen(index) || step.openStepOnInit"
      [isDisabled]="isDisabled(index) || step.disabled"
      [class.active]="isOpen(index)"
      [class.enabled]="!isDisabled(index) && !step.disabled"
      [class.disabled]="isDisabled(index) || step.disabled"
      [class.first]="first"
      [class.last]="last && !isOpen(index)">
      <div accordion-heading class="accordion-step-header">
        <div class="title">
          {{ index + 1 }}.
          <yc-i18n
            [key]="step.i18nKey"
            [defaultValue]="step.label">
          </yc-i18n>
        </div>
      </div>
      <div class="accordion-step-body">
        <ng-container *ngComponentOutlet="step.component">
        </ng-container>
      </div>
    </accordion-group>
  </accordion>
</div>