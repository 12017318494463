
import { Injectable } from '@angular/core';
import { Donation, TotalPayload } from '@core/models/myworkspace-finance.model';
import { APIResponse, APIResult } from '@yourcause/common';
import { HttpRestService } from '@core/services/http-rest.service';

@Injectable({ providedIn: 'root' })
export class MyWorkspaceFinanceResources {
  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getMostRecentPayments () {
    const endpoint = '/api/Nonprofit/Finance/Payments';
    const payload = {
      paginationOptions: {
        rowsPerPage: 1,
        pageNumber: 1,
        returnAll: false,
        sortColumns: [{
          columnName: 'dateCreated',
          sortAscending: false
        }]
      }
    };

    const response = await this.httpRestService.post<APIResult<Donation>>(
      endpoint,
      payload
    );

    return response.data;
  }

  async getVerifyDonationsTotal () {
    const response = await this.httpRestService.get<APIResponse<TotalPayload>>(
      'api/NonprofitDashboard/VerifyDonationsTotal',
      {}
    );

    return response.data.total;
  }

  async getVolunteerHoursUnverified () {
    const response = await this.httpRestService.get<{ data: TotalPayload }>(
      'api/NonprofitDashboard/VolunteerHoursToBeVerified',
      {}
    );

    return response.data.total;
  }
}
