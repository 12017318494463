import { FeatureFlagModel } from '@core/typings/feature-flag.typing';
import { CachedAttr, CACHE_TYPES } from '@yourcause/common/cache';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class FeatureFlagState extends BaseYcState {
  readonly featureFlags: FeatureFlagModel[];

  @CachedAttr(CACHE_TYPES.LOCALSTORAGE)
  readonly programsFlagValue: boolean;
  readonly bbidFlagValue: boolean;
  readonly liveChatFlagValue: boolean;
  readonly populationServedFlagValue: boolean;
}
