import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nppVettingType'
})
export class VettingTypePipe implements PipeTransform {
  constructor (
  ) { }

  transform (val: string|number): string {
    switch (val) {
      case 1:
      case 'ED':
        return 'International';
      case 3:
      case 'Standard':
        return 'Standard';
      case 4:
      case 'Domestic Vetting':
      default:
        return 'Domestic';
      case 5:
      case 'Partner':
        return 'Partner';
    }
  }
}
