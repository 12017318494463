import { Pipe, PipeTransform } from '@angular/core';
import { CsrApprovalStatus, EventActionScenario, VolunteerEvent } from '@core/models/volunteer-event.model';

@Pipe({
  name: 'nppEventAction'
})
export class VolunteerEventActionPipe implements PipeTransform {
  row: VolunteerEvent;
  npoIsActive: boolean;
  scenario: EventActionScenario;

  transform (
    row: VolunteerEvent,
    npoIsActive: boolean,
    scenario: EventActionScenario
  ): boolean {
    this.row = row;
    this.npoIsActive = npoIsActive;
    this.scenario = scenario;

    if (scenario !== EventActionScenario.SHOW_MENU) {
      return this.validateOneScenario();
    } else {
      return this.canManage ||
        this.canCopyOrRemove ||
        this.canPublish ||
        this.canView ||
        this.canSubmit;
    }
  }

  validateOneScenario (): boolean {
      switch (this.scenario) {
        case EventActionScenario.MANAGE:
          return this.canManage;
        case EventActionScenario.PUBLISH:
          return this.canPublish;
        case EventActionScenario.VIEW:
          return this.canView;
        case EventActionScenario.SUBMIT:
          return this.canSubmit;
        default:
        case EventActionScenario.COPY_REMOVE:
          return this.canCopyOrRemove;
      }
  }

  get canManage (): boolean {
    return this.npoIsActive &&
      ((this.row.isDraft && !this.row.approvalStatus) ||
      this.row.approvalStatus === CsrApprovalStatus.RequestChange);
  }

  get canView (): boolean {
    return (!this.row.isDraft && !this.row.approvalStatus) ||
      (
        this.row.requiresApproval &&
        (this.row.approvalStatus !== null &&
          this.row.approvalStatus !== CsrApprovalStatus.RequestChange)
      );
  }

  get canCopyOrRemove (): boolean {
    return this.npoIsActive;
  }

  get canPublish (): boolean {
    return this.npoIsActive &&
      this.row.isDraft &&
      !this.row.requiresApproval &&
      (
        !this.row.customQuestionUrl ||
        (!!this.row.customQuestionUrl &&
          !!this.row.customQuestionSetResponseId)
      );
  }

  get canSubmit (): boolean {
    return this.npoIsActive &&
      this.row.isDraft &&
      this.row.requiresApproval &&
      !this.row.approvalStatus &&
      (
        !this.row.customQuestionUrl ||
        (!!this.row.customQuestionUrl &&
          !!this.row.customQuestionSetResponseId)
      );
  }
}
