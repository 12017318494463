import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';
import { VolunteerShift } from '../models/volunteer-event.model';


@Pipe({
  name: 'nppShiftCapacity'
})
export class ShiftCapacity implements PipeTransform {
  constructor (
    private readonly i18n: I18nService
  ) {}
  unlimitedText = this.i18n.translate('common:textUnlimited', {}, 'Unlimited');



  transform (shift: VolunteerShift): any {
    if (shift.capacity) {
      return `${shift.numberOfParticipants}/${shift.capacity}`;
    } else {
      return this.unlimitedText;
    }
  }
}
