import { Component, Input } from '@angular/core';
import { Address } from '@yourcause/common';

@Component({
  selector: 'yc-npo-address',
  templateUrl: './npo-address.component.html',
  styleUrls: ['./npo-address.component.scss']
})
export class NpoAddressComponent {
  @Input() address: Address;

  adaptAddress (address: Address): Address {
    let stateProvRegCode = '';

    if (this.address?.state) {
      stateProvRegCode = this.address.state;

      return {
        ...this.address,
        stateProvRegCode
      };
    }

    return address;
  }
}
