import { Injectable } from '@angular/core';
import { BannerState } from '@core/state/banner.state';
import { BannerInfo } from '@yourcause/common/banner';
import { I18nService } from '@yourcause/common/i18n';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';


@AttachYCState(BannerState)
@Injectable({ providedIn: 'root' })
export class BannerService extends BaseYCService<BannerState> {
  constructor (
    private i18n: I18nService
  ) {
    super();
  }

  get showInactiveNpoBanner (): boolean {
    return this.get('showInactiveNpoBanner');
  }

  get banners () {
    const defaultBanners: BannerInfo[] = [{
      visible: false,
      message: '',
      buttonText: '',
      onButtonClick: null
    }];

    return this.get('banners') || defaultBanners;
  }

  setShowInactiveNpoBanner (val: boolean) {
    this.set('showInactiveNpoBanner', val);
  }

  setBanners (banners: BannerInfo[]) {
    this.set('banners', banners);
  }

  clearBanner () {
    this.setBanners([]);
  }

  getCurrentNonprofitIsNotActiveBanner (): BannerInfo {
    return {
      visible: true,
      message: this.i18n.translate(
        'nonprofits:textOrganizationIsNoLongerActive',
        {},
        'This organization is no longer active in our system. You will not be able to perform certain actions. Contact our team if you believe this organization was deactivated in error.'
      ),
      buttonText: this.i18n.translate(
        'common:textLearnMore',
        {},
        'Learn more'
      ),
      onButtonClick: () => {
        window.open('https://webfiles.blackbaud.com/files/support/helpfiles/npoconnect/content/npo-administration-inactive-organizations.html');
      }
    };
  }

  setPortalBanners () {
    const banners: BannerInfo[] = [];
    const nonprofitIsNotActiveBanner = this.getCurrentNonprofitIsNotActiveBanner();

    if (this.showInactiveNpoBanner) {
      banners.push(nonprofitIsNotActiveBanner);
    }
    this.setBanners(banners);
  }
}
