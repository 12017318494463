export interface GiactCodeObjUI {
  id: number;
  value: string;
  description: string;
}

export enum GIACT_CODE_TYPE {
  VERIFICATION,
  ACCOUNT,
  CUSTOMER
}

export enum ACCEPT_REENTER_CODE {
  INVALID_ROUTING = 1,
  INVALID_ACCOUNT = 2
}

export const VERIFICATION_RESPONSE_MAP: GiactCodeObjUI[] = [
  {
    id: 0,
    value: 'Error',
    description: 'There was an error with the inquiry. Check the ErrorMessage property for details.'
  }, {
    id: 1,
    value: 'PrivateBadChecksList',
    description: 'The bank account in the inquiry was found on the API user\'s private bad check list.'
  }, {
    id: 2,
    value: 'Declined',
    description: 'The suggested action is to decline the bank account and/or customer data for this inquiry.'
  }, {
    id: 3,
    value: 'RejectItem',
    description: 'The suggested action is to not accept an item with the check number on this inquiry.'
  }, {
    id: 4,
    value: 'AcceptWithRisk',
    description: 'The suggested action is to further investigate or accept the bank account and/or customer data for this inquiry with known risk.'
  }, {
    id: 5,
    value: 'RiskAlert',
    description: 'The suggested action is to further investigate the bank account and/or customer data for this inquiry due to a risk alert .'
  }, {
    id: 6,
    value: 'Pass',
    description: 'The suggested action is to accept the bank account and/or customer data for this inquiry.'
  }, {
    id: 7,
    value: 'PassNdd',
    description: 'The bank account in the inquiry is a Non-Demand Deposit account.'
  }, {
    id: 8,
    value: 'NegativeData',
    description: 'There was negative data found associated with the account.'
  }, {
    id: 9,
    value: 'NoData',
    description: 'No data was found for the bank account and/or customer data.'
  }
];

export const ACCOUNT_RESPONSE_CODE_MAP: GiactCodeObjUI[] = [
  {
    id: 0,
    value: null,
    description: 'There is no AccountResponseCode value for this result.'
  }, {
    id: 1,
    value: 'GS01',
    description: 'Invalid Routing Number.  Please re-enter your Routing Number again.'
  }, {
    id: 2,
    value: 'GS02',
    description: 'Invalid Account Number. Please re-enter your Account Number again.'
  }, {
    id: 5,
    value: 'GP01',
    description: 'The account was found as active in your Private Bad Checks List.'
  }, {
    id: 6,
    value: 'RT00',
    description: 'The routing number belongs to a reporting bank; however, no positive nor negative information has been reported on the account number.'
  }, {
    id: 7,
    value: 'RT01',
    description: 'This account should be declined based on the risk factor being reported.'
  }, {
    id: 8,
    value: 'RT02',
    description: 'This item should be rejected based on the risk factor being reported.'
  }, {
    id: 9,
    value: 'RT03',
    description: 'Current negative data exists on this account. Accept transaction with risk. (Example: Checking or savings accounts in NSF status, recent returns, or outstanding items)'
  }, {
    id: 10,
    value: 'RT04',
    description: 'Non-Demand Deposit Account (post no debits), Credit Card Check, Line of Credit, Home Equity, or a Brokerage check.'
  }, {
    id: 11,
    value: 'RT05',
    description: 'N/A'
  }, {
    id: 12,
    value: '_1111',
    description: 'Account Verified – The account was found to be an open and valid checking account.'
  }, {
    id: 13,
    value: '_2222',
    description: 'AMEX – The account was found to be an American Express Travelers Cheque account.'
  }, {
    id: 14,
    value: '_3333',
    description: 'Non-Participant Provider – This account was reported with acceptable, positive data found in current or recent transactions.'
  }, {
    id: 15,
    value: '_5555',
    description: 'Savings Account Verified – The account was found to be an open and valid savings account.'
  }, {
    id: 19,
    value: 'GN01',
    description: 'Negative information was found in this account\'s history.'
  }, {
    id: 20,
    value: 'GN05',
    description: 'The routing number is reported as not currently assigned to a financial institution.'
  }, {
    id: 21,
    value: 'ND00',
    description: 'No positive or negative information has been reported on the account.'
  }, {
    id: 22,
    value: 'ND01',
    description: 'This routing number can only be valid for US Government financial institutions.'
  }
];

export const CUSTOMER_RESPONSE_CODE_MAP: GiactCodeObjUI[] = [
  {
    id: 0,
    value: null,
    description: 'There is no CustomerResponseCode value for this result.'
  }, {
    id: 1,
    value: 'CA01',
    description: 'Information submitted failed gAuthenticate.'
  }, {
    id: 2,
    value: 'CA11',
    description: 'Customer authentication passed gAuthenticate.'
  }, {
    id: 3,
    value: 'CA21',
    description: 'The customer or business name data did not match gAuthenticate data.'
  }, {
    id: 4,
    value: 'CA22',
    description: 'The customer\'s TaxId (SSN/ITIN) data did not match gAuthenticate data.'
  }, {
    id: 8,
    value: 'CA30',
    description: 'Multiple secondary data points did not match gAuthenticate data.'
  }, {
    id: 18,
    value: 'ND02',
    description: 'No data was found matching the customer information provided.'
  }
];


