import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { YCCommonModule } from '@yourcause/common';
import { AlertModule } from '@yourcause/common/alert';
import { BannerModule } from '@yourcause/common/banner';
import { BlockUiModule } from '@yourcause/common/block-ui';
import { ButtonModule } from '@yourcause/common/buttons';
import { CheckboxModule } from '@yourcause/common/checkbox';
import { CoreFormsModule } from '@yourcause/common/core-forms';
import { CurrencyModule } from '@yourcause/common/currency';
import { DateModule } from '@yourcause/common/date';
import { DomModule } from '@yourcause/common/dom';
import { FilesModule } from '@yourcause/common/files';
import { HTTP_REST_SERVICE } from '@yourcause/common/http';
import { I18nModule } from '@yourcause/common/i18n';
import { IconsModule } from '@yourcause/common/icons';
import { InputModule } from '@yourcause/common/input';
import { LINK_SERVICE } from '@yourcause/common/links';
import { ModalsModule } from '@yourcause/common/modals';
import { PanelModule } from '@yourcause/common/panel';
import { RadioButtonsModule } from '@yourcause/common/radio-buttons';
import { RichTextEditorModule } from '@yourcause/common/rich-text-editor';
import { SelectModule } from '@yourcause/common/select';
import { SPINNER_SERVICE } from '@yourcause/common/spinner';
import { TextAreaModule } from '@yourcause/common/textarea';
import { TourModule } from '@yourcause/common/tour';
import { TypeaheadSelectModule } from '@yourcause/common/typeahead-select';
import { UtilsModule } from '@yourcause/common/utils';
import { NgChartsModule } from 'ng2-charts';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaymentCompaniesPopoverComponent } from '../give/payment-companies-popover/payment-companies-popover.component';
import { TermsOfServiceModalComponent } from '../login/terms-of-service-modal/terms-of-service-modal.component';
import { PublicNonprofitSearchComponent } from '../nonprofits-research/public-nonprofit-search/public-nonprofit-search.component';
import { ReasonPipe } from '../reasons/reason.pipe';
import { StatusPipe } from '../statuses/status.pipe';
import { VettingApplicationDatePipe } from '../vetting-review/vetting-application-date.pipe';
import { AddressBlockComponent } from './components/address-block/address-block.component';
import { AdminNavigationComponent } from './components/admin-navigation/admin-navigation.component';
import { AuthWrapperComponent } from './components/auth-wrapper/auth-wrapper.component';
import { BankInfoAlertComponent } from './components/bank-info-alert/bank-info-alert.component';
import { ChapterAddressMatchParentModalComponent } from './components/chapter-address-match-parent-modal/chapter-address-match-parent-modal.component';
import { ClassificationComponent } from './components/classification/classification.component';
import { ConfirmNpoAdminModalComponent } from './components/confirm-npo-admin-modal/confirm-npo-admin-modal.component';
import { DesignationComponent } from './components/designation/designation.component';
import { EmailCommunicationGroupComponent } from './components/email-communication-group/email-communication-group.component';
import { ExtendedSearchDetailsComponent } from './components/extended-search-details/extended-search-details.component';
import { ListComponent } from './components/list/list.component';
import { NppNonprofitBlockTableInfoModalComponent } from './components/nonprofit-block-table-info-modal/nonprofit-block-table-info-modal.component';
import { NonprofitIRSCodesModalComponent } from './components/nonprofit-irs-codes-modal/nonprofit-irs-codes-modal.component';
import { NonprofitNotificationModalComponent } from './components/nonprofit-notification-modal/nonprofit-notification-modal.component';
import { NonprofitSearchComponent } from './components/nonprofit-search/nonprofit-search.component';
import { NpoAddressComponent } from './components/npo-address/npo-address.component';
import { NppNonprofitBlockTableComponent } from './components/npp-nonprofit-block-table/npp-nonprofit-block-table.component';
import { NppNpoStatsBlockComponent } from './components/npp-npo-stats-block/npp-npo-stats-block.component';
import { PaymentCardsComponent } from './components/payment-cards/payment-cards.component';
import { RegistrationAuthorityAttrModalComponent } from './components/registration-authority-attr-modal/registration-authority-attr-modal.component';
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';
import { RevocationDetailsByStateModalComponent } from './components/revocation-details-by-state-modal/revocation-details-by-state-modal.component';
import { SearchNpoAutocompleteComponent } from './components/search-npo-autocomplete/search-npo-autocomplete.component';
import { SearchNpoMergeComponent } from './components/search-npo-merge/search-npo-merge.component';
import { ShowChapterNonprofitsComponent } from './components/show-chapter-nonprofits/show-chapter-nonprofits.component';
import { ShowInactiveNonprofitsComponent } from './components/show-inactive-nonprofits/show-inactive-nonprofits.component';
import { TransactionFeesComponent } from './components/transaction-fees/transaction-fees.component';
import { UnAuthWrapperComponent } from './components/unauth-wrapper/unauth-wrapper.component';
import { VettingApplicationStepperComponent } from './components/vetting-application-stepper/vetting-application-stepper.component';
import { IfNonprofitRoleDirective } from './directives/if-nonprofit-role.directive';
import { IfSystemRoleDirective } from './directives/if-system-role.directive';
import { UnlessNonprofitRoleDirective } from './directives/unless-nonprofit-role.directive';
import { UnlessSystemRoleDirective } from './directives/unless-system-role.directive';
import { AuthGuard } from './guards/auth.guard';
import { ReleaseNotesGuard } from './guards/release-notes.guard';
import { RouteToSearchGuard } from './guards/route-to-search.guard';
import { UnauthGuard } from './guards/unauth.guard';
import { ActiveVettingPipe } from './pipes/active-vetting.pipe';
import { AdaptUrl } from './pipes/adapt-url.pipe';
import { AddressObjectPipe } from './pipes/address-object.pipe';
import { AlertPageIdMapPipe } from './pipes/alert-page-id-map.pipe';
import { AlternateNaText } from './pipes/alternate-na-text.pipe';
import { AssetTypeNamePipe } from './pipes/asset-type-name.pipe';
import { CalculateDaysAgoPipe } from './pipes/calculate-days-ago.pipe';
import { CarriageToBrPipe } from './pipes/carriage-to-br.pipe';
import { ChangeTrackingChangedByPipe } from './pipes/change-tracking-changed-by.pipe';
import { EntityPropertyDescPipe } from './pipes/change-tracking-entity.pipe';
import { ChangeTrackingOldNewValuePipe } from './pipes/change-tracking-old-new-value.pipe';
import { CharityCompareStatusPipe } from './pipes/charity-compare-status.pipe';
import { CheckPastDatePipe } from './pipes/check-past-date.pipe';
import { EligibleForGivingStatusIdPipe } from './pipes/eligible-for-giving-status-id.pipe';
import { EmailIcon } from './pipes/email-icon.pipe';
import { ExternalSystemsPipe } from './pipes/external-systems.pipe';
import { GenericizeStatus } from './pipes/genericize-status.pipe';
import { GiactCodePipe } from './pipes/giact-code.pipe';
import { IntBankFieldLength } from './pipes/int-bank-field-length.pipe';
import { JobIntervalFrequency } from './pipes/job-interval-frequency.pipe';
import { LocalizeBankField } from './pipes/localize-bank-field.pipe';
import { LocalizeBankHelp } from './pipes/localize-bank-help.pipe';
import { LocalizeWithNamespacePipe } from './pipes/localize-with-namespace.pipe';
import { MatchedDonorInfoPipe } from './pipes/matched-donor-info.pipe';
import { MergeComparison } from './pipes/merge-comparison.pipe';
import { NoteType } from './pipes/note-type.pipe';
import { NPOCommActionsPipe } from './pipes/npo-comm-actions.pipe';
import { NpoInactiveMessage } from './pipes/npo-inactive-message.pipe';
import { RegistrationAuthAttrs } from './pipes/registration-auth-attrs.pipe';
import { SdgLocalizedName } from './pipes/sdg-localized-name.pipe';
import { ShiftCapacity } from './pipes/shift-capacity.pipe';
import { ShowIfYc } from './pipes/show-if-yc.pipe';
import { StatusIcon } from './pipes/status-icon.pipe';
import { StringCommaSpacePipe } from './pipes/string-comma-space.pipe';
import { UserRolesPipe } from './pipes/user-roles.pipe';
import { VettingOrganizationTypePipe } from './pipes/vetting-organization-type.pipe';
import { VettingRequestStatus } from './pipes/vetting-request-status.pipe';
import { VettingRequestedBy } from './pipes/vetting-requested-by.pipe';
import { VettingTypePipe } from './pipes/vetting-type.pipe';
import { VolunteerCommunityPartnersPipe } from './pipes/volunteer-community-partners.pipe';
import { VolunteerEventActionPipe } from './pipes/volunteer-event-action.pipe';
import { VolunteerEventDate } from './pipes/volunteer-event-date.pipe';
import { EventStatus } from './pipes/volunteer-event-status.pipe';
import { VolunteerParticipantCountPipe } from './pipes/volunteer-participant-count.pipe';
import { VolunteerShiftDates } from './pipes/volunteer-shift-dates.pipe';
import { CacheBusterInterceptor } from './services/cache-buster.service';
import { HttpRestService } from './services/http-rest.service';
import { LinkService } from './services/link.service';
import { SpinnerService } from './services/spinner.service';
import { TokenInterceptor } from './services/token/token-interceptor.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { EfgDbStatusValuePipe } from './pipes/efg-db-status-value.pipe';

@NgModule({
  imports: [
    TooltipModule,
    PopoverModule,
    AccordionModule,
    BsDropdownModule,
    I18nModule,
    CoreFormsModule,
    IconsModule,
    ButtonModule,
    TourModule,
    TypeaheadSelectModule,
    DateModule,
    TextAreaModule,
    RichTextEditorModule,
    RadioButtonsModule,
    CurrencyModule,
    BlockUiModule,
    InputModule,
    FilesModule,
    SelectModule,
    CheckboxModule,
    PanelModule,
    BannerModule,
    AlertModule,
    FormsModule,
    ReactiveFormsModule,
    YCCommonModule,
    CommonModule,
    RouterModule,
    ModalsModule,
    DomModule,
    UtilsModule
  ],
  declarations: [
    AuthWrapperComponent,
    UnAuthWrapperComponent,
    IfSystemRoleDirective,
    IfNonprofitRoleDirective,
    UnlessNonprofitRoleDirective,
    UnlessSystemRoleDirective,
    TermsOfServiceModalComponent,
    AddressBlockComponent,
    BankInfoAlertComponent,
    NpoAddressComponent,
    PaymentCardsComponent,
    ListComponent,
    SearchNpoAutocompleteComponent,
    SearchNpoMergeComponent,
    NonprofitSearchComponent,
    PublicNonprofitSearchComponent,
    ShowInactiveNonprofitsComponent,
    ShowChapterNonprofitsComponent,
    ClassificationComponent,
    DesignationComponent,
    AdminNavigationComponent,
    NonprofitNotificationModalComponent,
    ConfirmNpoAdminModalComponent,
    RegistrationAuthorityAttrModalComponent,
    RevocationDetailsByStateModalComponent,
    ReleaseNotesComponent,
    ExtendedSearchDetailsComponent,
    NonprofitIRSCodesModalComponent,
    ChapterAddressMatchParentModalComponent,
    TransactionFeesComponent,
    VettingApplicationStepperComponent,
    EmailCommunicationGroupComponent,
    NppNpoStatsBlockComponent,
    NppNonprofitBlockTableComponent,
    NppNonprofitBlockTableInfoModalComponent,
    GenericizeStatus,
    ActiveVettingPipe,
    VettingRequestStatus,
    ReasonPipe,
    StatusPipe,
    ExternalSystemsPipe,
    GiactCodePipe,
    NpoInactiveMessage,
    AdaptUrl,
    UserRolesPipe,
    ShiftCapacity,
    SdgLocalizedName,
    LocalizeWithNamespacePipe,
    NoteType,
    NPOCommActionsPipe,
    CarriageToBrPipe,
    AlertPageIdMapPipe,
    StringCommaSpacePipe,
    VettingOrganizationTypePipe,
    VettingApplicationDatePipe,
    VettingRequestedBy,
    VettingTypePipe,
    MergeComparison,
    JobIntervalFrequency,
    VolunteerShiftDates,
    VolunteerEventDate,
    EmailIcon,
    IntBankFieldLength,
    LocalizeBankField,
    LocalizeBankHelp,
    ShowIfYc,
    StatusIcon,
    EventStatus,
    ChangeTrackingChangedByPipe,
    EntityPropertyDescPipe,
    ChangeTrackingOldNewValuePipe,
    CalculateDaysAgoPipe,
    VolunteerEventActionPipe,
    EligibleForGivingStatusIdPipe,
    EfgDbStatusValuePipe,
    VolunteerParticipantCountPipe,
    AssetTypeNamePipe,
    VolunteerCommunityPartnersPipe,
    CheckPastDatePipe,
    CharityCompareStatusPipe,
    RegistrationAuthAttrs,
    PaymentCompaniesPopoverComponent,
    MatchedDonorInfoPipe,
    AddressObjectPipe,
    AlternateNaText
  ],
  providers: [
    HttpClientModule,
    AuthGuard,
    UnauthGuard,
    ReleaseNotesGuard,
    RouteToSearchGuard,
    {
      provide: LINK_SERVICE,
      useClass: LinkService
    },
    {
      provide: HTTP_REST_SERVICE,
      useClass: HttpRestService
    },
    {
      provide: SPINNER_SERVICE,
      useClass: SpinnerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheBusterInterceptor,
      multi: true
    }
  ],
  exports: [
    I18nModule,
    CoreFormsModule,
    IconsModule,
    ButtonModule,
    BsDropdownModule,
    TourModule,
    TypeaheadSelectModule,
    DateModule,
    TextAreaModule,
    RichTextEditorModule,
    RadioButtonsModule,
    CurrencyModule,
    BlockUiModule,
    InputModule,
    FilesModule,
    SelectModule,
    CheckboxModule,
    PanelModule,
    BannerModule,
    AlertModule,
    CommonModule,
    YCCommonModule,
    TooltipModule,
    PopoverModule,
    UtilsModule,
    AccordionModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    IfSystemRoleDirective,
    IfNonprofitRoleDirective,
    UnlessNonprofitRoleDirective,
    UnlessSystemRoleDirective,
    HttpClientModule,
    AddressBlockComponent,
    BankInfoAlertComponent,
    NpoAddressComponent,
    PaymentCardsComponent,
    ListComponent,
    SearchNpoAutocompleteComponent,
    SearchNpoMergeComponent,
    NonprofitSearchComponent,
    PublicNonprofitSearchComponent,
    ShowInactiveNonprofitsComponent,
    ShowChapterNonprofitsComponent,
    ClassificationComponent,
    UnAuthWrapperComponent,
    AuthWrapperComponent,
    DesignationComponent,
    AdminNavigationComponent,
    ExtendedSearchDetailsComponent,
    NonprofitIRSCodesModalComponent,
    ReleaseNotesComponent,
    NonprofitNotificationModalComponent,
    ConfirmNpoAdminModalComponent,
    RegistrationAuthorityAttrModalComponent,
    RevocationDetailsByStateModalComponent,
    ChapterAddressMatchParentModalComponent,
    TransactionFeesComponent,
    VettingApplicationStepperComponent,
    EmailCommunicationGroupComponent,
    NppNpoStatsBlockComponent,
    NppNonprofitBlockTableComponent,
    NppNonprofitBlockTableInfoModalComponent,
    ShiftCapacity,
    SdgLocalizedName,
    LocalizeWithNamespacePipe,
    NoteType,
    NPOCommActionsPipe,
    CarriageToBrPipe,
    VettingRequestedBy,
    VettingTypePipe,
    AlertPageIdMapPipe,
    StringCommaSpacePipe,
    GenericizeStatus,
    ActiveVettingPipe,
    VettingRequestStatus,
    ReasonPipe,
    StatusPipe,
    ExternalSystemsPipe,
    GiactCodePipe,
    VettingOrganizationTypePipe,
    VettingApplicationDatePipe,
    NpoInactiveMessage,
    AdaptUrl,
    UserRolesPipe,
    MergeComparison,
    JobIntervalFrequency,
    VolunteerShiftDates,
    EmailIcon,
    IntBankFieldLength,
    VolunteerEventDate,
    LocalizeBankField,
    LocalizeBankHelp,
    ShowIfYc,
    StatusIcon,
    EventStatus,
    ChangeTrackingChangedByPipe,
    EntityPropertyDescPipe,
    ChangeTrackingOldNewValuePipe,
    CalculateDaysAgoPipe,
    VolunteerEventActionPipe,
    EligibleForGivingStatusIdPipe,
    EfgDbStatusValuePipe,
    VolunteerParticipantCountPipe,
    AssetTypeNamePipe,
    VolunteerCommunityPartnersPipe,
    CheckPastDatePipe,
    CharityCompareStatusPipe,
    RegistrationAuthAttrs,
    PaymentCompaniesPopoverComponent,
    MatchedDonorInfoPipe,
    AddressObjectPipe,
    AlternateNaText,
    ModalsModule,
    DomModule
  ]
})
export class YCCoreModule {}
