import { Injectable } from '@angular/core';
import { NonprofitAdminRequestCreateModel, NonprofitAdminRequestDetailModel, NonprofitAdminRequestDisplayModel, NonprofitAdminRequestUpdateModel, RegistrationAuthorityInfoModel } from '@core/models/claim.model';
import { BasicNonprofit } from '@core/models/npo.model';
import { User } from '@core/models/user.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse, APIResult, PaginationOptions, TableAsset } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class ClaimResources {
  constructor (
    private http: HttpRestService
  ) { }

  // country / my-organization
  async fetchRegistrationAuthNamesForSearch (
    countryCode: string,
    organizationType?: number
  ) {
    const endpoint = `api/NonprofitAdminRequest/GetRegistrationAuthorityInformation?CountryCode=${countryCode}&OrganizationType=${organizationType}`;

    const response = await this.http.get<APIResponse<RegistrationAuthorityInfoModel[]>>(
      endpoint
    );

    return response.data as RegistrationAuthorityInfoModel[];
  }

  // claim-page / my-nonprofits
  async cancelAdminRequest (nonprofitAdminRequestId: number) {
    return this.http.post<APIResponse>(
      'api/NonprofitAdminRequest/CancelAdminRequest',
      {
        nonprofitAdminRequestId
      }
    );
  }

  async getRequestDetail (id: number) {
    const endpoint = `api/NonprofitAdminRequest/GetRequestDetail?nonprofitAdminRequestId=${id}`;

    const response = await this.http.get<APIResponse<NonprofitAdminRequestDetailModel>>(
      endpoint,
      {}
    );

    return response.data as NonprofitAdminRequestDetailModel;
  }

  async getRequestDetailForReview (id: number) {
    const endpoint = `api/NonprofitAdminRequest/GetRequestDetailForReview?nonprofitAdminRequestId=${id}`;
    const response = await this.http.get<APIResponse<NonprofitAdminRequestDetailModel>>(
      endpoint,
      {}
    );

    return response.data as NonprofitAdminRequestDetailModel;
  }

  async removeAsset (
    nonprofitAdminRequestId: number,
    file: TableAsset,
    isUserApplying: boolean
  ) {
    const endpoint = !isUserApplying ?
      `api/NonprofitAdminRequest/RemoveAssetForReview` :
      `api/NonprofitAdminRequest/RemoveAsset`;

    return this.http.post<APIResponse>(
      endpoint,
      {
        assetId: file.fileId,
        nonprofitAdminRequestId
      }
    );
  }

  async updateAdminRequest (
    payload: NonprofitAdminRequestUpdateModel
  ) {
    const response = await this.http.post<APIResponse<NonprofitAdminRequestDisplayModel>>(
      'api/NonprofitAdminRequest/UpdateAdminRequest',
      payload
    );

    return response.data as NonprofitAdminRequestDisplayModel;
  }


  async createAdminRequest (
    payload: NonprofitAdminRequestCreateModel
  ) {
    const response = await  this.http.post<APIResponse<NonprofitAdminRequestDisplayModel>>(
      'api/NonprofitAdminRequest/CreateAdminRequest',
      payload
    );

    return response.data as NonprofitAdminRequestDisplayModel;
  }

  async getNonprofitAdmins (id: string) {
    const endpoint = `api/NonprofitAdminRequest/GetAdmins?nonprofitId=${id}`;

    const response = await this.http.get<APIResponse<User[]>>(
      endpoint
    );

    return response.data as User[];
  }

  async getClaimChapters (
    parentNonprofitId: number,
    paginationOptions: PaginationOptions<BasicNonprofit>,
    stateProvRegId?: number
  ) {
    return this.http.post<APIResult<BasicNonprofit>>(
      'api/NonprofitChapter/GetChapters',
      {
        paginationOptions,
        parentNonprofitId,
        stateProvRegId
      }
    );
  }
}
