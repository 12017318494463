import { Pipe, PipeTransform } from '@angular/core';
import { ChangeTrackingEntityType } from '@core/models/change-tracking.model';
import { UserCommunicationPreference } from '@core/models/communication.model';
import { InflectService } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { StatusService } from '../../statuses/status.service';
import { ArrayHelpersService } from '@yourcause/common/utils';

@Pipe({
  name: 'nppChangeTrackingOldNewValue'
})
export class ChangeTrackingOldNewValuePipe implements PipeTransform {
  eligibilityStatuses = this.statusService.eligibleForGivingStatusDisplay;
  textNotSet = this.i18n.translate(
    'bankInfo:textNotSet',
    {},
    'Not set'
  );
  noneText = this.i18n.translate(
    'common:textNone',
    {},
    'None'
  );
  categoryText = this.i18n.translate(
    'common:textCategory',
    {},
    'Category'
  );
  groupText = this.i18n.translate(
    'common:textGroup',
    {},
    'Group'
  );
  nteeText = 'NTEE';
  notApplicableText = this.i18n.translate(
    'common:textNotApplicableNA',
    {},
    'N/A'
  );

  constructor (
    private i18n: I18nService,
    private statusService: StatusService,
    private inflect: InflectService,
    private arrayHelper: ArrayHelpersService
  ) { }

  transform (
    change: string,
    type: ChangeTrackingEntityType
  ): string {
    let returnVal = '';
    if (!!change) {
      switch (type) {
        case ChangeTrackingEntityType.EligibleForGiving:
          returnVal = this.getEligibleForGivingChange(change);
          break;
        case ChangeTrackingEntityType.DisbursementSuspended:
          returnVal = this.getDisbursementSuspendedChange(change);
          break;
        case ChangeTrackingEntityType.BankInfo:
          returnVal = this.getBankInfoChange(change);
          break;
        case ChangeTrackingEntityType.ParentChildRel:
          returnVal = change.substring(change.indexOf(':') + 1);
          break;
        case ChangeTrackingEntityType.Classification:
          returnVal = this.getClassificationChange(change);
          break;
        case ChangeTrackingEntityType.EmailCommunicationPreferences:
          returnVal = this.getCommPreferencesChange(change);
          break;
        default:
          if (['true', 'false'].includes(change.toLowerCase())) {
            returnVal = change.toLowerCase() === 'true' ?
              this.i18n.translate(
                'common:textYes',
                {},
                'Yes'
              ) :
              this.i18n.translate(
                'common:textNo',
                {},
                'No'
              );
          } else {
            returnVal = change[0].toUpperCase() + change.substring(1);
          }
      }
    } else {
      returnVal = this.noneText;
    }

    return returnVal;
  }

  getEligibleForGivingChange (change: string): string {
    let returnVal = '';
    const status = this.eligibilityStatuses?.find((s) => {
      return s.id === +change;
    });
    returnVal = this.i18n.translate(
      status.i18nKey,
      {},
      status.value
    );

    return returnVal;
  }

  getDisbursementSuspendedChange (change: string): string {
    const returnVal = change.toLowerCase() === 'false' ?
    this.i18n.translate(
      'common:textYes',
      {},
      'Yes'
    ) :
    this.i18n.translate(
      'common:textNo',
      {},
      'No'
    );

    return returnVal;
  }

  getBankInfoChange (change: string): string {
    let returnVal = '';
    const list = JSON.parse(change);
    Object.entries(list).forEach(([fieldName, val]) => {
      val = !!val ? val : this.textNotSet;
      fieldName = this.i18n.translate(
        `bankInfo:lbl${fieldName}`,
        {},
        fieldName.replace(/([A-Z])/g, ' $1')
      );
      returnVal += fieldName + ': ' + val + '<br>';
    });

    return returnVal;
  }

  getCommPreferencesChange (change: string): string {
    let returnVal = '';
    let commPrefValue = '';
    const allComms = this.arrayHelper.sort(
      Object.entries(UserCommunicationPreference)
      .map(([key, value]) => {
        return value;
      })
    );
    const parsedChange = JSON.parse(change);
    const valueArray = Object.entries(parsedChange)
      .map(([key, value]) => {
        return value;
      }) as string[];
    const unsubscribedComms = valueArray[0].split(', ');
    allComms.forEach((comm) => {
      if (unsubscribedComms.includes(comm)) {
        commPrefValue = this.i18n.translate(
          'common:textNo',
          {},
          'No'
        );
      } else {
        commPrefValue = this.i18n.translate(
          'common:textYes',
          {},
          'Yes'
        );
      }
      returnVal += comm + ': ' + commPrefValue + '<br>';
    });

    return returnVal;
  }

  getClassificationChange (change: string): string {
    let returnVal = '';
    const values = JSON.parse(change);
    const categoryVal = values.CATEGORY;
    const groupVal = values.GROUP;
    const nteeVal = values.NTEE;
    const categoryTranslated = categoryVal ?
      this.i18n.translate(
        `nonprofits:text${this.inflect.trimPuncAndPascalize(categoryVal)}`,
        {},
        categoryVal
      ) :
      this.notApplicableText;
    const groupTranslated = groupVal ?
      this.i18n.translate(
        `nonprofits:text${this.inflect.trimPuncAndPascalize(groupVal)}`,
        {},
        groupVal
      ) :
      this.notApplicableText;
    const nteeTranslated = nteeVal ?
      this.i18n.translate(
        `nonprofits:text${this.inflect.trimPuncAndPascalize(nteeVal)}`,
        {},
        nteeVal
      ) :
      this.notApplicableText;
    returnVal = '' + this.categoryText + ': ' + categoryTranslated +
      '<br><small>' +
      this.groupText + ': ' + groupTranslated +
      '<br>' +
      this.nteeText + ': ' + nteeTranslated +
      '</small>';

    return returnVal;
  }
}
