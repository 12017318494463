import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';
import { VolunteerEventService } from '../../volunteer/services/volunteer-event.service';


@Pipe({
  name: 'nppVolunteerPartners'
})
export class VolunteerCommunityPartnersPipe implements PipeTransform {
  communityPartners = this.volunteerEventService.communityPartners;
  allCommunitiesLocalized = this.i18n.translate(
    'volunteer:lblAllCommunities',
    {},
    'All communities'
  );
  constructor (
    private volunteerEventService: VolunteerEventService,
    private i18n: I18nService
  ) { }

  transform (partnerId: number): string {
    if (!!partnerId) {
      return this.communityPartners?.find((partner) => {
        return partner.affiliateId === partnerId;
      })?.name;
    } else {
      return this.allCommunitiesLocalized;
    }
  }
}
