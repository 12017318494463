import { Pipe, PipeTransform } from '@angular/core';
import { EXTERNAL_SYSTEM_MAP_BY_ID } from '@core/models/donation.model';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'nppExternalSystemPipe'
})
export class ExternalSystemsPipe implements PipeTransform {
  internalRequestText = this.i18n.translate(
    'vetting:textInternalRequest',
    {},
    'Internal request'
  );

  constructor (
    private i18n: I18nService
  ) { }
  transform (
    value: number,
    specialCase?: string
  ): string {
    let name: string;
    if (value) {
      name = EXTERNAL_SYSTEM_MAP_BY_ID[value as keyof typeof EXTERNAL_SYSTEM_MAP_BY_ID];
    } else {
      if (specialCase === 'vettingRequest') {
        name = this.internalRequestText;
      }
    }

    return name;
  }
}
