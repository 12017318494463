import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nppIntBankFieldLength'
})
export class IntBankFieldLength implements PipeTransform {

  transform (countryCode: string, fieldName: string): any {
    const country = countryCode.toLowerCase();
    const field = fieldName.toLowerCase();

    return country === 'us' ? null : this.setMaxLength(field);
  }
  setMaxLength (fieldName: string) {
    switch (fieldName) {
      case 'bankname':
      case 'bankaddress':
      case 'accountname':
        return 105;
      case 'benefistreetaddress':
      case 'beneficity':
      case 'benefiphonenumber':
      case 'beneficontactname':
        return 60;
      default:
        return null;
    }
  }
}
