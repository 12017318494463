import { Injectable } from '@angular/core';
import { FeatureFlagModel, FeatureFlagType, UpdateFeatureFLagPayload } from '@core/typings/feature-flag.typing';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { FeatureFlagResources } from './feature-flag.resources';
import { FeatureFlagState } from './feature-flag.state';

@AttachYCState(FeatureFlagState)
@Injectable({ providedIn: 'root' })
export class FeatureFlagService extends BaseYCService<FeatureFlagState>{
  constructor (
    private featureFlagResources: FeatureFlagResources,
    private notifier: NotifierService,
    private i18n: I18nService
  ) {
    super();
  }

  get featureFlags (): FeatureFlagModel[] {
    return this.get('featureFlags');
  }

  get programsFlagValue () {
    return this.get('programsFlagValue');
  }

  get blackbaudIdFlagValue () {
    return this.get('bbidFlagValue');
  }

  get liveChatFlagValue () {
    return this.get('liveChatFlagValue');
  }

  get populationServedFlagValue () {
    return this.get('populationServedFlagValue');
  }

  getFeatureFlagEnabledValue (
    featureFlagType: FeatureFlagType
  ): boolean {
    if (this.featureFlags?.length > 0) {
      const flag = this.featureFlags.find((type) => type.name === featureFlagType);

      return flag?.enabled;
    }

    return false;
  }

  setProgramsFlagValue (val: boolean) {
    this.set('programsFlagValue', val);
  }

  setBlackbaudIdFlagValue (val: boolean) {
    this.set('bbidFlagValue', val);
  }

  setLiveChatFlagValue (val: boolean) {
    this.set('liveChatFlagValue', val);
  }

  setPopulationServedFlagValue (val: boolean) {
    this.set('populationServedFlagValue', val);
  }

  async setAllFlags (flags: FeatureFlagModel[]) {
    const programFlag = flags?.find((flag) => {
      return flag.name === FeatureFlagType.Programs;
    });

    const bbidFlag = flags?.find((flag) => {
      return flag.name === FeatureFlagType.BlackbaudId;
    });

    const liveChatFlag = flags?.find((flag) => {
      return flag.name === FeatureFlagType.LiveChat;
    });

    const populationServedFlag = flags?.find((flag) => {
      return flag.name === FeatureFlagType.PopulationServed;
    });

    return Promise.all([
      this.set('featureFlags', flags),
      this.setProgramsFlagValue(programFlag?.enabled || false),
      this.setBlackbaudIdFlagValue(bbidFlag?.enabled || false),
      this.setLiveChatFlagValue(liveChatFlag?.enabled || false),
      this.setPopulationServedFlagValue(populationServedFlag?.enabled || false)
    ]);
  }

  // APIs
  async getAllFeatureFlagsLookup () {
    const response = await this.featureFlagResources.getFeatureFlags();
    await this.setAllFlags(response.data);
  }

  async updateFeatureFlagStatus (
    payload: UpdateFeatureFLagPayload
  ) {
    try {
      await this.featureFlagResources.updateFeatureFlagStatus(payload);
      this.notifier.success(this.i18n.translate(
        'platformAdmin:notificationSuccessUpdatingFeatureFlag',
        {},
        'Successfully updated Feature Flag'
      ));
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'platformAdmin:notificationErrorUpdatingFeatureFlag',
        {},
        'There was an error updating the Feature Flag'
      ));
    }
  }
}
