import { Pipe, PipeTransform } from '@angular/core';
import { AssetType, InflectService } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ClaimService } from '../../claim/services/claim.service';

@Pipe({
  name: 'nppAssetTypeName'
})

export class AssetTypeNamePipe implements PipeTransform {
  assetTypes = this.claimService.claimsAssetTypes;

  constructor (
    private i18n: I18nService,
    private inflect: InflectService,
    private claimService: ClaimService
  ) { }

  transform (id: number): string {
    const found: AssetType = (this.assetTypes || [])
      .find((type) => +type.assetTypeId === +id);

    if (found) {
      return this.i18n.translate(
        `common:text${this.inflect.pascalize(found.name)}`,
        {},
        found.name
      );
    }

    return '';
  }
}
