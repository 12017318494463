import { Pipe, PipeTransform } from '@angular/core';
import { addDays, set } from 'date-fns';
import { VolunteerEvent, VolunteerShift } from '../models/volunteer-event.model';
import { DATE_TIME_FORMAT, DateService } from '@yourcause/common/date';

@Pipe({
  name: 'nppVolunteerShiftDates'
})
export class VolunteerShiftDates implements PipeTransform {
  constructor (
    private dateService: DateService
  ) { }

  transform (
    shift: VolunteerShift,
    event: VolunteerEvent,
    context: 'shiftStart'|'shiftEnd'
  ): any {
    let returnDate;
    switch (context) {
      case 'shiftStart':
        const startDate = addDays(
          set(new Date(event.originalStartDate), {
            hours: shift.startHours,
            minutes: shift.startMinutes
          }),
          shift.startDay - 1
        );
        returnDate = this.dateService.formatDate(startDate, DATE_TIME_FORMAT);
        break;
      case 'shiftEnd':
        const endDate = addDays(
          set(new Date(event.originalStartDate), {
            hours: shift.endHours,
            minutes: shift.endMinutes
          }),
          shift.startDay + shift.numberOfDays - 2
        );
        returnDate = this.dateService.formatDate(endDate, DATE_TIME_FORMAT);
        break;
      }

    return returnDate;
  }
}
