import { DashboardChartAllDetail, DataArray, DonationsBySourcePayload, SelectedDates } from '@core/models/dashboard.model';
import { CACHE_TYPES, CachedAttr } from '@yourcause/common/cache';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class DashboardState extends BaseYcState {
  @CachedAttr<SelectedDates>(CACHE_TYPES.LOCALSTORAGE)
  readonly dashboardDateRange: SelectedDates;

  readonly donationsByCompany: DashboardChartAllDetail;
  readonly donationsByFundingSource: DataArray[];
  readonly donationsByFundingSourceDates: string[];
  readonly chartStartDate: Date;
  readonly chartEndDate: Date;
  readonly defaultDonationsBySourcePayload: DonationsBySourcePayload;
}

