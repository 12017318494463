<ng-container>
  <ng-template #companiesList>
    <div *ngFor="let company of companiesForDisplay">
      <div class="left-side">
        {{ company }}
      </div>
    </div>
  </ng-template>

  <button #numberOfCompaniesLink="bs-popover"
    class="btn btn-link p-0 text-left"
    [placement]="'right'"
    [popover]="companiesList"
    [popoverContext]="{
      numberOfCompaniesLink: numberOfCompaniesLink
    }"
    [outsideClick]="true">
    <yc-i18n class="font-size-14"
      key="payments:textNumberOfCompanies"
      defaultValue="__num__ Companies"
      [context]="{'num': companies?.length}">
    </yc-i18n>
  </button>
</ng-container>
