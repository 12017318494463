import { Pipe, PipeTransform } from '@angular/core';
import { ChangeTrackingChangeByRole, ChangeTrackingChangedBySystemName } from '@core/models/change-tracking.model';
import { AuthService } from '@core/services/auth.service';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'nppChangeTrackingChangedBy'
})
export class ChangeTrackingChangedByPipe implements PipeTransform {
  ycUserString = this.i18n.translate(
    'common:textYcUser',
    {},
    'YourCause User'
  );
  systemString = this.i18n.translate(
    'common:textSystem'
  );
  nonprofitUserString = this.i18n.translate(
    'common:textNonprofitUser',
    {},
    'Nonprofit User'
  );

  constructor (
    private i18n: I18nService,
    private authService: AuthService
  ) {}


  transform (changedByRole: string, changedBy: string): string {
    switch (changedByRole) {
      case ChangeTrackingChangeByRole.YCUser:
        if (changedBy === ChangeTrackingChangedBySystemName.NPPAutomation ||
          changedBy === ChangeTrackingChangedBySystemName.SiteAdmin) {
            return changedBy;
        } else {
          if (this.authService.isYcAdminOrSupport()) {
            return changedBy +
              `<br>` +
              this.ycUserString;
          } else {
            return this.ycUserString;
          }
        }
      case ChangeTrackingChangeByRole.NPOUser:
        return changedBy +
          `<br>` +
          this.nonprofitUserString;
      case ChangeTrackingChangeByRole.System:
      default:
        return this.systemString;
    }
  }
}
