import { PaymentDetailsApi } from '@core/models/payment.model';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { MatchRequestSummary, PendingDisbursementSummary } from '../models/finance.model';


@RegisterYCState()
export class FinanceState extends BaseYcState {
  readonly pendingDisbursements: PendingDisbursementSummary;
  readonly transactionsPending: number;
  readonly amountPending: number;
  readonly matchRequests: MatchRequestSummary;
  readonly paymentId: number;
  readonly paymentNumber: string;
  readonly paymentDetailsForTransactions: PaymentDetailsApi;
  readonly transactionsStatus: string;
  readonly paymentDateRangeAllTime: Date[];
  readonly errorState: boolean;
}

