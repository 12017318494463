import { Pipe, PipeTransform } from '@angular/core';
import { EligibilityStatus } from '@core/models/status.model';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'nppEfgDbStatusValuePipe'
})
export class EfgDbStatusValuePipe implements PipeTransform {
  constructor (
    private i18n: I18nService
  ) {}

  // values taken directly from backend status table
  transform (eligibleForGivingStatusId: number): string {
    switch (eligibleForGivingStatusId) {
      // id: 10
      case EligibilityStatus.AwaitingReview:
        return this.i18n.translate(
          'common:lblVettingInProgress',
          {},
          'Vetting in Progress'
        );
      // id: 11
      case EligibilityStatus.YesEligible:
        return this.i18n.translate(
          'common:textEligible',
          {},
          'Eligible'
        );
      // id: 13
      case EligibilityStatus.NeverEligible:
        return this.i18n.translate(
          'common:textIneligible',
          {},
          'Ineligible'
        );
      default:
      // id: 12
      case EligibilityStatus.MaybeEligible:
        return this.i18n.translate(
          'common:textInfoRequired',
          {},
          'Info Required'
        );
    };
  }
}
