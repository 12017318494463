<yc-modal *ngIf="modalLoaded"
  [modalHeader]="modalHeader"
  [cancelButtonText]="'common:btnClose' | ycI18n: 'Close'"
  (onCancel)="_onCancel()">
  <div class="table-wrapper">
    <div class="yc-layout-table">
      <yc-table
        [rows]="rows"
        [simple]="true"
        [hideTableFilters]="true"
        [hideTableOptions]="true"
        [hidePagination]="true"
        [rowsPerPage]="1000">
        <ng-container *ycTableColumn="'nonprofits:hdrCode';
          let row;
          prop: 'code',
          defaultSortDirection: codeType === CodeType.BMFFoundation ||
            codeType === CodeType.BMFSubsection ?
              'desc' :
              'asc'">
            {{ row.code }}
        </ng-container>
        <ng-container *ycTableColumn="'platformAdmin:hdrDescription';
          let row;
          prop: 'description'">
          <div class="col-description">
            {{ row.description }}
          </div>
        </ng-container>
        <ng-container *ycTableColumn="'nonprofits:textIsBbgfEligible';
          let row;
          prop: 'bbgfEligible'">
          {{ row.bbgfEligible }}
        </ng-container>
      </yc-table>
    </div>
  </div>
</yc-modal>