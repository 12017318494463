<ng-container *ngIf="ready">
  <yc-modal
    [simple]="true"
    [modalHeader]="modalHeader"
    [modalSubHeader]="userNameEmail"
    [primaryButtonText]="primaryButtonText"
    [cancelButtonText]="cancelButtonText"
    [primaryDisabled]="disablePrimaryButton"
    (onCancel)="onCancel()"
    (onPrimaryClick)="onPrimaryClick()">
    <div class="font-size-875">
      {{ modalBody }}
      <span *ngIf="this.userAccessRemovedDate">
        <br><br>
        {{ modalBody_p_2 }}
        <br><br>
        {{ modalBody_p_3 }}
      </span>
    </div>

    <div *ngIf="confirmForm"
      [formGroup]="confirmForm"
      class="mt-4">
      <div *ngIf="npoOptions.length"
        class="checkbox-container justify-content-center">
        <div class="flex-grow-1">
          <yc-checkbox-group
            name="selectNpos"
            [options]="npoDisplayOptions"
            [useCdkVirtualScroll]="true"
            [minHeightOfViewport]="'330px'"
            [maxHeightOfViewport]="'430px'"
            [centerVertical]="true"
            [showSelectAllOptions]="npoDisplayOptions.length > 1"
            (onChange)="getNposToRemove($event)">
          </yc-checkbox-group>
        </div>
      </div>

      <yc-checkbox
        name="confirmNotSelecting"
        [optionLabel]="checkboxText"
        [centerLabel]="true">
      </yc-checkbox>
    </div>
  </yc-modal>
</ng-container>