import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CACHE_TYPES, CachedAttr } from '@yourcause/common/cache';
import { I18nService } from '@yourcause/common/i18n';

@Component({
  selector: 'yc-show-inactive-nonprofits',
  templateUrl: './show-inactive-nonprofits.component.html'
})
export class ShowInactiveNonprofitsComponent implements OnInit {
  @CachedAttr(CACHE_TYPES.LOCALSTORAGE, true)
  showInactiveNpos: boolean;
  @Input() parentFormGroup: UntypedFormGroup;
  @Output() changeType = new EventEmitter();
  showInactiveForm: UntypedFormGroup;
  showInactiveText = this.i18n.translate(
    'search:lblShowInactiveNonprofitsCheckbox',
    {},
    'Show inactive nonprofits'
  );

  constructor (
    private i18n: I18nService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit () {
    this.showInactiveForm = this.formBuilder.group({
      showInactive: this.showInactiveNpos
    });
    this.parentFormGroup.get('showInactive').setValue(this.showInactiveNpos);
  }

  _changeMade () {
    this.showInactiveNpos = this.showInactiveForm.get('showInactive').value;
    this.parentFormGroup.get('showInactive').setValue(this.showInactiveNpos);
    this.changeType.emit();
  }

}
