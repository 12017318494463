import { Country, RegionsByCountry, StateProvReg } from '@core/models/location.model';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class LocationState extends BaseYcState {
  readonly countries: Country[];
  readonly regions: StateProvReg[];
  readonly regionsByCountry: RegionsByCountry;
  readonly regionsForSelect: TypeaheadSelectOption[];
  readonly countriesForSelect: TypeaheadSelectOption[];
  readonly countryCurrencyMap: {
    [x: string]: string;
  };
}
