import { User } from '@core/models/user.model';
import { YcFile } from '@yourcause/common/files';

export namespace NotesAPI {
  export interface NoteBase {
    noteType: NoteType;
    note: string;
    assetId?: string;
    emailAddresses?: string;
  }

  export interface NoteAssetMap {
    [assetId: string]: YcFile;
  }

  export interface NoteDisplay extends NoteBase {
    id?: number;
    nonprofitId?: number;
    lastModifiedDate?: string;
    createdDate?: string;
    createdByUser?: User;
    modifiedByUser?: User;
    createdBy?: string;
  }

  export interface NoteModalPayload extends NoteBase {
    id?: number;
    nonprofitId: number;
    file?: YcFile;
    assetId?: string;
  }

  export enum NoteType {
    GENERAL = 1,
    OUTREACH_EMAIL = 2,
    VETTING = 3,
    VETTING_REVISION_REQUESTED = 4
  }
}
