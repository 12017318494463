<yc-modal *ngIf="addressReasonForm"
  [formGroup]="addressReasonForm"
  [modalHeader]="modalHeader"
  [cancelButtonText]="cancelButtonText"
  [primaryButtonText]="primaryButtonText"
  [primaryDisabled]="addressReasonForm.invalid"
  (onCancel)="_onCancel()"
  (onPrimaryClick)="_onSubmit()">

  <div *ngIf="!reasonForMatch"
    class="mb-4">
    {{ modalBody }}
  </div>
  <div class="d-flex flex-row mb-4"
    [class.justify-content-between]="parentDisplayAddress"
    [class.justify-content-around]="!parentDisplayAddress">
    <div>
      <div class="address-title">
        <yc-i18n
          key="common:textChapterDisplayAddress"
          defaultValue="Chapter Display Address">
        </yc-i18n>
      </div>
      <div class="font-size-875">
        <yc-npo-address
          [address]="chapterAddress">
        </yc-npo-address>
      </div>
    </div>
    <div *ngIf="parentDisplayAddress">
      <div class="address-title">
        <yc-i18n
          key="common:textParentDisplayAddress"
          defaultValue="Parent Display Address">
        </yc-i18n>
      </div>
      <div class="font-size-875">
        <yc-npo-address
          [address]="parentDisplayAddress">
        </yc-npo-address>
      </div>
    </div>
    <div *ngIf="parentRegisteredAddress">
      <div class="address-title">
        <yc-i18n
          key="common:textParentRegisteredAddress"
          defaultValue="Parent Registered Address">
        </yc-i18n>
      </div>
      <div class="font-size-875">
        <yc-npo-address
          [address]="parentRegisteredAddress">
        </yc-npo-address>
      </div>
    </div>
  </div>
  <div>
    <yc-textarea
      name="reasonForAddressMatch"
      [label]="reasonLabel"
      [maxLength]="500"
      [disabled]="!!reasonForMatch"
      [addRequiredAsterisk]="!reasonForMatch">
    </yc-textarea>
  </div>
</yc-modal>