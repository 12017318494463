import { Pipe, PipeTransform } from '@angular/core';
import { InflectService } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'nppLocalizedWithNameSpace'
})
export class LocalizeWithNamespacePipe implements PipeTransform {

  constructor (
    private i18n: I18nService,
    private inflect: InflectService
  ) { }

  transform (
    textForTranslation: string,
    namespace: string
  ): string {
    return this.i18n.translate(
      `${namespace}:text${this.inflect.trimPuncAndPascalize(textForTranslation)}`,
      {},
      textForTranslation
    );
  }
}
