import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nppEmailIcon'
})
export class EmailIcon implements PipeTransform {

  transform (value: any): any {
    const iconType = value.toLowerCase();

    return '/assets/img/email-icons/' + iconType + '.png' ||  '/assets/img/email-icons/heart.png';
  }
}
