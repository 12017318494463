import { Pipe, PipeTransform } from '@angular/core';
import { Communications } from '@yourcause/common';

@Pipe({
  name: 'npoCanPerformCommAction'
})
export class NPOCommActionsPipe implements PipeTransform {

  transform (status: Communications.CommunicationStatuses, action: 'EDIT'|'SEND'|'DELETE'|'STAGE'): boolean {
    const nonEditableStatuses = [
      Communications.CommunicationStatuses.SENT
    ];
    const sendableStatuses = [
      Communications.CommunicationStatuses.READY_TO_BE_SENT
    ];
    const nonDeletableStatuses = [
      Communications.CommunicationStatuses.SENT,
      Communications.CommunicationStatuses.COMPLETED
    ];
    const nonStageableStatuses = [
      Communications.CommunicationStatuses.SENT
    ];
    switch (action) {
      case ('EDIT'):
        return !nonEditableStatuses.includes(status);
      case ('SEND'):
        return sendableStatuses.includes(status);
      case ('STAGE'):
        return !nonStageableStatuses.includes(status);
      default:
      case ('DELETE'):
        return !nonDeletableStatuses.includes(status);
    }
  }
}
