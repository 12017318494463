import { Component, Input, OnInit } from '@angular/core';
import { SystemHealthDashboardDetails, SystemHealthDashboardStats } from '@core/typings/system-health-dashboard.typing';
import { YCModalComponent } from '@yourcause/common/modals';
import { SystemHealthDashboardService } from '../system-health-dashboard.service';

@Component({
  selector: 'npp-system-health-dashboard-modal',
  templateUrl: './system-health-dashboard-modal.component.html',
  styleUrls: ['./system-health-dashboard-modal.component.scss']
})
export class SystemHealthDashboardDetailsModalComponent extends YCModalComponent<void> implements OnInit {
  @Input() healthIndicatorStats: SystemHealthDashboardStats;
  healthIndicatorDetails: SystemHealthDashboardDetails;
  details: any[];
  headers: string[] = [];

  constructor (
    private systemHealthDashboardService: SystemHealthDashboardService
  ) {
    super();
  }

  async ngOnInit () {
    this.healthIndicatorDetails = (
      await this.fetchHealthIndicatorDetails(
        this.healthIndicatorStats.healthIndicatorId
      )
    ).endpointResponse;
    this.details = JSON.parse(this.healthIndicatorDetails.healthIndicatorResults);
    this.details.forEach((value) => {
      Object.keys(value).forEach((key) => {
        if (!this.headers.find((header) => header === key)) {
          this.headers.push(key);
        }
      });
    });
  }

  async fetchHealthIndicatorDetails (healthIndicatorId: number) {
    return await this.systemHealthDashboardService.getSystemHealthDashboardDetails(
      healthIndicatorId
    );
  }
}
