import { Directive, TemplateRef, ViewContainerRef, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[ycIfNonprofitRole]'
})
export class IfNonprofitRoleDirective implements OnInit {
  @Input() ycIfNonprofitRole: string;
  constructor (
    public templateRef: TemplateRef<any>,
    public viewRef: ViewContainerRef,
    public roleService: AuthService
  ) {
  }

  ngOnInit () {
    if (this.roleService.hasNpoRole(this.ycIfNonprofitRole)) {
      this.viewRef.createEmbeddedView(this.templateRef, {}, 0);
    }
  }

}
