import { Injectable } from '@angular/core';
import { CheckInfoPayload } from '@core/models/claim.model';
import { StateProvReg } from '@core/models/location.model';
import { CodeType } from '@core/models/nonprofit-irs-code.model';
import { BasicNonprofit, CreateNonprofitApi, CreateNonprofitApiResponse, DisplayPayload, LogoPayload, Nonprofit, NonprofitRegAuthAttributes, RemittancePayload, UpdateNPOClassificationPayload } from '@core/models/npo.model';
import { SpinnerService } from '@core/services/spinner.service';
import { TokenService } from '@core/services/token/token.service';
import { Address, AddressFormatterService, AddressType, NonprofitSummaryInfoScenario, PaginationOptions } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { AssetManagementService } from '../asset-management/services/asset-management.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { LocationService } from '../location/location.service';
import { NotesService } from '../notes/notes.service';
import { NonprofitResources } from './nonprofit.resources';
import { NonprofitState } from './nonprofit.state';
import { RevocationDetailsBySubdivision } from '@core/models/revocation.model';

@AttachYCState(NonprofitState)
@Injectable({ providedIn: 'root' })
export class NonprofitService extends BaseYCService<NonprofitState> {

  constructor (
    private nonprofitResources: NonprofitResources,
    private notifier: NotifierService,
    private i18n: I18nService,
    private assetManagementService: AssetManagementService,
    private formatter: AddressFormatterService,
    private locationService: LocationService,
    private notesService: NotesService,
    private spinnerService: SpinnerService,
    private tokenService: TokenService,
    private dashboardService: DashboardService
  ) {
    super();
  }

  get selectedNpo (): number {
    return this.get('selectedNpo');
  }

  get activeNpo (): Nonprofit {
    return this.get('activeNpo');
  }

  get myNpos (): BasicNonprofit[] {
    return this.get('myNpos');
  }

  get activeNpoStateData (): Nonprofit {
    return this.get('activeNpoStateData');
  }

  get placeholder () {
    return this.get('placeholder');
  }

  async setSelectedNpo (
    selectedNpoId: number,
    skipToastr = false
  ) {
    this.set('selectedNpo', selectedNpoId);
    if (!!selectedNpoId) {
      await this.setActiveNonprofit(
        selectedNpoId,
        skipToastr
      );
    }
  }

  async setActiveNonprofit (
    nonprofitId: number,
    skipToastr = false
  ) {
    this.spinnerService.startSpinner();
    const nonprofit = await this.getNonprofit(nonprofitId);
    const adaptedNonprofit = this.npoAdapter(nonprofit);
    this.set('activeNpo', adaptedNonprofit);
    this.setActiveNpoStateData(adaptedNonprofit);
    this.tokenService.setActiveNpoId(nonprofitId);
    this.dashboardService.clearDataOnNonprofitChange();
    if (!skipToastr) {
      this.notifier.success(this.i18n.translate(
        'common:notificationYouAreAdministeringNonprofit',
        {
          orgName: adaptedNonprofit.name
        },
        'You are now administering __orgName__'
      ));
    }
    this.spinnerService.stopSpinner();
  }

  async setMyNpos (npos: BasicNonprofit[]) {
    const nonprofits = await this.getNonprofitsForAdmin();
    this.set('myNpos', nonprofits);
  }

  setActiveNpoStateData (attrs: any) {
    this.set(
      'activeNpoStateData',
      {
        ...this.activeNpo,
        ...attrs
      }
    );
  }

  setNonprofitLogo (logoUrl: string) {
    this.set('nonprofitLogo', logoUrl);
  }

  addChapterToMypNos (newChapter: BasicNonprofit) {
    let myNpos = this.myNpos;
    if (myNpos?.length > 0) {
      myNpos = [
        ...this.myNpos,
        newChapter
      ];
    } else {
      myNpos = [newChapter];
    }
    this.setMyNpos(myNpos);
  }

  formatDisplayPayload (displayPayload: DisplayPayload) {
    if (typeof displayPayload?.tags === 'object') {
      const tagArray = [] as string[];
      let tagsString = '';
      let tagItem = '';
      displayPayload.tags.forEach((tag) => {
        if (typeof tag === 'string') {
          tagItem = tag as string;
          tagItem = tagItem.replace(/["#]/g, '').trim();
          tagArray.push(tagItem);
        } else {
          tagItem = tag.value;
          tagItem = tagItem.replace(/["#]/g, '').trim();
          tagArray.push(tagItem);
        }
      });
      tagsString = tagArray.join(',');
      displayPayload.tags = tagsString as any;
    }

    return displayPayload;
  }

  npoAdapter (nonprofit: Nonprofit) {
    nonprofit.tags = this.tagsAdapter(nonprofit) || [];
    nonprofit.displayAddress = this.displayAddressAdapter(nonprofit);
    nonprofit.displayName = nonprofit.displayName || nonprofit.name;
    this.notesService.setNoteCount(nonprofit.notesCount);

    return nonprofit;
  }

  displayAddressAdapter (nonprofit: Nonprofit) {
    if (!nonprofit.displayAddress || !nonprofit.displayAddress.address1) {
      if (nonprofit.address) {
        nonprofit.displayAddress = nonprofit.address;
      } else if (nonprofit.isChapterAffiliate && nonprofit.parentNonprofit) {
        if (nonprofit.parentNonprofit.displayAddress) {
          nonprofit.displayAddress = nonprofit.parentNonprofit.displayAddress;
        } else if (nonprofit.parentNonprofit.address) {
          nonprofit.displayAddress = nonprofit.parentNonprofit.address;
        }
      }
    }

    return nonprofit.displayAddress;
  }

  tagsAdapter (
    nonprofit?: Nonprofit|DisplayPayload,
    nonprofitTags?: string[]
  ) {
    let tagsArray: string[] = [];
    if (nonprofit?.tags?.length > 0) {
      tagsArray = ('' + nonprofit.tags).split(',');
    } else if (!!nonprofitTags) {
      tagsArray = ('' + nonprofitTags).split(',');
    }

    return tagsArray;
  }

  formatNpoAddresses (nonprofits: BasicNonprofit[]) {
    nonprofits.forEach((nonprofit: BasicNonprofit) => {
      (nonprofit.address = nonprofit.address || {} as Address).toString = () => {
        return this.formatter.format(nonprofit.address, false, true);
      };
    });

    return nonprofits;
  }

  addStateCodeToPayload (
    payload: RemittancePayload|DisplayPayload,
    type: AddressType
  ) {
    this.locationService.setRegions(payload.address.countryCode);
    if (!this.locationService.regions) {
      return payload;
    } else {
      const regions: StateProvReg[] = this.locationService.regions;
      if (payload.address.stateProvRegId) {
        if ([AddressType.displayInfo, AddressType.remittanceAddress].includes(type)) {
          const stateName = this.findMatchingState(
            regions,
            payload.address.stateProvRegId as number
          );
          payload.address.stateProvRegName = stateName;
        }
      }

      return payload;
    }
  }

  findMatchingState (
    optionValue: StateProvReg[],
    formValue: number
  ) {
    const match = optionValue.filter(region => region.id === formValue);

    return match[0].stateProvRegName;
  }

  updateAttributeInMyNpos (attrs: any) {
    const index = this.myNpos.findIndex((npo) => npo.id === this.selectedNpo);
    const myNpos = [
      ...this.myNpos.slice(0, index),
      {
        ...this.myNpos[index],
        ...attrs
      },
      ...this.myNpos.slice(index + 1)
    ];

    this.setMyNpos(myNpos);
  }

  async updateAndSetNonprofitDisplayInfo () {
    const displayPayload = this.activeNpoStateData;
    const formattedDisplayPayload = this.formatDisplayPayload(displayPayload);
    const payloadWithStateCode = this.addStateCodeToPayload(
      formattedDisplayPayload,
      AddressType.displayInfo
    );
    const response = await this.updateNonprofitDisplayInfo(
      payloadWithStateCode
    );
    this.setActiveNpoStateData(response);
    this.setActiveNonprofit(displayPayload.id, true);
  }

  // APIs

  async getNonprofit (nonprofitId: number) {
    const id = !!nonprofitId ? +nonprofitId : null;
    try {
      const response = await this.nonprofitResources.getNonprofit(id);

      return response.data;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async getNonprofitAdmins (nonprofitId: number) {
    try {
      const response = await this.nonprofitResources.getNonprofitAdmins(
        nonprofitId
      );

      return response.data;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async updateNonprofitDisplayInfo (payload: DisplayPayload) {
    try {
      const response = await this.nonprofitResources.updateNonprofitDisplayInfo(
        payload
      );

      return response.data;
    } catch (e) {
      console.error(e);

      this.notifier.error(this.i18n.translate(
        'nonprofits:notificationErrorUpdatingDisplayInfo',
        {},
        'There was an error updating the display information'
      ));

      return null;
    }
  }

  async createEditOrganization (payload: CreateNonprofitApi, newOrganization: boolean) {
    try {
      const response = await this.nonprofitResources.createEditOrganization(
        payload
        ,newOrganization
      );

      if (newOrganization) {
        this.notifier.success(this.i18n.translate(
          'nonprofits:notificationSuccessAddOrg',
          {},
          'Successfully added organization'
        ));

        return response.data as CreateNonprofitApiResponse;
      } else {
          this.notifier.success(this.i18n.translate(
          'nonprofits:notificationSuccessUpdating the organization',
          {},
          'Successfully updated the organization'
        ));
      }
    } catch (e: any) {
      console.error(e);

      if (newOrganization) {
        this.notifier.error(this.i18n.translate(
          'nonprofits:notificationErrorAddingOrganization',
          {},
          'There was an error adding your organization'
        ));

        return e;
      } else {
        this.notifier.error(this.i18n.translate(
          'nonprofits:notificationErrorUpdatingOrganization',
          {},
          'There was an error updating your organization'
        ));
      }
    }

    return null;
  }

  updateNonprofitClassification (
    payload: UpdateNPOClassificationPayload,
    nonprofitId: number
  ) {
    try {
      const response = this.nonprofitResources.updateNonprofitClassification(
        payload,
        nonprofitId
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'admin:textErrorUpdatingClassification',
        {},
        'There was an error updating classification for this organization'
      ));

      return null;
    }
  }

  async getStatsSummaryInfo (
    nonprofitId: number,
    scenario: NonprofitSummaryInfoScenario
  ) {
    try {
      const response = await this.nonprofitResources.getStatsSummaryInfo(
        nonprofitId,
        scenario
      );

      return response.data;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'search:notificationErrorFetchingSummaryInfo',
        {},
        'There was an error fetching summary info'
      ));

      return null;
    }
  }

  async getShowMoreDetail (
    nonprofitId: number
  ) {
    try {
      const response = await this.nonprofitResources.getShowMoreDetail(
        nonprofitId
      );

      return response.data;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'common:notificationErrorRetreivingData',
        {},
        'There was an error retrieving the data'
      ));

      return null;
    }
  }

  /**
   * Get nonprofit revocation details by state.
   *
   * @param nonprofitId nonprofit identity
   * @param stateCode state code (e.g. 'CA')
   * @param nonprofitGuid nonprofit identity
   * @returns list of results
   */
  async getRevocationDetails (
    nonprofitId: number,
    stateCode?: string
  ): Promise<RevocationDetailsBySubdivision[]> {
    try {
      const response = await this.nonprofitResources.getRevocationDetails(
        nonprofitId,
        stateCode
      );

      return response.data;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'common:notificationErrorRetreivingData',
        {},
        'There was an error retrieving the data'
      ));

      return null;
    }
  }

  async getNonprofitIrsCodes (
    codeType: CodeType
  ) {
    try {
      const response = await this.nonprofitResources.getNonprofitIrsCodes(
        codeType
      );

      return response.data;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  // For My Nonprofits
  async getNonprofitsForAdmin () {
    try {
      const response = await this.nonprofitResources.getNonprofitsForAdmin();
      const nposWithFormattedAddress = this.formatNpoAddresses(response.data);

      return nposWithFormattedAddress;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  // This gets Parent and Standalone Nonprofits for User/Admin/Mgr AND
  // Chapter Nonprofits, but only if User is Admin/Mgr of Chapter and not the parent
  async getStrictNonprofitsForAdmin () {
    try {
      const response = await this.nonprofitResources.getStrictNonprofitsForAdmin();
      const nposWithFormattedAddress = this.formatNpoAddresses(response.data);

      return nposWithFormattedAddress;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async getIrsAttribution (
  paginationOptions: PaginationOptions<NonprofitRegAuthAttributes>,
  nonprofitId: number
) {
    const response = await this.nonprofitResources.getIrsAttribution(
      paginationOptions,
      nonprofitId
    );

    return response;
}

  async fetchNonprofitsWithCheckInfo ( // IN RESOURCES
    checkInfo: CheckInfoPayload
  ) {
    try {
      const response = await this.nonprofitResources.fetchNonprofitsWithCheckInfo(
        {
          ...checkInfo
        }
      );

      return response.data;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async updateNpoLogo (payload: LogoPayload) {
    const response = await this.updateNpoImage(payload);
    if (!!response) {
      if (response) {
        this.setActiveNpoStateData({
          nonprofitIconImageUrl: response.url,
          nonprofitLogoImageUrl: response.url
        });
        this.setNonprofitLogo(response.url);

      } else {
        this.notifier.success(this.i18n.translate(
          'nonprofits:notificationLogoRemoved',
          {},
          'Successfully removed nonprofit image'
        ));
        this.setActiveNpoStateData({
          nonprofitIconImageUrl: '',
          nonprofitLogoImageUrl: ''
        });
      }
    } else {
      this.setActiveNpoStateData({
        nonprofitIconImageUrl: this.activeNpo.nonprofitIconImageUrl,
        nonprofitLogoImageUrl: this.activeNpo.nonprofitIconImageUrl
      });
    }
  }

  async updateNpoImage (payload: LogoPayload) {
    try {
      const response = await this.assetManagementService.updateNpoImage(
        payload
      );
      this.notifier.success(this.i18n.translate(
        'nonprofits:notificationSuccessUpdatingNonprofitLogo',
        {},
        'Successfully updated the nonprofit logo'
      ));

      return response.data;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'nonprofits:notificationErrorUpdatingLogo',
        {},
        'There was an error updating the Nonprofit Logo.'
      ));

      return e;
      ;
    }
  }

  async getNonprofitAdditionalInformation (nonprofitId: number) {
    try {
      const response = await this.nonprofitResources.getNonprofitAdditionalInformation(
        nonprofitId
      );

      return response.data;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'common:notificationErrorRetreivingData',
        {},
        'There was an error retrieving the data'
      ));

      return null;
    }
  }
}
