import { CompanyDisplayModel } from '@core/models/check-reissue.model';
import { NonprofitComplianceRecord } from '@core/models/compliance.model';
import { BbiSubsectorFromApi, CharityAdminDetailDownloadInfo, CustomComplianceCompany } from '@core/models/reporting.model';
import { SystemEmailsAPI } from '@core/typings/api/system-email.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class ReportingState extends BaseYcState {
  readonly charityAdminDetailDownloadInfo: CharityAdminDetailDownloadInfo;
  readonly drilldownRowId: number;
  readonly clientId: number;
  readonly customComplianceCompanies: CustomComplianceCompany[];
  readonly allCompanies: CompanyDisplayModel[];
  readonly companiesForInput: CompanyDisplayModel[];
  readonly outstandingPaymentId: number;
  readonly outstandingPaymentNumber: number;
  readonly paymentNonprofitId: number;
  readonly paymentNonprofitName: string;
  readonly allCompliances: NonprofitComplianceRecord[];
  readonly currentEfgReportDateTime: string;
  readonly emailClasses: SystemEmailsAPI.EmailClass[];
  readonly emailClassesForFilter: SystemEmailsAPI.EmailClassesForFilter[];
  readonly bbiSubsectorData: BbiSubsectorFromApi[];
  readonly adminEmail: string;
  readonly ycProcessingPartnerNames: string[];
  readonly revokedNonprofitsSubdivisions: string[];
}
