
import { Injectable } from '@angular/core';
import { AppShellState } from '@core/state/app-shell.state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
@AttachYCState(AppShellState)
export class AppShellService extends BaseYCService<AppShellState> {
  myNonprofitsLink = {
    href: 'my-nonprofits',
    icon: 'heart',
    label: 'My Nonprofits',
    i18nKey: 'sidebar:linkMyNonprofits',
    sequence: '3.1',
    group: 3
  };

  constructor (
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
    private arrayHelper: ArrayHelpersService
  ) {
    super();
  }

  get programFeatureFlag () {
    return this.featureFlagService.programsFlagValue;
  }

  get sidebarClosed () {
    return this.get('sidebarClosed');
  }

  get sidebarLinks () {
    return this.get('sidebarLinks');
  }

  getSidebarLinks () {
    return (() => {
      if (this.authService.isYcAdminOrSupport()) {
        return [{
          href: 'yourcause-admin/yc-change-tracking-page',
          icon: 'exchange',
          label: 'Change Tracking',
          i18nKey: 'sidebar:linkChangeTracking',
          sequence: '2.5',
          group: 2
        }, {
          href: 'yourcause-admin/administrator-requests',
          icon: 'user-tie',
          label: 'Administrator Requests',
          i18nKey: 'sidebar:linkAdministratorRequests',
          sequence: '2.1',
          group: 2
        }].concat(
          this.authService.isYcNonprofitManager() ?
          [{
            href: 'nonprofit-management',
            icon: 'heart',
            label: 'Nonprofit Management',
            i18nKey: 'admin:hdrNonprofitManagement',
            sequence: '2.9',
            group: 2
          }] : []
        );
      } else {
        if (this.authService.hasActiveNonprofit()) {
          return [
            this.myNonprofitsLink,
            {
              href: 'my-workspace',
              icon: 'home',
              label: 'My Workspace',
              i18nKey: 'sidebar:linkMyWorkspace',
              sequence: '1.1',
              group: 1
            },
            {
              href: 'dashboard',
              icon: 'file-chart-line',
              label: 'Dashboard',
              i18nKey: 'sidebar:linkDashboard',
              sequence: '1.2',
              group: 1
            },
            {
              href: 'giving',
              icon: 'gift',
              label: 'Giving',
              i18nKey: 'sidebar:linkGiving',
              sequence: '1.3',
              group: 1
            },
            {
              href: 'volunteering',
              icon: 'hand-heart',
              label: 'Volunteering',
              i18nKey: 'sidebar:linkVolunteering',
              sequence: '1.4',
              group: 1
            },
            {
              href: 'nonprofit-profile',
              icon: 'ribbon',
              label: 'Nonprofit Profile',
              i18nKey: 'sidebar:linkNonprofitProfile',
              sequence: '1.5',
              group: 1
            },
            {
              href: 'account-setup',
              icon: 'toolbox',
              label: 'Administration',
              i18nKey: 'sidebar:linkAccountSetup',
              sequence: '2.1',
              group: 2
            }, {
              href: 'user-management',
              icon: 'users',
              label: 'User Management',
              i18nKey: 'sidebar:linkUserManagement',
              sequence: '2.2',
              group: 2
            }
            ].concat(
              this.programFeatureFlag ?
                [{
                  href: 'programs',
                  icon: 'gifts',
                  label: 'Programs',
                  i18nKey: 'sidebar:linkPrograms',
                  sequence: '1.6',
                  group: 1
                }] : []
            );
        } else {
          return [this.myNonprofitsLink];
        }
      }
    })().concat(
      this.authService.isImplementationManager() ?
      [{
        href: 'implementation',
        icon: 'hammer',
        label: 'Implementation',
        i18nKey: 'sidebar:linkImplementation',
        sequence: '2.51',
        group: 2
      }] : []
    ).concat(
      this.authService.isComplianceAdmin() ?
      [{
        href: 'compliance-admin',
        icon: 'file-check',
        label: 'Compliance',
        i18nKey: 'admin:hdrCompliance',
        sequence: '2.7',
        group: 2
      }] : []
    ).concat(
      this.authService.isYcAdminOrSupport() ||
        this.authService.isYCReporting() ||
        this.authService.isImplementationManager() ?
      [{
        href: 'reporting',
        icon: 'chart-line',
        label: 'Reporting',
        i18nKey: 'sidebar:linkReporting',
        sequence: '2.92',
        group: 2
      }] : []
    ).concat(
      this.authService.isYcAdminOrSupport() ||
        this.authService.isYCReporting() ?
      [{
        href: 'search',
        icon: 'search',
        label: 'Nonprofit Search',
        i18nKey: 'sidebar:linkNonprofitSearch',
        sequence: '1.1',
        group: 1
      }] : []
    ).concat(this.authService.hasSystemRole('SiteAdmin') ? [{
      href: 'platform-admin',
      icon: 'toolbox',
      label: 'Platform Admin',
      i18nKey: 'sidebar:linkPlatformAdmin',
      sequence: '3.1',
      group: 3
    }, {
      href: 'help-support/switchboard',
      icon: 'question-circle',
      label: 'Help and Support',
      i18nKey: 'sidebar:linkHelpAndSupport',
      sequence: '3.1',
      group: 3
    }] : [{
      href: 'help-support/switchboard',
      icon: 'question-circle',
      label: 'Help and Support',
      i18nKey: 'sidebar:linkHelpAndSupport',
      sequence: '4.1',
      group: 4
    }]).concat(
      this.authService.isEngineering() ?
      [{
        href: 'system-health-dashboard',
        icon: 'tachometer',
        label: 'System Health Dashboard',
        i18nKey: 'sidebar:linkSystemHealthDashboard',
        sequence: '6.1',
        group: 6
      },
      {
        href: 'submitted-support-requests',
        icon: 'ticket',
        label: 'Support Requests',
        i18nKey: 'sidebar:linkSupportRequests',
        sequence: '6.2',
        group: 6
      }] : []
    );
  }

  toggleSidebar () {
    this.set('sidebarClosed', !this.sidebarClosed);
  }

  setSidebarLinks () {
    const links = this.getSidebarLinks();
    this.set('sidebarLinks', this.arrayHelper.sort(links, 'sequence'));
  }

  setShowHeaderButtons (show: boolean) {
    this.set('showHeaderButtons', show);
  }
}
