import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nppStatusIcon'
})
export class StatusIcon implements PipeTransform {
  constructor () {}

  transform (value: any): any {
    const status = ('' + value).toLowerCase();
    switch (status) {
      case 'true':
      case 'approved':
      case 'approved - ed':
      case 'published':
      case 'cleared':
      case 'verified':
        return 'check-circle text-success';
      case 'expired':
      case 'no application':
        return 'minus-circle text-warning';
      case 'on hold':
        return 'hand-paper text-warning';
      case 'on hold pending billing':
        return 'pause-circle text-warning';
      case 'false':
      case 'rejected':
      case 'declined - ed':
      case 'sanctioned':
      case 'denied':
      case 'declined':
        return 'times-circle text-danger';
      case 'draft':
      case 'information requested':
        return 'pencil text-info';
      case 'in progress':
        return 'spinner text-grey';
      case 'cancelled':
        return 'trash text-danger';
      case 'pending':
      case 'submitted':
      case 'awaiting review':
      case 'revision submitted':
      case 'routed to vetting authority':
      default:
        return 'ellipsis-h text-grey';
    }
  }
}
