import { Injectable } from '@angular/core';
import { ContactPayload, ContactResponse, CreateEventDocumentPayload, CreateEventPhotoPayload, CreateOrUpdateEventShiftPayload, DeleteEventDocumentPayload, DeleteEventPhotoPayload, EmailParticipantModalResponse, EventContact, EventSkillType, Participant, ParticipantExportModel, ParticipantResponse, VolunteerCommunityPartnerResponse, VolunteerEvent, VolunteerEventDocument, VolunteerEventPhoto, VolunteerEventsResponse, VolunteerShift, VolunteerShiftsResponse, VolunteerTimeZone } from '@core/models/volunteer-event.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse, PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class VolunteerEventResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getEvent (eventId: number) {
    const endpoint = `api/Volunteer/GetEvent?EventId=${eventId}`;
    const response = await this.httpRestService.get<APIResponse<VolunteerEvent>>(
      endpoint
    );

    return response.data;
  }

  async createEvent (volunteerEvent: Partial<VolunteerEvent>) {
    const endpoint = '/api/Volunteer/CreateEvent';
    const response = await this.httpRestService.post<APIResponse<VolunteerEvent>>(
      endpoint,
      volunteerEvent
    );

    return response.data;
  }

  async updateEvent (volunteerEvent: Partial<VolunteerEvent>) {
    return this.httpRestService.post<APIResponse<VolunteerEvent>>(
      '/api/Volunteer/UpdateEvent',
      volunteerEvent
    );
  }

  async publishEvent (
    eventId: number,
    requiresApproval?: boolean
  ) {
    return this.httpRestService.get<APIResponse<string>>(
      `api/Volunteer/PublishEvent?EventId=${eventId}`
    );
  }

  async deactivateEvent (eventId: number) {
    return this.httpRestService.delete<APIResponse<string>>(
      `api/Volunteer/DeactivateEvent?EventId=${eventId}`
    );
  }

  async getEventParticipants (
    eventId: number,
    paginationOptions: PaginationOptions<Participant>
  ) {
    return this.httpRestService.get<APIResponse<ParticipantResponse>>(
      `api/Volunteer/GetEventParticipants?EventId=${eventId}&Page=${paginationOptions.pageNumber}&Size=${paginationOptions.rowsPerPage}`
    );
  }

  async getEventParticipationsExport (eventId: number) {
    return this.httpRestService.get<APIResponse<ParticipantExportModel>>(
      `api/Volunteer/GetEventParticipationsExport?EventId=${eventId}`
    );
  }

  async getEventParticipationsByShift (
    eventId: number,
    shiftId: number,
    paginationOptions: PaginationOptions<Participant>
  ) {
    return this.httpRestService.get<APIResponse<ParticipantResponse>>(
      `api/Volunteer/GetEventParticipationsByShift?EventId=${eventId}&ShiftId=${shiftId}&Page=${paginationOptions.pageNumber}&Size=${paginationOptions.rowsPerPage}`
    );
  }

  async getEventParticipationsByShiftExport (
    eventId: number,
    shiftId: number
  ) {
    return this.httpRestService.get<APIResponse<ParticipantExportModel>>(
      `api/Volunteer/GetEventParticipationsByShiftExport?EventId=${eventId}&ShiftId=${shiftId}`
    );
  }

  async getEventWaitlist (
    eventId: number,
    paginationOptions: PaginationOptions<Participant>
  ) {
    return this.httpRestService.get<APIResponse<ParticipantResponse>>(
      `api/Volunteer/GetEventWaitlist?EventId=${eventId}&Page=${paginationOptions.pageNumber}&Size=${paginationOptions.rowsPerPage}`
    );
  }

  async getEventWaitlistExport (eventId: number) {
    return this.httpRestService.get<APIResponse<ParticipantExportModel>>(
      `api/Volunteer/GetEventWaitlistExport?EventId=${eventId}`
    );
  }

  async getEventWaitlistByShift (
    eventId: number,
    shiftId: number,
    paginationOptions: PaginationOptions<Participant>
  ) {
    return this.httpRestService.get<APIResponse<ParticipantResponse>>(
      `api/Volunteer/GetEventWaitlistByShift?EventId=${eventId}&ShiftId=${shiftId}&Page=${paginationOptions.pageNumber}&Size=${paginationOptions.rowsPerPage}`
    );
  }

  async getEventWaitlistByShiftExport (
    eventId: number,
    shiftId: number
  ) {
    return this.httpRestService.get<APIResponse<ParticipantExportModel>>(
      `api/Volunteer/GetEventWaitlistByShiftExport?EventId=${eventId}&ShiftId=${shiftId}`
    );
  }

  async emailEventParticipants (payload: EmailParticipantModalResponse) {
    return this.httpRestService.post<APIResponse>(
      'api/Volunteer/EmailEventParticipant',
      payload
    );
  }

  async getEventPhotos (eventId: number) {
    return this.httpRestService.get<APIResponse<VolunteerEventPhoto[]>>(
      `api/Volunteer/GetEventPhotos?EventId=${eventId}`
    );
  }

  async createEventPhoto (payload: CreateEventPhotoPayload) {
    return this.httpRestService.post(
      'api/Volunteer/CreateEventPhoto',
      payload
    );
  }

  async deleteEventPhoto (payload: DeleteEventPhotoPayload) {
    return this.httpRestService.delete(
      `api/Volunteer/DeleteEventPhoto?EventId=${payload.eventId}&PhotoName=${payload.photoName}`
    );
  }

  async getEventShiftsPaginated (
    eventId: number,
    paginationOptions: PaginationOptions<VolunteerShift>
  ) {
    return this.httpRestService.get<APIResponse<VolunteerShiftsResponse>>(
      `api/Volunteer/GetEventShifts?EventId=${eventId}&Page=${paginationOptions.pageNumber}&Size=${paginationOptions.rowsPerPage}`
    );
  }

  async getEventShiftsNoPagination (
    eventId: number
  ) {
    return this.httpRestService.get<APIResponse<VolunteerShiftsResponse>>(
      `api/Volunteer/GetEventShifts?EventId=${eventId}&Page=1&Size=100`
    );
  }

  async createOrUpdateEventShift (
    payload: CreateOrUpdateEventShiftPayload
  ) {
    return this.httpRestService.post<APIResponse<CreateOrUpdateEventShiftPayload>>(
      'api/Volunteer/CreateOrUpdateEventShift',
      payload
    );
  }

  async deleteEventShift (
    eventId: number,
    shiftId: number
  ) {
    return this.httpRestService.delete(
      `api/Volunteer/DeleteEventShift?EventId=${eventId}&ShiftId=${shiftId}`
    );
  }

  async getEventContacts (
    eventId: number,
    paginationOptions: PaginationOptions<EventContact>
  ) {
    return this.httpRestService.get<APIResponse<ContactResponse>>(
      `api/Volunteer/GetEventContacts?EventId=${eventId}&Page=${paginationOptions.pageNumber}&Size=${paginationOptions.rowsPerPage}`
    );
  }

  async createOrUpdateEventContact (payload: ContactPayload) {
    return this.httpRestService.post<APIResponse<EventContact>>(
      'api/Volunteer/CreateOrUpdateEventContact',
      payload
    );
  }

  async deleteEventContact (
    eventId: number,
    contactId: number
  ) {
    return this.httpRestService.delete(
      `api/Volunteer/DeleteEventContact?EventId=${eventId}&ContactId=${contactId}`
    );
  }

  async getEventDocuments (eventId: number) {
    return this.httpRestService.get<APIResponse<VolunteerEventDocument[]>>(
      `api/Volunteer/GetEventDocuments?EventId=${eventId}`
    );
  }

  async createEventDocument (payload: CreateEventDocumentPayload) {
    return this.httpRestService.post(
      'api/Volunteer/CreateEventDocument',
      payload
    );
  }

  async deleteEventDocument (payload: DeleteEventDocumentPayload) {
    return this.httpRestService.delete(
      `api/Volunteer/DeleteEventDocument?EventId=${payload.eventId}&DocumentName=${payload.documentName}`
    );
  }

  async getTimeZones () {
    return this.httpRestService.get<APIResponse<VolunteerTimeZone[]>>(
      'api/Volunteer/GetTimeZones'
    );
  }

  async fetchVolunteerEventsList (
    endpoint: string,
    paginationOptions: PaginationOptions<VolunteerEvent>
  ) {
    return this.httpRestService.get<APIResponse<VolunteerEventsResponse>>(
      `${endpoint}?Page=${paginationOptions.pageNumber}&Size=${paginationOptions.rowsPerPage}`
    );
  }

  async getEventCommunityPartners () {
    return this.httpRestService.get<APIResponse<VolunteerCommunityPartnerResponse>>(
      'api/Volunteer/GetEventCommunityPartners?Page=1&Size=100'
    );
  }

  async getAllEventSkills () {
    const url = 'api/Volunteer/GetAllEventSkills';

    return this.httpRestService.get<APIResponse<EventSkillType[]>>(url);
  }

  async getAffiliateEventSkills (affiliateId: number) {

    const url = `api/Volunteer/GetAffiliateEventSkills?AffiliateId=${affiliateId}`;

    return this.httpRestService.get<APIResponse<EventSkillType[]>>(url);
  }
}
