import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '@yourcause/common/date';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'nppActiveVetting'
})
export class ActiveVettingPipe implements PipeTransform {
  activeVettingTypes = ['Not Required', 'Unvetted'];
  constructor (
    private dateService: DateService,
    private i18n: I18nService
  ) { }

  transform (vettingName: string, expDate?: string): string {
    if (!!expDate &&
        !this.activeVettingTypes.includes(vettingName)
    ) {
      const expireText = this.i18n.translate(
        'nonprofits:textActiveVettingWithExpiration',
        {
          expDate: this.dateService.formatDate(expDate, 'MM/dd/yyyy')
        },
        'expiring __expDate__'
      );

      return vettingName + ' ' + expireText;
    } else {
      return vettingName;
    }
  }
}
