import { Pipe, PipeTransform } from '@angular/core';
import { InflectService } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'nppSdgLocalizedName'
})
export class SdgLocalizedName implements PipeTransform {

  constructor (
    private i18n: I18nService,
    private inflect: InflectService
  ) { }

  transform (name: string): string {
    return this.i18n.translate(
      `sdgs:text${this.inflect.trimPuncAndPascalize(name)}`,
      {},
      name
    );
  }
}
