import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'jobIntervalFrequency'
})
export class JobIntervalFrequency implements PipeTransform {
  constructor (
    private readonly i18n: I18nService
  ) {
  }
  everyText = this.i18n.translate('common:textEvery', {}, 'Every');

  transform (interval: number, frequency: string): any {
    if (interval > 1) {
      return `${this.everyText} ${interval} ${frequency}s`;
    } else {
      return `${this.everyText} ${frequency}`;
    }
  }
}
