import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { AuthService } from '../../_core/services/auth.service';
import { TermsOfServiceModelResponse } from '@core/models/auth.model';
import { YCModalComponent } from '@yourcause/common/modals';

@Component({
  selector: 'yc-terms-of-service-modal',
  templateUrl: './terms-of-service-modal.component.html'
})
export class TermsOfServiceModalComponent extends YCModalComponent<boolean> implements OnInit {
  @Output() completed = new EventEmitter<boolean>();

  userTermsOfService: TermsOfServiceModelResponse;

  constructor (
    public authService: AuthService,
    private spinnerService: SpinnerService
  ) {
    super();
  }

  async ngOnInit () {
    this.setUsersTermsOfService();
  }

  async setUsersTermsOfService () {
    this.spinnerService.startSpinner();
    this.userTermsOfService = (await this.authService.getUserTermsOfService()).data;
    this.spinnerService.stopSpinner();
  }

  async acknowledgeTermsOfService () {
    this.spinnerService.startSpinner();
    if (this.userTermsOfService.id){
      await this.authService.acknowledgeTermsOfService(this.userTermsOfService.id);
    }
    this.spinnerService.stopSpinner();
    this.closeModal.emit(true);
  }

  openPrivacyPolicy () {
    if (this.userTermsOfService.privacyPolicyUrl){
      window.open(this.userTermsOfService.privacyPolicyUrl, '_blank');
    }
  }

  openTermsOfService () {
    if (this.userTermsOfService.url){
      window.open(this.userTermsOfService.url, '_blank');
    }
  }

  signOut () {
    this.closeModal.emit(false);
  }
}
