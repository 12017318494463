
export const Behaviors = {
  userKey: '_npp_user',
  userTokenKey: '_npp_userToken',
  clientIdentifierKey: '_npp_clientIdentifier'
};

export interface TokenPayload {
  email: string;
  password: string;
  clientIdentifier: string;
}

export interface SSOToken {
  id_token: string;
  access_token: string;
  scope: string;
  session_state: string;
  state: string;
  code?: string;

  swapped_token: string;

  [x: string]: string;
}

export interface TokenResponse {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshTokenExpiration: string;
  bbidToken?: boolean;
}

export interface TokenContent {
  UserId: string;
  client_id: string;
  aud: string;
  email: string;
  exp: number;
  family_name: string;
  given_name: string;
  iss: string;
  jti: string;
  sub: string;
  typ: string;
}

export interface SSOTokenResponse extends TokenResponse {
  subdomain: string;
}

