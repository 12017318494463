import { Component, Input, OnInit } from '@angular/core';
import { TransactionFees } from '@core/models/donation.model';
import { CheckFeeDetails } from '@core/models/payment.model';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';


@Component({
  selector: 'yc-check-fee-details-modal',
  templateUrl: './check-fee-details-modal.component.html',
  styleUrls: ['./check-fee-details-modal.component.scss']
})

export class CheckFeeDetailsModalComponent extends YCModalComponent<boolean> implements OnInit {
  @Input() checkFeeDetails: CheckFeeDetails;
  @Input() isNpoAdmin = false;
  @Input() transactionFeeDetails: TransactionFees;
  @Input() disbursementFees: TransactionFees;
  @Input() companyName: string;
  @Input() disbursementCurrencyCode: string;
  @Input() currency: string;
  loading = true;
  modalHeader: string;

  primaryButtonText = this.i18n.translate(
    'common:btnClose',
    {},
    'Close'
  );
  secondaryButtonText = this.i18n.translate(
    'give:textRegisterForAchDisbursement',
    {},
    'Register for ACH disbursement'
  );

  constructor (
    private i18n: I18nService
  ) {
    super();
  }

  ngOnInit () {
    this.modalHeader = !!this.checkFeeDetails ? this.i18n.translate(
      'payments:hdrWhatIsTheCharityCheckAdminFee',
      {},
      'What is the Charity Check Admin Fee?'
    ) : this.i18n.translate(
      'give:hdrAboutServiceFees',
      {},
      'About Service Fees'
    );

    this.loading = false;
  }

  goToDisbursementInfoPage () {
    this.closeModal.emit(true);
  }

  _onCancel () {
    this.closeModal.emit();
  }
}
