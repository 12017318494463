export interface FeatureFlagModel {
  id: number;
  name: string;
  description: string;
  enabled: boolean;
}

export interface UpdateFeatureFLagPayload {
  id: number;
  enabled: boolean;
}

export enum FeatureFlagType {
  Programs = 'Programs',
  BlackbaudId = 'BlackbaudId',
  LiveChat = 'LiveChat',
  PopulationServed = 'PopulationServed'
}
