import { Pipe, PipeTransform } from '@angular/core';
import { SearchMoreDetails } from '@core/models/npo.model';
import { I18nService } from '@yourcause/common/i18n';

@Pipe({
  name: 'nppRegAuthAttrs'
})
export class RegistrationAuthAttrs implements PipeTransform {
  naText = this.i18n.translate(
    'common:textNotApplicableNA',
    {},
    'N/A'
  );

  constructor (
    private i18n: I18nService
  ) { }

  transform (
    nonprofitDetails: SearchMoreDetails,
    defaultValue: string, // RegistrationAuthorityAttribute - i18nDefaultValue
    isBool: boolean, // should return boolean
    isDesc: boolean, // should return string (description)
    translateLabel = true // so we don't translate FP/TS acronyms
  ) {
    if (!!nonprofitDetails) {
      let returnVal: any;
      if (isBool || isDesc) {
        nonprofitDetails.registrationAuthorityAttributes?.find((attr) => {
          if (attr.i18nDefaultValue === defaultValue) {
            if (isBool) {
              returnVal = attr.value === 'True' ? true : false ;
            } else {
              returnVal = attr.value !== '' ?
                attr.value :
                this.naText;
            }
          }
        });
      } else if (!isBool && !isDesc) {
        nonprofitDetails.registrationAuthorityAttributes?.find((attr) => {
          if (attr.i18nDefaultValue === defaultValue) {
            returnVal = translateLabel ?
              this.i18n.translate(
                attr.i18nKey,
                {},
                attr.i18nDefaultValue
              ) :
              attr.i18nDefaultValue;
          }
        });
      }

      return returnVal;
    }
  }
}
