import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VettingApplicationStepperStep } from '@core/typings/vetting-application.typing';

@Component({
  selector: 'yc-vetting-application-stepper',
  templateUrl: './vetting-application-stepper.component.html',
  styleUrls: ['./vetting-application-stepper.component.scss']
})
export class VettingApplicationStepperComponent {
  @Input() steps: VettingApplicationStepperStep[] = [];
  @Input() currentStep = 0;
  @Input() stepperIsDisabled = false;
  @Output() currentStepChanged = new EventEmitter();

  isOpen (i: number) {
    return i === this.currentStep;
  }

  isDisabled (index: number) {
    if (this.stepperIsDisabled) {
      return false;
    }
    let allPreviousStepsValid = true;
    while (index--) {
      if (this.steps[index] && !this.steps[index].canProceed) {
        allPreviousStepsValid = false;
      }
    }

    return !allPreviousStepsValid;
  }
}
