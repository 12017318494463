export interface NonprofitIRSCodes {
  nonprofitCodes: NonprofitIRSCodeInfo[];
}

export interface NonprofitIRSCodeInfo {
  code: string;
  description: string;
  bBGFEligible: string;
}

export enum CodeType {
  Pub78Designation = 'Pub78Designation',
  BMFFoundation = 'BMFFoundation',
  BMFSubsection = 'BMFSubsection'
}
