import { Injectable } from '@angular/core';
import { SendBrandedEmailModel } from '@core/models/mail-to.model';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { MailToResources } from './mailTo-modal.resources';


@Injectable({ providedIn: 'root' })
export class MailToService {

  constructor (
    private notifier: NotifierService,
    private i18n: I18nService,
    private mailToResources: MailToResources
  ) { }

  async sendBrandedEmail (
    payload: SendBrandedEmailModel,
    skipToaster = false
  ) {
    const defaultTitle = this.i18n.translate(
      'common:textMessageFromNpoConnect',
      {},
      'Message from NPOconnect'
    );
    if (!payload.title) {
      payload.title = defaultTitle;
    }
    try {
      await this.mailToResources.sendBrandedEmail(payload);
      if (!skipToaster) {
        this.notifier.success(this.i18n.translate(
          'common:notificationSuccessfullySentEmail',
          {},
          'Successfully sent the email'
        ));
      }
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        'common:notificationThereWasAnErrorSendingTheMessage',
        {},
        'There was an error sending the email'
      ));
    }
  }

}
