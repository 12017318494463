import { CompanyDisplayModel } from '@core/models/check-reissue.model';
import { NonprofitAsset } from '@core/models/nonprofit-asset.model';
import { Nonprofit } from '@core/models/npo.model';
import { VettingRequestStatusId } from '@core/models/status.model';
import { User } from '@core/models/user.model';
import { MailToModalResponse, TableAsset } from '@yourcause/common';

export namespace VettingRequestAPI {
  export interface VettingRequestStatus {
    id: number;
    type: string;
  }

  export interface VettingRequest extends VettingRequestDisplay {
    nonprofit: Nonprofit;
  }

  export interface VettingRequestDisplay {
    id: number;
    ycVettingRequestId: number;
    submittedOn: string;
    affiliateEmployeeId: number;
    affiliateEmployeeFirstName: string;
    affiliateEmployeeLastName: string;
    affiliateEmployeeEmail: string;
    affiliateEmployeeCount: number;
    nonprofitContactName: string;
    nonprofitContactEmail: string;
    nonprofitContactWebsite: string;
    contactIsActiveUser?: boolean;
    contactIsNonprofitAdmin?: boolean;
    notes: string;
    vettingRequestStatusId: number;
    vettingRequestStatus: string;
    nonprofitVettingRequestStatusComment: string;
    lastModifiedDate: string;
    modifiedByUserId?: number;
    createdByUserId: number;
    createDate: string;
    company: {
      id: number;
      name: string;
      externalKey: string;
      externalSystemType: string;
    };
    modifiedByUser: {
      id: number;
      lastName: string;
      firstName: string;
      fullName: string;
      email: string;
      lastLoginDate: string;
    };
    createdByGcUser?: string;
    companyName: string;
    externalSystemTypeId: number;
    rejectionReason?: string;
    rejectionReasonId?: number;
    countOfVettingNotes: number;
    vettingTypeId: number;
  }

  export interface UpdateRequestStatusPayload {
    id: number;
    nonprofitVettingRequestStatusTypeId: VettingRequestStatusId;
    comment?: string;
    rejectionReasonId?: number;
    reevaluationDate?: string;
  }

  export enum VettingRequestStatusName {
    REQUEST_SUBMITTED = 'Request submitted',
    INVITATION_SENT = 'Invitation sent',
    PENDING = 'Pending',
    APPROVED_ED = 'Approved - ED',
    APPROVED_STANDARD = 'Approved - Standard',
    DECLINED_ED = 'Declined - ED',
    DECLINED_STANDARD = 'Declined - Standard',
    DRAFT = 'Draft',
    INFORMATION_REQUESTED = 'Information Requested',
    ON_HOLD = 'On Hold'
  }

  export enum VettingTypeId {
    ED = 1,
    Standard = 3,
    DomesticVetting = 4
  }

  export enum VettingTypeName {
    DOMESTIC = 'Domestic Vetting',
    STANDARD = 'Standard',
    ED = 'ED'
  }

  export interface NonprofitVettingRequestPayload {
    affiliateEmployeeEmail: string;
    affiliateEmployeeFirstName: string;
    affiliateEmployeeLastName: string;
    companyId: number;
    contactEmail?: string;
    contactName?: string;
    contactWebsite?: string;
    externalSystemTypeId?: number;
    isSubmission: boolean;
    nonprofitId?: number;
    nonprofitVettingRequestId?: number;
    notes?: string;
  }

  export interface NonprofitVettingRequestUpdateResponse {
    id: number;
    vettingTypeId: number;
    submittedOn: string;
    notes: string;
    vettingRequestStatusId: number;
    rejectionReason?: string;
    rejectionReasonId?: number;
    vettingRequestStatus: string;
    nonprofitVettingRequestStatusComment?: string;
    nonprofitVettingRequestStatusChangedByUser: User;
    nonprofitVettingRequestStatusChangeDate: string;
    lastModifiedDate: string;
    modifiedByUser: User;
    createdByUserId: number;
    createDate: string;
    isActiveRow: boolean;
    countOfVettingNotes?: number;
    affiliateEmployeeEmail: string;
    affiliateEmployeeFirstName: string;
    affiliateEmployeeLastName: string;
    companyId: number;
    expirationDate?: string;
    reevaluationDate?: string;
  }

  export interface NonprofitVettingRequestWithAssets extends NonprofitVettingRequestPayload {
    assetsToAdd: TableAsset[];
  }

  export interface NonprofitVettingRequestDetail extends NonprofitVettingRequestUpdateResponse {
    assets?: NonprofitAsset[];
  }

  export interface DomesticVettingRequest {
    ycCharityId: number;
    submittedByUser: User;
    reeveluationDate: string;
    nonprofit: Nonprofit;
    id: number;
    ycVettingRequestId?: number;
    vettingTypeId: number;
    submittedOn: string;
    affiliateEmployeeId: number;
    affiliateEmployeeFirstName: string;
    affiliateEmployeeLastName: string;
    affiliateEmployeeEmail: string;
    affiliateEmployeeCount: number;
    nonprofitContactName?: string;
    nonprofitContactEmail?: string;
    nonprofitContactWebsite?: string;
    notes?: string;
    vettingRequestStatusId: number;
    rejectionReason?: string;
    rejectionReasonId?: number;
    vettingRequestStatus: string;
    nonprofitVettingRequestStatusComment?: string;
    nonprofitVettingRequestStatusChangedByUser?: User;
    nonprofitVettingRequestStatusChangeDate?: string;
    lastModifiedDate: string;
    modifiedByUserId?: number;
    createdByUserId: number;
    createDate: string;
    isActiveRow: boolean;
    company: CompanyDisplayModel;
    modifiedByUser: User;
    externalSystemTypeId?: number;
    countOfVettingNotes?: number;
    assignedToUser?: User;
    pub78Code?: string;
    bmfFoundationCode?: string;
    bmfAffiliationCode?: string;
    bmfSubsectionCode?: string;
  }

  export interface NonprofitVettingTileDetailsModel {
    vettingId: number;
    vettingAuthority: string;
    vettingTypeId: number;
    vettingComment: string;
    vettingCreateDate: string;
    vettingExpirationDate: string;
    reevaluationDate: string;
    vettingRequestSubmittedOn: string;
    vettingRequestId: number;
    vettingRequestStatusId: number;
    vettingRequestStatusChangeDate: string;
  }

  export interface DomesticVettingStatusModalPayload {
    statusUpdateInfo: UpdateRequestStatusPayload;
    emailInfo: MailToModalResponse;
  }
}
