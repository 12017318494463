import { Injectable } from '@angular/core';
import { Country, CurrencyByCountry, StateProvReg } from '@core/models/location.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class LocationResources {
  constructor (
    private httpRestService: HttpRestService
  ) { }

  async fetchCountries () {
    const endpoint = 'api/countryregion/getcountries';
    const response = await this.httpRestService.get<APIResponse<Country[]>>(
      endpoint
    );

    return response.data;
  }

  async fetchRegionsByCountry (countryCode: string) {
    const endpoint = `api/countryregion/getstateprovinceregions?countryCode=${countryCode}`;
    const response = await this.httpRestService.get<APIResponse<StateProvReg[]>>(
      endpoint
    );

    return response.data;
  }

  async fetchCurrencyByCountry (countryCode: string) {
    const endpoint = `api/CountryRegion/Currencies?countryCode=${countryCode}`;
    const response = await this.httpRestService.get<APIResponse<CurrencyByCountry>>(
      endpoint
    );

    return response.data;
  }
}
