import { Injectable } from '@angular/core';
import { FeaturedPartner, FeaturedPartnerCollateral, FeaturedPartners } from '@core/models/featured-partner.model';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { FeaturedPartnerResources } from './featured-partner.resources';
import { FeaturedPartnerState } from './featured-partner.state';
import { AuthService } from '@core/services/auth.service';


@AttachYCState(FeaturedPartnerState)
@Injectable({ providedIn: 'root' })
export class FeaturedPartnerService extends BaseYCService<FeaturedPartnerState> {

  constructor (
    private featuredPartnerResources: FeaturedPartnerResources,
    private authService: AuthService
 ) {
   super();
 }

  get hiddenPartnerIds () {
    return this.get('hiddenPartnerIds');
  }

  get currentFeaturedPartner () {
    return this.get('currentFeaturedPartner');
  }

  get currentFeaturedPartnerCollateral () {
    return this.get('currentFeaturedPartnerCollateral');
  }

  get hasCurrentFeaturedPartner (): boolean {
    return !!this.currentFeaturedPartnerCollateral;
  }

  get showFeaturedPartner (): boolean {
    return !this.hiddenPartnerIds.includes(this.currentFeaturedPartner.id);
  }

  addHiddenPartnerId (id: number) {
    this.set('hiddenPartnerIds', [...this.hiddenPartnerIds, id]);
  }

  removeHiddenPartnerId (id: number) {
    this.set('hiddenPartnerIds', this.hiddenPartnerIds.filter(partnerId => partnerId !== id));
  }

  setCurrentFeaturedPartner (featuredPartner: FeaturedPartner) {
    this.set('currentFeaturedPartner', featuredPartner);
  }

  setActiveFeaturedPartnerCollateral (activeFeaturedPartnerCollateral: FeaturedPartnerCollateral) {
    this.set('currentFeaturedPartnerCollateral', activeFeaturedPartnerCollateral);
  }

  async getCurrentFeaturedPartner () {
    const response = 1;
    this.setCurrentFeaturedPartner({
      id: response
    });

    return response;
  }

  async getFeaturedPartnerCollateral (
    featuredPartnerId: FeaturedPartners,
    nonprofitId?: number
  ) {
    if (
      !this.currentFeaturedPartnerCollateral &&
      !this.authService.isYcAdminOrSupport()
    ) {
      const response = await this.featuredPartnerResources.getFeaturedPartnerCollateral(
        featuredPartnerId,
        nonprofitId
      );
      this.setActiveFeaturedPartnerCollateral(response);

      return response;
    }

    return this.currentFeaturedPartnerCollateral;
  }

  async createUserImpression (
    partnerCampaignCollateralId: number,
    nonprofitId?: number
  ) {
    await this.featuredPartnerResources.createUserImpression(partnerCampaignCollateralId, nonprofitId);
  }
}
