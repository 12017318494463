import { Component, Input } from '@angular/core';
import { DisbursementInfo, FISPlacholder } from '@core/models/disbursement-info.model';
import { I18nService } from '@yourcause/common/i18n';


@Component({
  selector: 'yc-bank-info-alert',
  template: `
  <div *ngIf="!!bankInfo">
    <div *ngIf="!bankInfo.isInReview &&
      !bankInfo.isRejected">
      <div class="row">
        <div
          [class.col-md-12]="!bankInfo.hasIntermediaryBankInfo"
          [class.col-md-6]="bankInfo.hasIntermediaryBankInfo">
          <!-- PRIMARY ACCOUNT INFO - APPROVED -->
          <div class="account-title">
            <yc-i18n
              key="bankInfo:hdrPrimaryBankInformation"
              defaultValue="Primary Bank Information">
            </yc-i18n>
          </div>
          <dl class="dl-horizontal">
            <dt *ngIf="bankInfo.comment">
              <yc-i18n key="common:textComment"></yc-i18n>
            </dt>
            <dd *ngIf="bankInfo.comment">
              {{ bankInfo.comment }}
            </dd>
            <dt>
              <yc-i18n key="bankInfo:textBankName"></yc-i18n>
            </dt>
            <dd>
              {{ bankInfo.bankName }}
            </dd>
            <dt *ngIf="bankInfo.routingNumber">
              <yc-i18n key="bankInfo:textRoutingNumber"></yc-i18n>
            </dt>
            <dd *ngIf="bankInfo.routingNumber">
              {{ bankInfo.routingNumber }}
            </dd>
            <dt *ngIf="bankInfo.swiftCode">
              <yc-i18n key="bankInfo:lblSwiftCode"></yc-i18n>
            </dt>
            <dd *ngIf="bankInfo.swiftCode">
              {{ bankInfo.swiftCode }}
            </dd>
            <dt>
              <yc-i18n key="bankInfo:textAccountType"></yc-i18n>
            </dt>
            <dd>
              {{ accountTypeText }}
            </dd>
            <dt>
              <yc-i18n key="bankInfo:lblAccountNumber"></yc-i18n>
            </dt>
            <dd *ngIf="bankInfo.bankAccountNumberLast2 !== FISPlaceholder.maskedNumber">
              *{{ bankInfo.bankAccountNumberLast2 }}
            </dd>
            <dd *ngIf="bankInfo.bankAccountNumberLast2 === FISPlaceholder.maskedNumber">
              {{ bankInfo.bankAccountNumberLast2 }}
            </dd>
            <dt>
              <yc-i18n key="bankInfo:textNameOnAccount" defaultValue="Name on account">
              </yc-i18n>
            </dt>
            <dd>
              {{ bankInfo.accountName }}
            </dd>
          </dl>
        </div>
        <!-- INTERMEDIARY ACCOUNT INFO APPROVED -->
        <div *ngIf="bankInfo.hasIntermediaryBankInfo"
          class="col-md-6">
          <div class="account-title">
            <yc-i18n
              key="bankInfo:hdrIntermediaryBankInformation"
              defaultValue="Intermediary Bank Information">
            </yc-i18n>
          </div>
          <dl class="dl-horizontal">
            <dt *ngIf="bankInfo.intermediarySwiftCode">
              <yc-i18n
                key="bankInfo:lblSwiftCode"
                defaultValue="SWIFT/BIC code">
              </yc-i18n>
            </dt>
            <dd *ngIf="bankInfo.intermediarySwiftCode">
              {{ bankInfo.intermediarySwiftCode }}
            </dd>
            <dt *ngIf="bankInfo.intermediaryBankAccountNumberLast2">
              <yc-i18n
                key="bankInfo:lblAccountNumber"
                defaultValue="Account number">
              </yc-i18n>
            </dt>
            <dd *ngIf="bankInfo.intermediaryBankAccountNumberLast2">
              *{{ bankInfo.intermediaryBankAccountNumberLast2 }}
            </dd>
          </dl>
        </div>
      </div>
    </div>

    <div *ngIf="bankInfo.isInReview || bankInfo.isRejected"
      class="yc-alert"
      role="alert">
      <div class="yc-alert-wrapper"
        [ngClass]="thisClass">
        <div class="yc-alert-icon">
          <yc-fa [icon]="icons[0]"></yc-fa>
        </div>
        <div class="yc-alert-icon-bg"></div>
        <div class="yc-alert-body"
          [class.two-row]="twoRow">
          <div *ngIf="title"
            class="bank-info-alert-title"
            [innerHtml]="title">
          </div>
          <div class="yc-alert-message">
            <div class="row">
              <div
                [class.col-md-6]="bankInfo.hasIntermediaryBankInfo"
                [class.col-md-12]="!bankInfo.hasIntermediaryBankInfo">
                <!-- PRIMARY ACCOUNT INFO - PENDING OR REJECTED -->
                <div class="account-title">
                  <yc-i18n
                    key="bankInfo:hdrPrimaryBankInformation"
                    defaultValue="Primary Bank Information">
                  </yc-i18n>
                </div>
                <dl class="dl-horizontal">
                  <dt *ngIf="bankInfo.comment">
                    <yc-i18n key="common:textComment"></yc-i18n>
                  </dt>
                  <dd *ngIf="bankInfo.comment">
                    {{ bankInfo.comment }}
                  </dd>
                  <dt>
                    <yc-i18n key="bankInfo:textBankName"></yc-i18n>
                  </dt>
                  <dd>
                    {{ bankInfo.bankName }}
                  </dd>
                  <dt *ngIf="bankInfo.routingNumber">
                    <yc-i18n key="bankInfo:textRoutingNumber"></yc-i18n>
                  </dt>
                  <dd *ngIf="bankInfo.routingNumber">
                    {{ bankInfo.routingNumber }}
                  </dd>
                  <dt *ngIf="bankInfo.swiftCode">
                    <yc-i18n key="bankInfo:lblSwiftCode"></yc-i18n>
                  </dt>
                  <dd *ngIf="bankInfo.swiftCode">
                    {{ bankInfo.swiftCode }}
                  </dd>
                  <dt>
                    <yc-i18n key="bankInfo:textAccountType"></yc-i18n>
                  </dt>
                  <dd>
                    {{ accountTypeText }}
                  </dd>
                  <dt>
                    <yc-i18n key="bankInfo:lblAccountNumber"></yc-i18n>
                  </dt>
                  <dd>
                    *{{ bankInfo.bankAccountNumberLast2 }}
                  </dd>
                  <dt>
                    <yc-i18n key="bankInfo:textNameOnAccount" defaultValue="Name on account">
                    </yc-i18n>
                  </dt>
                  <dd>
                    {{ bankInfo.accountName }}
                  </dd>
                </dl>
              </div>
              <!-- INTERMEDIARY ACCOUNT INFO - PENDING OR REJECTED -->
              <div *ngIf="bankInfo.hasIntermediaryBankInfo"
                class="col-md-6">
                <div class="account-title">
                  <yc-i18n
                    key="bankInfo:hdrIntermediaryBankInformation"
                    defaultValue="Intermediary Bank Information">
                  </yc-i18n>
                </div>
                <dl class="dl-horizontal">
                  <dt *ngIf="bankInfo.intermediarySwiftCode">
                  <yc-i18n
                    key="bankInfo:lblSwiftCode"
                    defaultValue="SWIFT/BIC code">
                  </yc-i18n>
                  </dt>
                  <dd *ngIf="bankInfo.intermediarySwiftCode">
                    {{ bankInfo.intermediarySwiftCode }}
                  </dd>
                  <dt *ngIf="bankInfo.intermediaryBankAccountNumberLast2">
                    <yc-i18n
                      key="bankInfo:lblAccountNumber"
                      defaultValue="Account number">
                    </yc-i18n>
                  </dt>
                  <dd *ngIf="bankInfo.intermediaryBankAccountNumberLast2">
                    *{{ bankInfo.intermediaryBankAccountNumberLast2 }}
                  </dd>
                </dl>
              </div>
            </div>
            <div *ngIf="bankInfo.isRejected"
              class="mt-3">
              <!-- REASON  -->
              <div *ngIf="bankInfo.rejectedReasonId">
                <strong>
                  <yc-i18n
                    key="admin:lblReasonForRejection"
                    defaultValue="Reason for rejection">
                  </yc-i18n>:
                </strong>
                {{ bankInfo.rejectedReasonId | nppReasonPipe }}
              </div>
              <!-- COMMENT  -->
              <div *ngIf="bankInfo.rejectedComment">
                <strong>
                  <yc-i18n
                    key="common:textCommentWithColon"
                    defaultValue="Comment:">
                  </yc-i18n>
                </strong>
                {{ bankInfo.rejectedComment }}
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styleUrls: ['./bank-info-alert.component.scss']
})
export class BankInfoAlertComponent {
  @Input() bankInfo: DisbursementInfo;
  twoRow = true;
  FISPlaceholder = FISPlacholder;
  detailsInReviewText = this.i18n.translate(
    'bankInfo:textNewBankDetailsInReview',
    {},
    'New banking details in review'
  );
  detailsRejectedText = this.i18n.translate(
    'bankInfo:textNewBankDetailsRejected',
    {},
    'New banking details have been rejected'
  );
  checkingAccountText = this.i18n.translate(
    'bankInfo:textCheckingAccount',
    {},
    'Checking account'
  );
  savingsAccountText = this.i18n.translate(
    'bankInfo:textSavingsAccount',
    {},
    'Savings account'
  );

  constructor (
    private i18n: I18nService
  ) { }

  get alertClass (): string {
    return this.bankInfo?.isInReview ?
      'info' :
      this.bankInfo?.isRejected ?
      'danger' :
      '';
  }

  get icons (): string[] {
    return this.bankInfo?.isInReview ?
      ['info-circle'] :
      this.bankInfo?.isRejected ?
      ['times-circle'] :
      [];
  }

  get thisClass (): string {
    return this.bankInfo?.isInReview ?
      'yc-alert-info' :
      this.bankInfo?.isRejected ?
      'yc-alert-danger' :
      '';
  }

  get title (): string {
    return this.bankInfo?.isInReview ?
      this.detailsInReviewText :
      this.bankInfo?.isRejected ?
      this.detailsRejectedText :
      '';
  }

  get accountTypeText (): string {
    return this.bankInfo?.isCheckingAccount ?
      this.checkingAccountText :
      this.savingsAccountText;
  }
}
