import { NotesAPI } from '@core/typings/api/notes.typing';
import { CachedAttr, CACHE_TYPES } from '@yourcause/common/cache';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';


@RegisterYCState()
export class NotesState extends BaseYcState {
  @CachedAttr(CACHE_TYPES.SESSION)
  readonly noteCount = 0 as number;

  readonly noteAssetMap: NotesAPI.NoteAssetMap = {};
}
