import { Pipe, PipeTransform } from '@angular/core';
import { OrganizationTypeForVetting } from '@core/typings/vetting-application.typing';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'nppVettingOrgType'
})
export class VettingOrganizationTypePipe implements PipeTransform {
  constructor (
    private i18n: I18nService
  ) { }

  transform (typeId: OrganizationTypeForVetting) {
    let typeDescription: string;
    switch (typeId) {
      case OrganizationTypeForVetting.STANDARD:
        typeDescription = this.i18n.translate(
          'vetting:textStandardOrganization',
          {},
          'Standard 501(c)3 nonprofit organization'
        );
        break;
      case OrganizationTypeForVetting.EXEMPT:
        typeDescription = this.i18n.translate(
          'vetting:textExemptOrganization',
          {},
          'Nonprofit organization that is exempt under a group exemption'
        );
        break;
      case OrganizationTypeForVetting.SUPPORTING:
        typeDescription = this.i18n.translate(
          'vetting:textSupportingOrganization',
          {},
          `Nonprofit that is considered a "Supporting Organization" by the IRS`
        );
        break;
      case OrganizationTypeForVetting.UNKNOWN:
        typeDescription = this.i18n.translate(
          'nonprofits:textUnknown',
          {},
          'Unknown'
        );
        break;
      case OrganizationTypeForVetting.STAND_ALONE_CHURCH:
        typeDescription = this.i18n.translate(
          'vetting:textStandAloneChurch',
          {},
          'Stand Alone Church (does not legally fall under a group exemption)'
        );
        break;
      case OrganizationTypeForVetting.GOVERNMENT_ENTITY:
        typeDescription = this.i18n.translate(
          'vetting:textGovernmentEntity',
          {},
          'Government Entity'
        );
    }

    return typeDescription;
  }
}
