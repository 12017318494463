import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BBGFEligibleStatusId, NonprofitSearchStatsModalType } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';

@Component({
  selector: 'npp-npo-stats-block',
  templateUrl: './npp-npo-stats-block.component.html',
  styleUrls: ['./npp-npo-stats-block.component.scss']
})

export class NppNpoStatsBlockComponent {
  @Output() openModal = new EventEmitter<number>();
  @Input() showPopovers = true;
  @Input() registrationAuthority: string;
  @Input() ycCharityId: number;
  @Input() isActive: boolean;
  @Input() eligibleForGivingStatusId: number;
  @Input() eligibleForDisbursement: boolean;
  // FOR ADD CHILD MODAL DISPLAY ONLY
  @Input() foundationCodeDesc: string;
  @Input() subsectionCodeDesc: string;

  NonprofitSearchStatsModalType = NonprofitSearchStatsModalType;
  BBGFEligibleStatusId = BBGFEligibleStatusId;
  popoverPlacement = 'right' as const;
  regAuthPopover = this.i18n.translate(
    'nonprofits:popRegistrationAuthorityDesc',
    {},
    'The primary source of this nonprofit information, usually a governmental agency within a country.'
  );
  isActivePopover = this.i18n.translate(
    'nonprofits:popIsActiveDesc',
    {},
    'This nonprofit is active within the Global Good Network. Inactive nonprofits are not available in CSRconnect or GrantsConnect.'
  );
  givingPopover = this.i18n.translate(
    'nonprofits:popEligibleForGivingDesc',
    {},
    'This nonprofit meets the BBGF criteria to allow for donations to be disbursed to it by BB.'
  );
  disbursementPopover = this.i18n.translate(
    'nonprofits:popEligibleForDisbursementDesc',
    {},
    'This nonprofit meets BB Compliance criteria to allow for disbursements to be made.'
  );
  constructor (
    private i18n: I18nService
  ) { }
}

