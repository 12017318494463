import { Pipe, PipeTransform } from '@angular/core';
import { GenericizeStatusSpecialCase } from '@core/models/status.model';
import { I18nService } from '@yourcause/common/i18n';


@Pipe({
  name: 'nppGenericizeStatus'
})
export class GenericizeStatus implements PipeTransform {
  constructor (
    private readonly i18n: I18nService
  ) {}

  transform (
    value: any,
    specialCase?: string
  ): any {
    let genericStatus;

    const status = ('' + value).toLowerCase();
    switch (status) {
      case 'approved':
      case 'verified by company':
      case 'verified':
      case 'approved - ed':
      case 'sent':
      case 'approved - standard':
      case 'disbursed':
        if (specialCase === GenericizeStatusSpecialCase.VERIFIED_DONATION) {
          genericStatus = this.i18n.translate('status:textVerifiedDonation', {}, 'Verified donation');
        } else if (specialCase === GenericizeStatusSpecialCase.VERIFIED_HOURS) {
          genericStatus = this.i18n.translate('status:textVerifiedHours', {}, 'Verified hours');
        } else if (specialCase === GenericizeStatusSpecialCase.ALL_TRANSACTIONS) {
          genericStatus = this.i18n.translate('common:textDisbursed', {}, 'Disbursed');
        } else {
          genericStatus = this.i18n.translate('statusPipe:Approved', {}, 'Approved');
        }

        return genericStatus;
      case 'not responded':
      case 'pending':
      case 'submitted':
      case 'processing':
        if (specialCase === GenericizeStatusSpecialCase.ALL_TRANSACTIONS) {
          genericStatus = this.i18n.translate('status:textPendingDisbursement', {}, 'Pending disbursement');
        } else {
          genericStatus = this.i18n.translate('statusPipe:Pending', {}, 'Pending');
        }

        return genericStatus;
      case 'expired':
        genericStatus = this.i18n.translate('statusPipe:Expired', {}, 'Expired');

        return genericStatus;
      case 'rejected by company':
      case 'rejected':
      case 'declined':
      case 'denied':
      case 'declined - ed':
      case 'declined - standard':
        genericStatus = this.i18n.translate('statusPipe:Declined', {}, 'Declined');

        return genericStatus;
      case 'not responded':
        genericStatus = this.i18n.translate('statusPipe:NotResponded', {}, 'Not responded');

        return genericStatus;
      case 'requestmadewithcheckreissue':
        genericStatus =  this.i18n.translate('statusPipe:RequestMadeWithCheckReissue', {}, 'Request made with check reissue');

        return genericStatus;
      case 'awaitingaddressapproval':
        genericStatus =  this.i18n.translate('statusPipe:AwaitingAddressApproval', {}, 'Awaiting address approval');

        return genericStatus;
      case 'true':
        genericStatus = this.i18n.translate('statusPipe:textTrue', {}, 'True');

        return genericStatus;
      case 'false':
        genericStatus = this.i18n.translate('statusPipe:textFalse', {}, 'False');

        return genericStatus;
      case 'outstanding':
        genericStatus = this.i18n.translate('statusPipe:textOutstanding', {}, 'Outstanding');

        return genericStatus;
      case 'cleared':
        genericStatus = this.i18n.translate('statusPipe:textCleared', {}, 'Cleared');

        return genericStatus;
      case 'refunded':
        genericStatus = this.i18n.translate('statusPipe:textRefunded', {}, 'Refunded');

        return genericStatus;
      case 'voided':
        genericStatus = this.i18n.translate('statusPipe:textVoided', {}, 'Voided');

        return genericStatus;
      case 'cancelled':
        genericStatus = this.i18n.translate('statusPipe:textCancelled', {}, 'Cancelled');

        return genericStatus;
      case 'ready to be sent':
        genericStatus = this.i18n.translate('statusPipe:textReadyToBeSent', {}, 'Ready to be sent');

        return genericStatus;
      case 'draft':
        genericStatus = this.i18n.translate('common:textDraft', {}, 'Draft');

        return genericStatus;
      case 'published':
        genericStatus = this.i18n.translate('common:textPublished', {}, 'Published');

        return genericStatus;
      case 'information requested':
        genericStatus = this.i18n.translate('statusPipe:InformationRequested', {}, 'Information requested');

        return genericStatus;
      case 'on hold':
        genericStatus = this.i18n.translate('statusPipe:OnHold', {}, 'On hold');

        return genericStatus;
      case 'sanctioned':
        genericStatus = this.i18n.translate('statusPipe:Sanctioned', {}, 'Sanctioned');

        return genericStatus;
      case 'completed':
        genericStatus = this.i18n.translate('statusPipe:Completed', {}, 'Completed');

        return genericStatus;
      case 'queued':
        genericStatus = this.i18n.translate('statusPipe:Queued', {}, 'Queued');

        return genericStatus;
      case 'staging':
        genericStatus = this.i18n.translate('statusPipe:Staging', {}, 'Staging');

        return genericStatus;
      case 'finished':
        if (specialCase === GenericizeStatusSpecialCase.CHARITY_COMPARE) {
          genericStatus = this.i18n.translate('statusPipe:Pending', {}, 'Pending');
        } else {
          genericStatus = this.i18n.translate('statusPipe:Finished', {}, 'Finished');
        }

        return genericStatus;
      case 'in progress':
        genericStatus = this.i18n.translate('statusPipe:InProgress', {}, 'In progress');

        return genericStatus;
      case 'awaiting review':
        genericStatus = this.i18n.translate('statusPipe:AwaitingReview', {}, 'Awaiting review');

        return genericStatus;
      case 'revision submitted':
        genericStatus = this.i18n.translate('statusPipe:RevisionSubmitted', {}, 'Revision submitted');

        return genericStatus;
      case 'errored':
        genericStatus = this.i18n.translate('statusPipe:Errored', {}, 'Errored');

        return genericStatus;
      default:
        genericStatus = this.i18n.translate('statusPipe:Pending', {}, 'Pending');

        return genericStatus;
    }
  }
}
