import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { TokenService } from '@core/services/token/token.service';

@Injectable({ providedIn: 'root' })
export class UnauthGuard  {

  constructor (
    private tokenService: TokenService,
    private router: Router,
    private authService: AuthService
  ) { }

  async canActivate (): Promise<boolean> {
    if (this.tokenService.getIsLoggedIn()) {
      this.router.navigateByUrl(await this.authService.getDefaultRoute());

      return false;
    }

    return true;
  }
}
