import { Injectable } from '@angular/core';
import { NonprofitSearchFacet } from '@core/models/npo.model';
import { SearchByType, SearchFilter, SearchResult } from '@yourcause/common';
import { BaseYCActionState, RegisterYCState } from '@yourcause/common/state';

@Injectable({
  providedIn: 'root'
})
@RegisterYCState()
export class NonprofitSearchState extends BaseYCActionState {
  readonly activeFacets: NonprofitSearchFacet;
  readonly allFacets: NonprofitSearchFacet;
  readonly paymentNumber: string;
  readonly searchType: SearchByType;
  readonly searchTerm: string;
  readonly searchFilters: SearchFilter[];
  readonly searchResults: SearchResult[];
}
