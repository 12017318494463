import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReleaseNoteAcknowledgement } from '../../models/release-notes.model';

@Component({
  selector: 'yc-release-notes',
  template: `<ng-container *ngIf="ready"><yc-modal
  [modalHeader]="'release:hdrReleaseHeader' | ycI18n:'NPOconnect Nonprofit Platform has been updated'"
  [cancelButtonText]="'common:btnClose' | ycI18n:'Close'"
  [tertiaryButtonText]="'release:btnViewPreviousRelease' | ycI18n:'View previous release notes'"
  (onCancel)="onCancel()"
  (onTertiaryClick)="navigateToReleaseNotes()">
    <div class="row">
      <div class="col-md-4">
        <p>
          <b>
            <yc-i18n
              key="release:textVersion"
              defaultValue="Version: ">
            </yc-i18n>
            {{ releaseNotes.version }}
          </b>
        </p>
      </div>
      <div class="col-md-8">
        <p class="pull-left">
        <b>
          <yc-i18n
            key="release:textReleaseDate"
            defaultValue="Release date: ">
          </yc-i18n>
         {{ releaseNotes.releaseDate | ycFormatDate }}
         </b>
        </p>
      </div>
    </div>
      <p> {{ releaseNotes.description }} </p>
      <p class="release-note-download">
        <a target="_blank">
          <yc-button
            (onClick)="downloadRelease(releaseNotes)"
            [primary]="true">
            <yc-i18n
              key="release:textViewReleaseNotesLink"
              defaultValue="View release notes">
            </yc-i18n>
          </yc-button>
        </a>
      </p>
    </yc-modal>
  </ng-container>
`,
styleUrls: ['./release-notes.component.scss']
})

export class ReleaseNotesComponent  {
@Output()
  completed = new EventEmitter<boolean>();
  releaseNotes: ReleaseNoteAcknowledgement;

  ready = false;

  constructor (
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {
  }

  navigateToReleaseNotes () {
    this.completed.emit(true);
    this.router.navigate(['/help-support/release-notes'], {
      relativeTo: this.activatedRoute
    });
  }

  downloadRelease (release: any) {
    window.open(release.releaseNoteUrl);
  }

  onCancel () {
    this.completed.emit(true);
  }

  afterModalInit () {
    this.ready = true;
  }

}
