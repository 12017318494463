
import { Injectable } from '@angular/core';
import { UserLoginClaims } from '@core/models/account.model';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
@Injectable({ providedIn: 'root' })
export class TokenState extends BaseYcState {
  readonly tokenInfo: UserLoginClaims;
  readonly userId: number;
  readonly userEmail: string;
  readonly ssoToken: string;

  // Remove this when we convert Nonprofit state to standard model
  readonly activeNpoId: number;
}
