<yc-modal *ngIf="!loading"
  [modalHeader]="modalHeader"
  [primaryButtonText]="primaryButtonText"
  [secondaryButtonText]="isNpoAdmin && !!checkFeeDetails ?
      secondaryButtonText : ''"
  (onPrimaryClick)="_onCancel()"
  (onSecondaryClick)="goToDisbursementInfoPage()"
  (onCancel)="_onCancel()">

  <!-- CHECK FEES  -->
  <div *ngIf="!!checkFeeDetails">
    <yc-i18n
      key="payments:textCharityCheckAdminFeeModalDetails_1"
      [context]="{
        'percentWithheld': checkFeeDetails.percentWithheld,
        'capApplied': (checkFeeDetails.capApplied | currency)
      }"
      defaultValue="The Charity Check Admin Fee is a __percentWithheld__% fee per check sent out, with a maximum fee of __capApplied__ on any single check. The Charity Check Admin Fee will only be applicable on donation disbursements paid by check to US-based nonprofits.">
    </yc-i18n>
    <br><br>
    <yc-i18n
      key="payments:textCharityCheckAdminFeeModalDetails_2"
      defaultValue="To avoid this fee, we encourage our nonprofit partners to register for ACH payments within NPOconnect.">
    </yc-i18n>&nbsp;
    <yc-i18n
      [key]="isNpoAdmin ?
        'payments:textCharityCheckAdminFeeModalDetailsAdmin_3' :
        'payments:textCharityCheckAdminFeeModalDetailsManager_3'"
      [defaultValue]="isNpoAdmin ?
        'To register for ACH disbursement by providing your organizations bank details at no cost, click the link below.' :
        'To register for ACH disbursement, have a nonprofit administrator provide your organization\'s bank details on the Disbursement Information page.'">
    </yc-i18n>
  </div>

  <!-- TRANSACTION FEES  -->
  <div *ngIf="!!transactionFeeDetails">
    <div class="mb-3">
      <yc-i18n
        key="give:textInOrderToDeliverYourTransactions"
        defaultValue="In order to deliver your transactions to you, the following fees are assessed:">
      </yc-i18n>
    </div>

    <!-- CHARITY SUPPORT FEES  -->
    <div *ngIf="transactionFeeDetails.charitySupportFees?.amount > 0"
      class="mb-3">
      <strong>
        <yc-i18n
          key="give:textCharitySupportFee"
          defaultValue="Charity support fee">
        </yc-i18n>:
      </strong>
      <yc-i18n
        key="give:textCharitySupportFeeDescription"
        defaultValue="A platform fee to support our capability to reliably deliver your donation.">
      </yc-i18n>
      <br>
      <strong>
        <yc-i18n
          key="give:textFeeAmount"
          defaultValue="Fee amount">
        </yc-i18n>: &nbsp;
      </strong>
      <!-- CHARITY SUPPORT FEE AMOUNT(S)  -->
      <span *ngIf="disbursementFees?.charitySupportFees?.amount">
        {{ disbursementFees?.charitySupportFees?.amount | money : disbursementCurrencyCode : true }}
        ({{ transactionFeeDetails.charitySupportFees?.amount | money : currency : true }})
      </span>
      <span *ngIf="!disbursementFees?.charitySupportFees?.amount">
        {{ transactionFeeDetails.charitySupportFees?.amount | money : currency : true }}
      </span>
      <!-- FEES COVERED BY..  -->
      <div *ngIf="transactionFeeDetails.charitySupportFees?.amount > 0 &&
        transactionFeeDetails.charitySupportFees?.fullyCovered">
        <yc-fa class="text-warning me-1 font-size-875"
          icon="star">
        </yc-fa>
        <yc-i18n
          key="give:textThisFeeHasBeenCovered"
          defaultValue="This fee has been fully covered by __companyName__"
          [context]="{
            'companyName': companyName
          }">
        </yc-i18n>
      </div>
    </div>

    <!-- PROCESSOR FEES  -->
    <div *ngIf="transactionFeeDetails.processorFees?.amount > 0">
      <strong>
        <yc-i18n
          key="give:textProcessingFee"
          defaultValue="Processing fee">
        </yc-i18n>:
      </strong>
      <yc-i18n
        key="give:textProcessingFeeDescription"
        defaultValue="A credit card processing fee assessed by Blackbaud Merchant Services.">
      </yc-i18n>
      <br>
      <strong>
        <yc-i18n
          key="give:textFeeAmount"
          defaultValue="Fee amount">
        </yc-i18n>:
      </strong>
      <!-- PROCESSOR FEE AMOUNT(S)  -->
      <span *ngIf="disbursementFees?.processorFees?.amount">
        {{ disbursementFees?.processorFees?.amount | money : disbursementCurrencyCode : true }}
        ({{ transactionFeeDetails.processorFees?.amount | money : currency : true }})
      </span>
      <span *ngIf="!disbursementFees?.processorFees?.amount">
        {{ transactionFeeDetails.processorFees?.amount | money : currency : true }}
      </span>
      <!-- FEES COVERED BY..  -->
      <div *ngIf="transactionFeeDetails.processorFees?.amount > 0 &&
          transactionFeeDetails.processorFees?.fullyCovered">
        <yc-fa class="text-warning me-1 font-size-875"
          icon="star">
        </yc-fa>
        <yc-i18n
          key="give:textThisFeeHasBeenCovered"
          defaultValue="This fee has been fully covered by __companyName__"
          [context]="{
            'companyName': companyName
          }">
        </yc-i18n>
      </div>
    </div>
  </div>
</yc-modal>