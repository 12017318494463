import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { CreateProgramRequestModel, GetAllProgramsReturnModelPagination, ProgramReturnModel, ProgramTypeModel, UpdateProgramRequestModel } from '@core/typings/programs.typing';
import { APIResponse, APIResult, PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class ProgramsResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getAllPrograms (
    paginationOptions: PaginationOptions<GetAllProgramsReturnModelPagination>
  ) {
    const endpoint = 'api/Program/GetAllPrograms';

    return this.httpRestService.post<APIResult<GetAllProgramsReturnModelPagination>>(
      endpoint,
      {
        paginationOptions
      }
    );
  }

  async getProgramTypes () {
    const endpoint = 'api/Program/GetProgramTypes';

    return this.httpRestService.get<APIResponse<ProgramTypeModel[]>>(
      endpoint
    );
  }

  async createProgram (program: CreateProgramRequestModel) {
    const endpoint = 'api/Program/CreateProgram';

    return this.httpRestService.post<APIResponse<ProgramReturnModel>>(
      endpoint,
      program
    );
  }

  async updateProgram (program: UpdateProgramRequestModel) {
    const endpoint = 'api/Program/UpdateProgram';

    return this.httpRestService.post<APIResponse<ProgramReturnModel>>(
      endpoint,
      program
    );
  }

  async deleteProgram (id: number) {
    const endpoint = 'api/Program/DeleteProgram';

    return this.httpRestService.post<APIResponse<boolean>>(
      endpoint,
      {
        id
      }
    );
  }

  async publishProgram (id: number) {
    const endpoint = 'api/Program/PublishProgram';

    return this.httpRestService.post<APIResponse<boolean>>(
      endpoint,
      {
        id
      }
    );
  }

  async unPublishProgram (id: number) {
    const endpoint = 'api/Program/UnPublishProgram';

    return this.httpRestService.post<APIResponse<boolean>>(
      endpoint,
      {
        id
      }
    );
  }
}
