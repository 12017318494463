import { Injectable } from '@angular/core';
import { SidebarLink } from '@yourcause/common';
import { CachedAttr, CACHE_TYPES } from '@yourcause/common/cache';
import { BaseYCActionState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
@Injectable({ providedIn: 'root' })
export class AppShellState extends BaseYCActionState {
  readonly showHeaderButtons: boolean = true;
  readonly latestVersion: string;

  @CachedAttr(CACHE_TYPES.LOCALSTORAGE)
  readonly sidebarClosed: boolean;

  readonly sidebarLinks: SidebarLink[] = [];
}
