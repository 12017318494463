import { Component, Input, OnInit } from '@angular/core';
import { DEDICATION_MAP } from '@core/models/donation.model';
import { InflectService } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NonprofitService } from '../../../nonprofit/nonprofit.service';

@Component({
  selector: 'yc-designation',
  templateUrl: './designation.component.html'
})
export class DesignationComponent implements OnInit {
  @Input() designation: string;
  @Input() dedicationType: keyof typeof DEDICATION_MAP;
  @Input() dedicationText: string;
  @Input() nonprofitName: string;
  chapterText: string;

  constructor (
    private inflect: InflectService,
    private i18n: I18nService,
    private nonprofitService: NonprofitService
  ) { }

  getDedicationText () {
    const dedication = DEDICATION_MAP[this.dedicationType];
    if (dedication) {
      return this.i18n.translate(
        `give:text${this.inflect.pascalize(dedication)}`,
        {
          dedicationText: this.dedicationText || ''
        },
        `${dedication} __dedicationText__`
      );
    }

    return '';
  }

  ngOnInit () {
    const activeNpo = this.nonprofitService.activeNpo;
    if (activeNpo) {
      const nonprofitName = this.nonprofitName.toLowerCase();
      if ((activeNpo.name.toLowerCase() !== nonprofitName) &&
        (activeNpo.displayName.toLowerCase() !== nonprofitName)) {
          this.chapterText = this.nonprofitName;
      }
    }
    this.chapterText = this.nonprofitName;
  }
}
