<div [formGroup]="formArray.at(0) | ycAs: formGroupTypeToken"
  class="d-flex"
  role="form">
  <div>
    <yc-typeahead-select
      name="searchType"
      [label]="'common:lblSearchByType' | ycI18n : 'Search by Type'"
      [srOnlyLabel]="true"
      [showClear]="false"
      [items]="searchByTypeOptions"
      (onChange)="_changeType()">
    </yc-typeahead-select>
  </div>
  <div class="search-input ms-3">
    <yc-input
      name="term"
      [placeholder]="placeholderText"
      [label]="'common:lblSearchTerm' | ycI18n : 'Search Term'"
      [srOnlyLabel]="true"
      [rightIcons]="[{
        icon: 'search'
      }]"
      [showClear]="true"
      [autofocus]="true"
      (onChange)="_onTermChange($event)">
    </yc-input>
  </div>

  <!-- SHOW CHAPTERS CHECKBOX  -->
  <div *ngIf="!disableFilterCheckboxes"
    class="d-flex">
    <div class="checkbox-filter">
      <yc-show-chapter-nonprofits
        [parentFormGroup]="searchForm"
        (changeType)="_changeType()">
      </yc-show-chapter-nonprofits>
    </div>

    <!-- SHOW INACTIVE CHECKBOX  -->
    <div class="checkbox-filter">
      <yc-show-inactive-nonprofits
        [parentFormGroup]="searchForm"
        (changeType)="_changeType()">
      </yc-show-inactive-nonprofits>
    </div>
  </div>

</div>

<!-- ADVANCED FILTERS SECTION  -->
<div *ngIf="facets && !disableFilterCheckboxes">
  <div [formGroup]="this.formArray.at(1) | ycAs: formGroupTypeToken"
    class="d-flex mt-2">

    <!-- REG AUTH SELECT  -->
    <yc-typeahead-select
      class="me-3"
      name="registrationAuthority"
      [label]="'common:lblRegistrationAuthority' | ycI18n"
      [srOnlyLabel]="true"
      [showClear]="false"
      [items]="regAuthOptions"
      (onChange)="_changeFilter($event, 'regAuth')">
    </yc-typeahead-select>

    <!-- COUNTRY SELECT  -->
    <yc-typeahead-select *ngIf="searchFiltersRegAuth &&
      countryOptions &&
      !loadingCountryOptions"
      class="me-3"
      [showClear]="false"
      name="country"
      [label]="'common:lblCountry' | ycI18n : 'Country'"
      [srOnlyLabel]="true"
      [items]="countryOptions"
      (onChange)="_changeFilter($event, 'country')">
    </yc-typeahead-select>

    <!-- STATE/PROV/REGION SELECT  -->
    <yc-typeahead-select *ngIf="searchFiltersCountry &&
      stateProvRegionOptions &&
      facets.stateProvRegName.length > 0 &&
      !loadingStateOptions"
      name="stateProvRegName"
      [showClear]="false"
      [label]="'accountSetup:lblState' | ycI18n"
      [srOnlyLabel]="true"
      [items]="stateProvRegionOptions"
      (onChange)="_changeFilter($event, 'state')">
    </yc-typeahead-select>
  </div>
</div>