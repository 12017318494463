import { Injectable } from '@angular/core';
import { StagedNonprofitForAdminRequest } from '@core/models/admin-request.model';
import { NewChapterRequestDisplayModel } from '@core/models/chapter-request.model';
import { NonprofitAdminRequestDisplayModel } from '@core/models/claim.model';
import { BasicMyNonprofit, MyNonprofitRow } from '@core/models/npo.model';
import { AdminRequestStatusId } from '@core/models/status.model';
import { Address, AddressFormatterService } from '@yourcause/common';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { MyNonprofitsResources } from './my-nonprofits.resources';
import { MyNonprofitsState } from './my-nonprofits.state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { ChangeRoleToAdministratorAPI } from '@core/typings/ui/change-role-to-administrator.typing';
import { NotifierService } from '@yourcause/common/notifier';
import { Router } from '@angular/router';
import { I18nService } from '@yourcause/common/i18n';

@AttachYCState(MyNonprofitsState)
@Injectable({ providedIn: 'root' })
export class MyNonprofitsService extends BaseYCService<MyNonprofitsState>{

  constructor (
    private myNonprofitsResources: MyNonprofitsResources,
    private formatter: AddressFormatterService,
    private arrayHelper: ArrayHelpersService,
    private notifierService: NotifierService,
    private i18nService: I18nService,
    private router: Router
  ) {
    super();
  }
    get nonprofitsForUser () {
      return this.get('nonprofitsForUser');
    }

    get requestsForUser () {
      return this.get('requestsForUser');
    }

    get nonprofitArray () {
      return this.get('nonprofitArray');
    }

    get adminRequestArray () {
      return this.get('adminRequestArray');
    }

    get nonprofitHasChapters () {
      return this.get('nonprofitHasChapters');
    }

    get useParentDescription () {
      return this.get('useParentDescription');
    }

    get useChapterDescription () {
      return this.get('useChapterDescription');
    }

    get tableRows () {
      return this.get('tableRows');
    }

    setNonprofitsForUser (allNonprofitsForUser: BasicMyNonprofit[]) {
      this.set('nonprofitsForUser', allNonprofitsForUser);
    }

    setRequestsForUser (allNonprofitAdminRequests: NonprofitAdminRequestDisplayModel[]) {
      this.set('requestsForUser', allNonprofitAdminRequests);
    }

    setNonprofitHasChapters (val: boolean) {
      this.set('nonprofitHasChapters', val);
    }

    setUseParentDescription (val: boolean) {
      this.set('useParentDescription', val);
    }

    setUseChapterDescription (val: boolean) {
      this.set('useChapterDescription', val);
    }

    setNonprofitArray () {
      const chapterRequestAsNonprofitArray = this.getChapterRequestAsNonprofitArray();
      const chapterArray = this.getChapterArray();
      const chapterAndRequestArray = chapterArray.concat(chapterRequestAsNonprofitArray);
      const nonChapterArray = this.getNonChapterArray();
      const chaptersWithoutParents = this.getChaptersWithoutParents(
        chapterAndRequestArray,
        nonChapterArray
      );

      const nonprofitArray: MyNonprofitRow[] = (nonChapterArray.concat(chaptersWithoutParents) || [])
        .map((nonprofit) => {
          const chapterList = chapterAndRequestArray.filter((npo) => {
            if (+npo.parentNonprofitId === +nonprofit.id) {
              npo.address = npo.displayAddress ||
                npo.address ||
                nonprofit.displayAddress ||
                nonprofit.address;
              this.setNonprofitHasChapters(true);

              return true;
            }

            return false;
          });

          const chapterRequestCount = chapterList.filter((chapter) => chapter.isChapterRequest).length;
          const chapterCount = chapterList.filter((chapter) => !chapter.isChapterRequest).length;

          return new MyNonprofitRow (
            nonprofit.id,
            nonprofit.registrationId,
            nonprofit.name,
            nonprofit.displayAddress || nonprofit.address,
            nonprofit.nonprofitIconImageUrl,
            nonprofit.createDate,
            undefined,
            true,
            chapterList,
            nonprofit.parentNonprofitId,
            nonprofit.parentName,
            nonprofit.parentRegistrationId,
            !!chapterList.length,
            nonprofit.isChapterAffiliate,
            nonprofit.isActive,
            nonprofit.deactivationReasonId,
            nonprofit.eligibleForGivingStatusId,
            chapterCount,
            chapterRequestCount,
            nonprofit.status?.status || '',
            nonprofit.usersRole?.roleName || '',
            nonprofit.adminsCount
          );
      });

      if (nonChapterArray.length > 0 && this.nonprofitHasChapters) {
        this.setUseParentDescription(true);
      } else if (chapterArray.length) {
        this.setUseChapterDescription(true);
      }
      this.set('nonprofitArray', nonprofitArray);
    }

    setAdminRequestArray () {
      const adminRequestForDisplayArray = this.getAdminRequestsForDisplay();
      const adminRequestArray = adminRequestForDisplayArray.map((request) => {
        const nonprofit: BasicMyNonprofit = request.nonprofit;
        const stagedNonprofit: StagedNonprofitForAdminRequest = request.stagedNonprofit;

        if (!!stagedNonprofit) {
          return new MyNonprofitRow (
            stagedNonprofit.stagedNonprofitId,
            stagedNonprofit.registrationId,
            stagedNonprofit.name,
            stagedNonprofit.address,
            'assets/img/nonprofit/icons/cat_10.gif',
            stagedNonprofit.stagedNonprofitCreateDate,
            request,
            true,
            undefined,
            undefined,
            request.parent?.name,
            request.parent?.registrationId,
            undefined
          );
        } else {
          return new MyNonprofitRow (
            nonprofit.id,
            nonprofit.registrationId,
            nonprofit.name,
            nonprofit.address,
            nonprofit.nonprofitIconImageUrl,
            nonprofit.createDate,
            request,
            true,
            undefined,
            undefined,
            nonprofit.parentName || request.parent?.name,
            nonprofit.parentRegistrationId || request.parent?.registrationId,
            undefined,
            false
          );
        }
      });
      this.set('adminRequestArray', adminRequestArray);
    }

    getChapterArray () {
      const chapterArray: BasicMyNonprofit[] = this.nonprofitsForUser.filter((nonprofit) => {
        (nonprofit.address as any) = nonprofit.address || {};
        if (!nonprofit.address.countryCode ||
          !nonprofit.address.stateProvRegCode ||
          !nonprofit.address.city
        ) {
          nonprofit.address = null;
        }
        nonprofit.isChapterRequest = false;

        return nonprofit.isChapterAffiliate;
      });

      return chapterArray;
    }

    getNonChapterArray () {
      const nonChapterArray: BasicMyNonprofit[] = this.nonprofitsForUser.filter((nonprofit) => {
        return !nonprofit.isChapterAffiliate;
      });

      return nonChapterArray;
    }

    getChaptersWithoutParents (
      chapterAndRequestArray: BasicMyNonprofit[],
      nonChapterArray: BasicMyNonprofit[]
    ) {
      const chaptersWithoutParents: BasicMyNonprofit[] = chapterAndRequestArray.filter((chapter) => {
        return !nonChapterArray.find((npo) => +npo.id === +chapter.parentNonprofitId);
      });

      return chaptersWithoutParents;
    }

    getChapterRequestAsNonprofitArray () {
      const chapterRequestsArray: NewChapterRequestDisplayModel[] = [];
      this.nonprofitsForUser.forEach((npo) => {
        if (npo.chapterRequests?.length > 0) {
          chapterRequestsArray.push(...npo.chapterRequests);
        }
      });
      const chapterRequestAsNonprofitArray: BasicMyNonprofit [] = chapterRequestsArray.map((req) => {
        return {
          id: req.stagedChapterNonprofit.stagedNonprofitId,
          registrationAuthorityId: req.parentNonprofit.registrationAuthorityId,
          registrationId: req.parentNonprofit.registrationId,
          name: req.stagedChapterNonprofit.name,
          address: req.stagedChapterNonprofit.address,
          nonprofitIconImageUrl: req.parentNonprofit.nonprofitIconImageUrl,
          createDate: req.createDate,
          parentNonprofitId: req.parentNonprofit.id,
          isChapterAffiliate: true,
          status: req.status,
          isChapterRequest: true,
          chapterRequestId: req.id,
          chapterRequestSummaryData: {
            stagedNonprofit: req.stagedChapterNonprofit,
            invite: req.invite,
            parentNonprofit: req.parentNonprofit,
            createdByUser: req.createdByUser
          }
        } as BasicMyNonprofit;
      });

      return chapterRequestAsNonprofitArray;
    }

    getAdminRequestsForDisplay () {
      const adminRequestForDisplayArray = (this.requestsForUser || [])
      .filter((request) => {
        return [
          AdminRequestStatusId.DRAFT,
          AdminRequestStatusId.PENDING,
          AdminRequestStatusId.DECLINED
        ].includes(request.adminStatusId) &&
          (request.nonprofit || request.stagedNonprofit);
      });

      return adminRequestForDisplayArray;
    }

    setTableRows () {
      const rows = this.arrayHelper.sort([
        ...this.nonprofitArray,
        ...this.adminRequestArray
      ], 'name');
      this.set('tableRows', rows);
    }

    async getNonprofitsForAdmin () {
      const response = await this.myNonprofitsResources.getNonprofitsForAdmin();
      response.forEach((npo: BasicMyNonprofit) => {
        (npo.address = npo.address || {} as Address).toString = () => {
          return this.formatter.format(npo.address, false, true);
        };
      });

      return response;
    }

    async getRequestsForUser () {
      const response = await this.myNonprofitsResources.getRequestsForUser();

      return response;
    }

    async cancelChapterRequest (
      chapterRequestIds: number[]
    ) {
      const response = this.myNonprofitsResources.cancelChapterRequest(chapterRequestIds);

      return response;
    }

    async setMyNonprofitRows (
      allNonprofitsForAdmin: BasicMyNonprofit[],
      allNonprofitAdminRequests: NonprofitAdminRequestDisplayModel[]
    ) {
      this.setNonprofitsForUser(allNonprofitsForAdmin),
      this.setRequestsForUser(allNonprofitAdminRequests),
      this.setNonprofitArray(),
      this.setAdminRequestArray(),
      this.setTableRows()
    }

    async setAllDataForMyNonprofitRows () {
      const [
        allNonprofitsForAdmin,
        allNonprofitAdminRequests
      ] = await Promise.all([
        await this.getNonprofitsForAdmin(),
        await this.getRequestsForUser()
      ]);
      await this.setMyNonprofitRows(
        allNonprofitsForAdmin,
        allNonprofitAdminRequests
      );
    }

    async changeRoleToAdministrator (nonprofitId: number, comment: string) {
      try {
        const payload : ChangeRoleToAdministratorAPI = {
          nonprofitId: nonprofitId,
          comments: comment
        };

        await this.myNonprofitsResources.changeRole(
          payload
        );

        this.notifierService.success(this.i18nService.translate(
          'nonprofits:notificationSuccessfullyRequestedToChangeRoleToAdministrator',
          {},
          'Successfully requested to change role to administrator'
        ));

      } catch (e: any) {
        this.handleChangeRoleToAdministratorErrorCodes(e);

      }
    }

    private handleChangeRoleToAdministratorErrorCodes (exception: any) {
      let notifierMessage = this.i18nService.translate(
        'nonprofits:unknownErrorNotificationChangeRoleToAdministrator',
        {},
        'There was an error changing role to administrator'
      );

      if (exception?.error?.errorCode && exception.error.errorCode === 'Does_Not_Exist') {
        this.router.navigateByUrl('/support-request?itemId=2');
      } else {
        if (exception?.status === 404) {
          notifierMessage = this.i18nService.translate(
            'nonprofits:notFoundErrorNotificationChangeRoleToAdministrator',
            {},
            'There was an error changing role to administrator; nonprofit not found'
          );
        } else if (exception?.status === 403) {
          if (exception?.message === 'User is not assigned to this nonprofit.') {
            notifierMessage = this.i18nService.translate(
              'nonprofits:unassignedErrorNotificationChangeRoleToAdministrator',
              {},
              'There was an error changing role to administrator; not assigned to nonprofit'
            );
          } else if (exception?.message === 'User is not allowed to change their role.') {
            notifierMessage = this.i18nService.translate(
              'nonprofits:notAllowedErrorNotificationChangeRoleToAdministrator',
              {},
              'There was an error changing role to administrator; not allowed to change role'
            );
          } else if (exception?.message === 'User is an administrator for this nonprofit.') {
            notifierMessage = this.i18nService.translate(
              'nonprofits:notificationSuccessfullyRequestedToChangeRoleToAdministratorExisting',
              {},
              'You are already an administrator'
            );
          }
        }

        this.notifierService.error(notifierMessage);

        return;
      }
    }
}
