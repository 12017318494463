import { Injectable } from '@angular/core';
import { SendBrandedEmailModel } from '@core/models/mail-to.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class MailToResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  async sendBrandedEmail (payload: SendBrandedEmailModel) {
    const endpoint = 'api/Email/SendBrandedEmail';

    return this.httpRestService.post<APIResponse>(
      endpoint,
      payload
    );
  }
}
